import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { SearchkitManager, SearchkitProvider, Hits } from 'searchkit'

import { Everylist } from 'components/ListItems'

import { getLastVisits } from 'services/visits'
import search from 'services/search'

const searchkit = new SearchkitManager(process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011', {
  httpHeaders: {
    'Authorization': window.localStorage.getItem('token')
  }
})

const packSource = el => ({
  _source: el
})

class Navbar extends Component {
  saveSearch = () => {
    const lastSearches = window.localStorage.getItem('lastSearches') || '[]'
    const lastSearchesObject = JSON.parse(lastSearches)
    if (lastSearchesObject.length >= 3) lastSearchesObject.splice(0, 1)
    lastSearchesObject.push(this.props.searchContent)
    window.localStorage.setItem('lastSearches', JSON.stringify(lastSearchesObject))
  }

  loadLasts = () => {
    const lastSearches = window.localStorage.getItem('lastSearches') || '[]'
    const lastSearchesObject = JSON.parse(lastSearches)

    const lastVisits = getLastVisits()
    return {
      searches: lastSearchesObject,
      visits: lastVisits
    }
  }
  render () {
    const { searchActive, toggleSearch, searchContent, modifySearch: parentModifySearch } = this.props
    const modifySearch = (val) => {
      parentModifySearch(val, () => searchkit.reloadSearch())
    }
    const { searches, visits } = this.loadLasts()
    searchkit.setQueryProcessor((plainQueryObject) => {
      plainQueryObject.query = {
        query_string: {
          query: "*" + searchContent + "*",
          fields: search
        }
      }
      return plainQueryObject
    })

    return <SearchkitProvider searchkit={searchkit}>
      <div style={{ boxShadow: '0px 0px 2px rgba(0,0,0,0.3)', height: '50px' }}>
        <div style={{ cursor: 'pointer', width: 50, height: 50, float: 'left', borderRight: '1px solid rgba(0,0,0,0.1)' }} onClick={searchActive ? () => {
          toggleSearch()
          modifySearch({ target: { value: '' } })
        } : this.props.toggleMenu}>
          {searchActive ? <i style={{ padding: 15 }} className='fal fa-arrow-left' /> : <img src='/assets/images/sign.png' style={{ width: 49, padding: '12px 12px' }} />}
        </div>
        <div style={{ overflow: 'hidden', lineHeight: '50px' }}>
          <input style={{
            background: '#EEE',
            width: 'calc(100% - 30px)',
            border: '0px solid rgba(0,0,0,0.3)',
            height: 30,
            marginLeft: 15,
            color: '#222',
            paddingLeft: 10,
            borderRadius: '3px',
            boxShadow: '0px 0px 2px rgba(0,0,0,0.3)'
          }} placeholder='Szukaj...' value={searchContent} onChange={modifySearch} onFocus={() => toggleSearch(true)} />
        </div>
        <div style={{
          position: 'fixed',
          top: 50,
          right: searchActive ? 0 : '100%',
          bottom: 0,
          width: '100%',
          background: 'white',
          zIndex: 1000
        }}>
          {searchContent === '' ? <div>
            <div>
              <div style={{
                fontSize: 14
              }}>Ostatnio szukane</div>
              {searches.map((word, i) => <div key={i} style={{ padding: 10, borderTop: '1px solid rgba(0,0,0,0.3)' }} onClick={() => modifySearch({
                target: { value: word }
              })}>{word}</div>)}
            </div>
            <div style={{ borderTop: '1px solid rgba(0,0,0,0.3)', padding: 10 }}>
              <div style={{
                fontSize: 14
              }}>Ostatnio oglądane</div>
              <Everylist hits={visits.map(packSource)} />
            </div>
          </div> : <div style={{ padding: 10 }} onClick={this.saveSearch}>
            <Hits listComponent={Everylist} highlightFields={['name']} />
          </div>}
        </div>
      </div>
    </SearchkitProvider>
  }
}

export default withRouter(Navbar)
