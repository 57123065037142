import React from 'react'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import { Menu, Dropdown } from 'semantic-ui-react'
import {
  SearchkitManager, SearchkitProvider, Hits, BoolMust, TermQuery,
  SelectedFilters, NoHits, HitItemProps, SearchkitComponent, InitialLoader
} from 'searchkit'
import { HotKeys } from 'react-hotkeys'

import MassActions from 'components/MassActions'
import { FullScreen, CloseButton, CreateContact, CreateContent, CreateWork, CreateProduct, CreateSet } from 'components/Generics'
import Grid from 'components/Grid'
import ListItem  from 'components/ListItems'
import Filters from 'components/CommonFilters'
import CommonSort from 'components/CommonSort'
import { toggleCheck } from 'components/ToggleCheck'

import Store from 'services/StoreStore'
import { fileUpload } from 'services/files'
import { nicePrompt } from 'services/prompt'

import 'react-tiny-fab/dist/styles.min.css'
import { routeToIndexType } from 'services/url'
import { contentTypes } from '../contents/types'


let records = []

const searchkit = new SearchkitManager(process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011', {
  httpHeaders: {
    'Authorization': window.localStorage.getItem('token')
  }
})

searchkit.addDefaultQuery((query) => {
  const type = window.location.pathname.split('/').filter(el => el)
  let indexType = routeToIndexType(type[0])

  const defaultQuery = []

  if (type[0] === 'products') {
    if (type.length === 1) {
      indexType = 'set'
      defaultQuery.push(TermQuery('type', 'store'))
    } else {
      indexType = 'product'
      defaultQuery.push(TermQuery('set.id', type[1]))
    }
  }

  defaultQuery.push(TermQuery('indexType', indexType))
  return query.addQuery(BoolMust(defaultQuery))
})

export const getStore = () => Store(`{
    templates: search(type: "content", source: "database", filter: "type:email_template") {
      ... on Content {
        id
        name
      }
    }
    organization {
      users {
        id
        name
      }
    }
    taxonomies: search(type: "taxonomy", source: "database") {
      ... on Taxonomy {
        id
        indexType
        type
        name
        content
        parent_id
      }
    }
  }`)

const observablesStore = (type) => Store(`
    fragment contactFields on Contact {
      id
      observables {
        user_id
        parent_id
        parent_type
        indexType
        id
      }
  }
  {
    contacts: search(type:"${type}", source:"database") {
      ...contactFields
    }
  }
  `, 'observables')

const toggleObserve = (type, contactId) => {
  /**
   * TODO Observe from list
   */
  console.log(type)
  console.log(contactId)
}

const ListComponent = (props) => {
  const { hits, checked, toggle, store, list, filtersActive, toggleFilters, type, aggregations } = props
  records = hits.map(el => el._source)

  return <React.Fragment>
    {/*<FullScreen active={filtersActive} direction='left'>*/}
      {/*<div style={{ minWidth: '200px', padding: '0px 16px' }}>*/}
        {/*<Filters type={type} />*/}
      {/*</div>*/}
      {/*<CloseButton click={toggleFilters} />*/}
    {/*</FullScreen>*/}
    {hits.map(hit => <ListItem
      key={hit._source.id}
      id={hit._source.id}
      checked={checked.indexOf(hit._source.id) > -1}
      toggle={toggle(hit._source.id)}
      result={hit}
      toggleObserve={() => toggleObserve(list, hit._source.id)}
      list={list}
    />)}
  </React.Fragment>
}

const Container = observer(class _Container extends React.Component {
  state = {
    addingOpen: false,
    filtersActive: false,
    sortsActive: false,
    massActive: false,
    checked: []
  }
  constructor (props) {
    super(props)
    this.imageInput = React.createRef()
  }

  componentDidMount () {
    getStore()
    setTimeout(() => {
      if (!this.props.location.state) return
      if (this.props.location.state.action === 'addText') this.createContact()
      if (this.props.location.state.action === 'addProductText') this.createProduct()
      if (this.props.location.state.action === 'addProductImage') this.createProductImage()
      if (this.props.location.state.action === 'addImage') this.imageInput.current.click()
    }, 500)
  }
  componentWillReceiveProps () {
    console.log('list cwrp')
    getStore()
  }
  componentWillUnmount () {
    console.log('list unmount')
    getStore().unlock()
  }
  createContact = () => {
    nicePrompt({
      title: 'Nazwa nowego kontaktu',
      confirm: 'Dodaj',
      cancel: 'Anuluj'
    }).then(name => {
      getStore().feedback = id => {
        this.setState({
          redirect: id
        })
      }
      getStore().executeMutation('createcontact', {
        name: name
      })
    })
  }

  createContactImage = () => {
    const file = this.imageInput.current.files[0]
    getStore().feedback = id => {
      getStore().feedback = id => {}
      fileUpload(file, '/Attachments/Contacts/' + id + '/' + file.name).then(() => {
        getStore().executeMutation('createinformation', {
          type: 'file',
          name: file.name,
          content: JSON.stringify({
            name: file.name,
            mimetype: file.type,
            size: file.size,
            path: '/Attachments/Contacts/' + id + '/' + file.name
          }),
          parent_type: 'contacts',
          parent_id: id
        })
        this.setState({
          redirect: id
        })
      })
    }
    getStore().executeMutation('createcontact', {
      name: 'Do uzupełnienia'
    })
  }

  addContent = type => {
    nicePrompt({
      title: 'Nazwa nowej treści',
      confirm: 'Dodaj',
      cancel: 'Anuluj'
    }).then(name => {
      getStore().feedback = id => {
        this.setState({
          redirect: type + '/' + id
        })
      }
      getStore().executeMutation('createcontent', {
        name: name,
        type: type,
        content: '{}'
      })
    })
  }

  createSet = type => {
    nicePrompt({
      title: 'Nazwa nowego magazynu',
      confirm: 'Dodaj',
      cancel: 'Anuluj'
    }).then(name => {
      getStore().feedback = id => {
        this.setState({
          redirect: id
        })
      }
      getStore().executeMutation('createset', {
        name: name,
        type: (type ? type : 'store'),
        content: '{}'
      })
    })
  }

  createProduct = type => {
    nicePrompt({
      title: 'Nazwa nowego produktu',
      confirm: 'Dodaj',
      cancel: 'Anuluj'
    }).then(name => {
      getStore().feedback = id => {
        this.setState({
          redirect: id
        })
      }
      getStore().executeMutation('createproduct', {
        name: name,
        type: type,
        set_id: this.props.match.params.setid,
        owner_id: 0,
        content: JSON.stringify({})
      })
    })
  }

  createProductImage = () => {
    const file = this.imageInput.current.files[0]
    getStore().feedback = id => {
      fileUpload(file, '/Attachments/Products/' + id + '/' + file.name)
      getStore().executeMutation('createinformation', {
        type: 'file',
        name: file.name,
        content: JSON.stringify({
          name: file.name,
          mimetype: file.type,
          size: file.size,
          path: '/Attachments/Products/' + id + '/' + file.name
        }),
        parent_type: 'products',
        parent_id: id
      })
    }
    getStore().executeMutation('createproduct', {
      name: 'Do uzupełnienia'
    })
  }

  createWork = () => {
    nicePrompt({
      title: 'Nazwa nowych prac',
      confirm: 'Dodaj',
      cancel: 'Anuluj'
    }).then(name => {
      getStore().feedback = id => {
        this.setState({
          redirect: id
        })
      }
      getStore().executeMutation('creatework', {
        name: name,
        type: 'project',
        content: '{}'
      })
    })
  }

  toggleFilters = () => {
    this.setState({
      filtersActive: !this.state.filtersActive
    })
  }

  markAll = () => {
    this.setState({
      checked: searchkit.results.hits.ids.split(',').map(el => parseInt(el.split('-')[1]))
    })
  }

  render () {
    console.log('list render');
    // const {store} = this.props
    // if (!store.loaded) return null
    const { filtersActive, sortsActive, massActive, redirect, checked } = this.state
    const type = this.props.location.pathname.split('/').filter(el => el)[0]

    const handlers = {
      'addText': this.createContact
    }

    if (redirect) return <Redirect to={type + '/' + redirect} />

    return <HotKeys handlers={handlers}><SearchkitProvider searchkit={searchkit}>
      <div style={{ display: 'none' }}>
        <input type='file' accept='image/*' capture='camera' ref={this.imageInput} onChange={this.createContactImage} />
      </div>
      <div>
        <MassActions
          hideMassActions={() => this.setState({ massActive: false })}
          type='contact'
          store={getStore()}
          active={massActive}
          checked={checked}
          uncheckAll={() => this.setState({checked: []})}
          getCheckedRecords={() => records}
          allowed={['observe', 'unobserve', 'tag', 'untag', 'email', 'sms', 'owner']} />
        <FullScreen active={filtersActive} direction='left'>
          <div style={{ minWidth: '200px', padding: '0px 16px' }}>
            <Filters type={type} data={searchkit}/>
          </div>
          <CloseButton click={this.toggleFilters} />
        </FullScreen>
        <FullScreen active={this.state.addingOpen} direction='right' style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        }}>
          {Object.keys(contentTypes).map(contentType => {
            return <div
              onClick={() => {
                this.setState({
                  addingOpen: false
                })
                this.addContent(contentType)
              }}
              style={{ flexGrow: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
              key={contentType}
              value={contentTypes[contentType]}
            >{contentTypes[contentType]}</div>
          })}
          <CloseButton click={() => this.setState({
            addingOpen: false
          })} />
        </FullScreen>
        <Grid>
          <div style={{ gridArea: 'massActions' }} size='50px' area='massActions' type='row'>
            <Menu>
              <Menu.Item onClick={() => this.setState({ filtersActive: true })}>
                Filtruj
              </Menu.Item>
              <Menu.Item onClick={() => this.setState({ massActive: true })}>
                Akcje{checked.length > 0 ? ' - ' + checked.length : ''}
              </Menu.Item>
              <Menu.Item onClick={() => this.markAll()}>
                Zaznacz wszystkie
              </Menu.Item>
              <Menu.Item position='right'>
                <Dropdown
                  onChange={(event, data) => {
                    const sort = data.value.split(' ').filter(el => el)
                    const field = sort[0];
                    const direction = sort[1];

                    searchkit.setQueryProcessor((plainQueryObject) => {
                      plainQueryObject.sort = sort.length > 0 ? {[field]: direction} : {};
                      return plainQueryObject
                    })
                    searchkit.reloadSearch();

                  }}
                  direction='left'
                  clearable
                  placeholder={'Sortuj...'}
                  options={[
                    {
                      text: 'Po nazwie A-Z',
                      value: 'name.keyword asc'
                    },
                    {
                      text: 'Po nazwie Z-A',
                      value: 'name.keyword desc'
                    },
                    {
                      text: 'Najdawniej utworzone',
                      value: 'created_at.keyword asc'
                    },
                    {
                      text: 'Ostatnio utworzone',
                      value: 'created_at.keyword desc'
                    },
                    {
                      text: 'Najdawniej zmodyfikowane',
                      value: 'updated_at.keyword asc'
                    },
                    {
                      text: 'Ostatnio zmodyfikowane',
                      value: 'updated_at.keyword desc'
                    }
                  ]}
                />
              </Menu.Item>
            </Menu>
          </div>
          <div style={{ gridArea: 'main', position: 'relative', overflow: 'auto', padding: '0 10px' }} area='main'>
            <SelectedFilters />
            <Hits listComponent={props => <ListComponent {...props} checked={checked} toggle={toggleCheck(this)} list={this.props.match.path.split('/').slice(-1)[0]} filtersActive={this.state.filtersActive} toggleFilters={this.toggleFilters} type={type}/>} />
            <NoHits translations={{
              "NoHits.NoResultsFound":"No " + type + " were found",
              "NoHits.DidYouMean":"Search for {suggestion}",
              "NoHits.SearchWithoutFilters":"Search for {query} without filters"
            }} />
            {type === 'contacts' && <CreateContact create={() => this.createContact()} image={() => this.imageInput.current.click()} />}
            {type === 'contents' && <CreateContent create={() => this.setState({ addingOpen: true })} />}
            {type === 'work' && <CreateWork create={() => this.createWork()} />}
            {(this.props.location.pathname.split('/').filter(el => el).length === 2 && type === 'products') && <CreateProduct set={this.props.match.params.setid} individual={() => this.createProduct('individual')} repeatable={() => this.createProduct('repeatable')} commercial={() => this.createProduct('commercial')} />}
            {(this.props.location.pathname.split('/').filter(el => el).length === 1 && type === 'products') && <CreateSet create={() => this.createSet()} />}
          </div>
        </Grid>
      </div>
    </SearchkitProvider></HotKeys>
  }
})

export default props => {
  return <Container {...props} />
}
