import React from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Container, Header, Card, Button } from 'semantic-ui-react'

export default observer(({ notifications, notificationStore, tasksStore }) => <Container style={{ paddingTop: 20 }}>
  <Header as='h2'>Powiadomienia</Header>
  {notifications.map(notification => {
    const { id, text, parent_type, parent_id, isread } = notification
    return <Card fluid style={{ borderRight: isread ? 'none' : '10px solid orange' }}><Card.Content>
      {text}<br /><br />
      <Link to={`/${parent_type}/${parent_id}`}>
        <Button>Do rekordu</Button>
      </Link>
      <Button onClick={() => {
        notification.isread = !notification.isread
        notificationStore.executeMutation('notificationRead', {
          id: id,
          isread: notification.isread
        })
      }}>Read toggle</Button>
    </Card.Content></Card>
  })}
  <Header as='h2'>Zadania</Header>
  {tasksStore.data.me.tasks && tasksStore.data.me.tasks.map(task => {
    const content = JSON.parse(task.content)
    return <Card fluid style={{ borderRight: '10px solid red' }}>
      <Card.Content>
        <div style={{ float: 'left' }}>
          {task.completed
            ? <i style={{ float: 'left', color: 'green', fontSize: '24px', marginRight: '10px' }} className='fal fa-check-square' />
            : <i style={{ float: 'left', fontSize: '24px', marginRight: '10px' }} className='fal fa-square' />}
          {task.name}
        </div>
        {content.description}
        <Button onClick={() => {
          task.completed = (task.completed === 1 ? 0 : 1)
          tasksStore.sync()
        }} style={{ float: 'right' }}>Wykonaj</Button>
      </Card.Content>
    </Card>
  })}
</Container>)
// TODO: Adam, dodaj tu proszę listę zadań do których user jest assigned.
