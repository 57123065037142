export const contentTypes = {
  bpmn: 'bpmn',
  form: 'form',
  webpage: 'webpage',
  email_template: 'email_template',
  offer: 'offer',
  contract: 'contract',
  article: 'article',
  persona: 'persona',
  rating: 'rating'
}
