import React, { Component } from 'react'
import { TermQuery, NumericRefinementListFilter, MenuFilter,
  RefinementListFilter, CheckboxFilter, RangeFilter, SearchkitComponent, ItemList, CheckboxItemList
} from 'searchkit'
import Store from 'services/StoreStore'
import Grid from './Grid'
import { Container, Header, Card, Button } from 'semantic-ui-react'

const CustomItemList = (props) => {
  if(props.items.length === 0) return null;

  return <div className={'filter-item'}>
    <ItemList {...props}/>
  </div>
}

const CustomCheckboxList = (props) => {
  if(props.items.length === 0) return null;

  console.log(props)

  return <div className={'filter-item'}>
    <CheckboxItemList {...props} />
  </div>
}

class Filters extends SearchkitComponent {

  render () {
    return <Container>
      <div className={'filters'} style={{ overflowY: 'auto' }}>


      <CheckboxFilter
        id='observables'
        label='Moje'
        title={'Obserwowane'}
        filter={TermQuery('observables.user_id', Store().getUser().user.id)}
        showCount
        listComponent={props => <CustomCheckboxList {...props} title={'Obserwowane'}/>}
      />

      <RefinementListFilter
        listComponent={props => <CustomItemList {...props} title={'Tagi'}/>}
        id='labels'
        field='labels.name.keyword'
        title={'Tagi'} />

      <RangeFilter field='monthIndex' id='monthIndex' min={360} max={600} showHistogram title={'Miesiąc roboczy'}/>

      <RangeFilter field='yearIndex' id='yearIndex' min={2000} max={2019} showHistogram title={'Rok roboczy'}/>

      <div>
        {this.props.type === 'contacts' && <Contacts />}
        {this.props.type === 'contents' && <Contents />}
        {this.props.type === 'work' && <Work />}
        {this.props.type === 'products' && <Products />}
      </div>
    </div>
    </Container>
  }
}

const Contacts = (props) => {
  return <div>
    <NumericRefinementListFilter
      id='is_company'
      field='is_company'
      title={'Typ kontaktu'}
      listComponent={props => <CustomItemList {...props} title={'Typ kontaktu'}/>}
      options={[
        { title: 'Wszystkie' },
        { title: 'Osoba', from: 0, to: 1 },
        { title: 'Firma', from: 1, to: 2 }
      ]} />
    <MenuFilter
      listComponent={props => <CustomItemList {...props} title={'Status'}/>}
      field='status.keyword'
      title='Status'
      id='status' />
  </div>
}

const Contents = (props) => {
  return <div>
    <MenuFilter
      listComponent={props => <CustomItemList {...props} title={'Typ treści'}/>}
      field='type.keyword'
      title={'Typ treści'}
      id='types' />
  </div>
}

const Work = (props) => {
  return <div>
    <MenuFilter
      listComponent={props => <CustomItemList {...props} title={'Typ pracy'}/>}
      field='type.keyword'
      title={'Typ pracy'}
      id='types' />
  </div>
}

const Products = (props) => {
  return <div>
    <MenuFilter
      listComponent={props => <CustomItemList {...props} title={'Typ produktu'}/>}
      field='type.keyword'
      title={'Typ produktu'}
      id='types' />
    <MenuFilter
      listComponent={props => <CustomItemList {...props} title={'Magazyn'}/>}
      field='set.name.keyword'
      title={'Magazyn'}
      id='set' />
  </div>
}

export default Filters
