import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { Form, TextArea, Button, Message } from 'semantic-ui-react'

import Store from 'services/StoreStore'


let checkInterval = null

export const getMessagesStore = id => Store(`
  {
    messages: search(type: "message", source: "database", filter: "channel_id:${id}") {
      ... on Message {
        id
        content
      }
    }
  }`, 'messages')

const getStore = () => Store(storeQuery)
const currentQuery = () => Store(storeQuery).query === storeQuery
const storeQuery = `
  {
    organization {
      id
      livechat {
        id
        name
      }
    }
  }`

const Messages = observer(class _Messages extends React.Component {
  render () {
    const { store } = this.props
    if (!store.loaded) return null
    console.log(store.data.messages)
    return <div style={{ marginBottom: '10px' }}>
      {store.data.messages.map(messages => <div>
        {JSON.parse(messages.content).map(message => <Message size='mini'>
          {moment(message.date).format('MMMM Do YYYY, h:mm:ss a')}<br />
          {message.content}
        </Message>)}
      </div>)}
    </div>
  }
})


const Container = observer(class _Container extends React.Component {
  state = {
    chosenChannel: null
  }
  componentDidMount () {
    getStore()
  }
  componentWillUnmount () {
    getStore().unlock()
  }
  changeChannel = channel => () => {
    this.setState({
      chosenChannel: channel
    })
  }
  sendMessage = () => {
    this.props.store.executeMutation('sendMessage', {
      channel_id: this.state.chosenChannel.id,
      author: 'Firma',
      date: moment().format(),
      content: this.state.newMessage
    })
    this.setState({
      newMessage: ''
    })
  }
  render () {
    if (!this.props.store.loaded || !currentQuery()) return null
    console.log(this.props.store.data)
    const { chosenChannel, newMessage } = this.state
    const { livechat } = this.props.store.data.organization

    if (chosenChannel !== null) {
      if (checkInterval) clearInterval(checkInterval)
      checkInterval = setInterval(() => {
        getMessagesStore(chosenChannel.id).reload()
      }, 5000)
    }

    return <div style={{ padding: '10px' }}>
      {chosenChannel === null && <div style={{ padding: 10 }}>
        {livechat.map(channel => {
          return <div onClick={this.changeChannel(channel)}>
            {channel.name}
          </div>
        })}
      </div>}
      {chosenChannel !== null && <div>
        <Messages store={getMessagesStore(chosenChannel.id)} />

        <Form onSubmit={e => {
          e.preventDefault()
          this.sendMessage()
        }}>
          <Form.Field>
            <TextArea rows={2} placeholder='Wpisz wiadomosc...' value={newMessage} onChange={e => this.setState({
              newMessage: e.target.value
            })} />
          </Form.Field>
          <Button type='submit'>Wyślij</Button>
        </Form>
      </div>}
    </div>
  }
})

export default () => <Container store={getStore()} />
