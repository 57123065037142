import React, { Component } from 'react'
import {observer} from 'mobx-react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import Column from './Column'
import { reorderCards, reorder } from './reorder'

const uuidv4 = require('uuid/v4')

export default props => <Board object={props.object} onChange={props.onChange} />

// {
// "order": ['asdasd', 'qweqweqw', 'ytutyu', 'dfgdfg'],
// "columns": {
//    asdasd: {
//      name: "aqweqweqw"
//      cards: [{name: "asdasdasdasd"}]
//    }
//  }
// }
const Board = observer(class _Board extends Component {
  onDragStart = (initial) => {}

  onDragEnd = (result) => {
    if (!result.destination) return
    const object = this.props.object
    const source = result.source
    const destination = result.destination

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) return

    if (result.type === 'COLUMN') {
      const order = reorder(
        object.order,
        source.index,
        destination.index
      )

      object.order = order
      this.props.onChange(object)
      return
    }

    const columns = reorderCards({
      quoteMap: object.columns,
      source,
      destination
    })

    object.columns = columns
    this.props.onChange(object)
  }
  render () {
    const {object, onChange} = this.props
    const {columns = {}, order = []} = object

    const board = (
      <Droppable
        droppableId='board'
        type='COLUMN'
        direction='horizontal'
        ignoreContainerClipping
      >
        {(provided) => (
          <div style={{
            display: 'inline-flex',
            width: (order.length + 1) * 220,
            paddingBottom: 200
          }} ref={provided.innerRef} {...provided.droppableProps}>
            {order.map((key, index) => (
              <Column
                key={key}
                index={index}
                column={columns[key]}
                changeColumn={key}
                columnId={key}
                onChange={value => {
                  object.columns[key] = value
                  onChange(object)
                }}
                onDelete={() => {
                  object.order.splice(index, 1)
                  delete object.columns[key]
                  onChange(object)
                }}
              />
            ))}
            <div style={{
              width: 200,
              background: 'rgba(0,0,0,0.4)',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer'
            }} onClick={() => {
              const key = uuidv4().replace(/-/g, '')
              const {object} = this.props

              if (!object.columns) object.columns = {}
              if (!object.order) object.order = []

              object.columns[key] = {
                name: '',
                cards: []
              }
              object.order.push(key)
              console.log(object)
              this.props.onChange(object)
            }}>Nowa lista...</div>
          </div>
        )}
      </Droppable>
    )

    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
      >
        {board}
      </DragDropContext>
    )
  }
})
