import _ from 'lodash'

const generalFields = {
  information: [
    'information.name^3',
    'information.content'
  ],
  tasks: [
    'tasks.name^3',
    'tasks.content'
  ],
  taxonomies: [
    'taxonomies.name^3',
    'taxonomies.content'
  ]
}

const generalFieldsAggregate = [
  ...generalFields.information,
  ...generalFields.tasks,
  ...generalFields.taxonomies
]

const fields = {
  contact: [
    'name^10',
    'contact_data_object.value^6'
  ],
  content: [
    'name^10',
    'content_object.object^3'
  ],
  product: [
    'name^10',
    'content_object.object^3'
  ],
  set: [
    'name^10',
    'content_object.object^3'
  ],
  work: [
    'name^10',
    'content_object.object^3'
  ]
}

const concat = (agg, el) => agg.concat(el)
const aggregatedFields = _.uniq(Object.keys(fields).map(field => fields[field]).reduce(concat, []))
export default [
  ...aggregatedFields,
  ...generalFieldsAggregate
]
