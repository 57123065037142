import React, { Component } from 'react'
import Select from 'react-select'
import _ from 'lodash'
import { observer } from 'mobx-react'

const Labels = observer(class _Labels extends Component {
  changeLabel = (val) => {
    const alwaysArrayValue = val.map ? val : [val]
    const newEntries = alwaysArrayValue.map(el => ({
      taxonomy_id: el.value
    }))
    const newEntriesId = newEntries.map(el => el.taxonomy_id)
    const difference = _.differenceBy(this.props.store.data[this.props.type][0].labels.map(el => el.id), newEntriesId)
    if (difference.length > 0) {
      console.log(this.props.store.data[this.props.type][0])
      difference.map(id => (this.props.store.data[this.props.type][0].taxonomables.find(el => el.taxonomy_id === id)._delete = true))
      this.props.store.sync()
    } else {
      this.props.store.data[this.props.type][0].taxonomables = newEntries
      this.props.store.sync()
    }
  }

  render () {
    const { store, type } = this.props

    const labels = store.data.taxonomies.filter(el => el.type === 'label')
    const differenceLabels = _.differenceBy(labels, store.data[type][0].labels.filter(el => el.type === 'label'), 'id')
    const labelOptions = differenceLabels.map(el => { return { value: el.id, label: el.name, content: el.content, type: el.type } })
    const labelValues = store.data[type][0].labels.map(el => ({
      value: el.id,
      label: el.name,
      content: el.content,
      type: el.type
    }))
    return <Select
      classNamePrefix={'taxonomies'}
      isMulti
      placeholder='Etykiety...'
      style={{ width: '200px' }}
      value={labelValues}
      options={labelOptions}
      onChange={(e) => this.changeLabel(e)}
    />
  }
})

export default Labels
