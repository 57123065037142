import React from 'react'
import { observer } from 'mobx-react'
import { Input, List } from 'semantic-ui-react'
import SortableTree from 'react-sortable-tree'

import ContentForm from 'contents/forms/ContentForm'
import Tree from 'contents/tree/Tree'

import Store from 'services/StoreStore'

import 'react-sortable-tree/style.css'

export const singleStore = () => Store(storeQuery)
const currentQuery = () => Store(storeQuery).query === storeQuery
export const storeQuery = `
  {
    taxonomies: search(type: "taxonomy", source: "database") {
      ... on Taxonomy {
        id
        indexType
        type
        name
        content
        parent_id
      }
    }
  }`

const types = {
  categories: 'category',
  labels: 'label'
}

const CategoryTree = observer(class _CategoryTree extends React.Component {
  state = {
    newTaxonomy: '',
    newType: 'category',
    tree: false
  }

  componentDidMount () {
    singleStore()
    this.setState({
      newType: types[this.props.match.url.split('/').slice(-1)[0]]
    })
  }

  componentWillUnmount () {
    singleStore().unlock()
  }

  loadTaxonomy = (taxonomy, store) => {
    this.setState({
      tree: <div>
        <h3>{taxonomy.name}</h3>
        <Tree treeData={JSON.parse(taxonomy.content)} id={taxonomy.id} from={'label'} store={store} />
      </div>
    })
  }

  addTaxonomy = () => {
    this.props.store.executeMutation('createtaxonomy', {
      name: this.state.newTaxonomy,
      type: this.state.newType,
      content: '{}'
    })
    this.setState({
      newTaxonomy: ''
    })
  }
  recursiveUpdate = (obj, parent = null) => {
    const taxonomy = this.props.store.data.taxonomies.find(el => el.id === obj.id)
    if (parent !== taxonomy.parent_id) {
      console.log('changed: ' + taxonomy.parent_id + ' to ' + parent)
      taxonomy.parent_id = parent
      this.props.store.sync()
    }
    obj.children.map(el => this.recursiveUpdate(el, obj.id))
  }
  updateData = data => {
    console.log(data)
    data.map(this.recursiveUpdate)
  }
  contentFormSubmit = (formData, store, id) => {
    const record = store.data.taxonomies.find(el => parseInt(id) === el.id)
    record.content = JSON.stringify(formData)
    store.sync()
  }

  render () {
    const { store } = this.props
    const { newTaxonomy, newType, modified } = this.state
    if (!store.loaded || !currentQuery()) return null
    const { taxonomies } = store.data
    const type = this.state.newType

    const nameWithChildren = taxonomy => ({
      expanded: true,
      id: taxonomy.id,
      parent: taxonomy.parent_id,
      title: taxonomy.name,
      children: taxonomies.filter(el => el.parent_id === taxonomy.id).map(nameWithChildren)
    })
    const treeData = taxonomies.filter(el => !el.parent_id && el.type === newType).map(nameWithChildren)

    return <div>
      <Input
        action={{ content: 'Save', onClick: () => this.addTaxonomy() }}
        label={'Add new ' + type}
        name={'newTaxonomy'}
        type='text'
        id={'newTaxonomy'}
        placeholder={type + ' name...'}
        value={newTaxonomy}
        onChange={e => this.setState({
          newTaxonomy: e.target.value
        })}
      />

      <List>
        {type === 'label' && taxonomies.filter(el => el.type === this.state.newType).map(taxonomy => {
          return <List.Item
            key={taxonomy.id}
            // onClick={() => {this.loadTaxonomy(taxonomy, store)}}
          >
            {taxonomy.name}
          </List.Item>
        })}
        { /* <div>{this.state.tree}</div> */ }
      </List>
      <br />

      {type === 'category' && <div style={{ height: 300 }}>
        <SortableTree
          treeData={treeData}
          onChange={this.updateData}
          generateNodeProps={rowInfo => ({
            onClick: () => this.setState({ modified: rowInfo.node.id })
          })}
        />
      </div>
      }
      {modified && <div>
        <ContentForm
          store={store}
          {...this.props}
          id={modified}
          onSubmit={({ formData }) => this.contentFormSubmit(formData, store, modified)}
          from={type}
        />
      </div>}
    </div>
  }
})

export default props => <CategoryTree store={singleStore()} {...props} />
