export const routeToIndexType = (route) => {
  const types = {
    'contacts': 'contact',
    'contents': 'content',
    'work': 'work',
    'products': 'product',
    'sets': 'set'
  }
  return types[route]
}

export const indexTypeToRoute = (type) => {
  const routes = {
    'contact': 'contacts',
    'content': 'contents',
    'work': 'work',
    'product': 'products',
    'set': 'sets'
  }
  return routes[type]
}
