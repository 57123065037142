import React, { Component } from 'react'

import { acceptInvitation } from 'services/auth'

class InvitationManager extends Component {
  componentDidMount () {
    const { token, status } = this.props.match.params

    if (status === 'accept') {
      acceptInvitation(token, status)
    }
  }

  render () {
    const { status } = this.props.match.params

    if (status === 'accept') return <div>Invitation accepted</div>
    return <div>Invitation declined</div>
  }
}

export default InvitationManager
