import Store from 'services/Store'

let stores = {}
export default (query, channel = 'default') => {
  if (stores[channel]) {
    stores[channel].setQuery(query)
  } else {
    stores[channel] = new Store(query, channel)
  }
  return stores[channel]
}
