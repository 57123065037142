// TODO: to nie komponent, to serwis
export const toggleCheck = that => id => () => {
  that.setState(state => {
    const index = state.checked.indexOf(id)
    if (index === -1) {
      state.checked.push(id)
    } else {
      state.checked.splice(index, 1)
    }
    return state
  })
}
