import React from 'react'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import moment from 'moment'
import _ from 'lodash'
import { Menu, Input } from 'semantic-ui-react'
import SwipeableViews from 'react-swipeable-views'
import { observer } from 'mobx-react'
import Avatar from 'react-avatar'
import {nicePrompt} from 'services/prompt'
import Stream from 'components/Stream'
import CustomData from 'components/CustomData'
import {default as FuncInput} from 'components/Input'

import Board from './Board'
import Monday from './Monday'

import Grid from 'components/Grid'
import Store from 'services/StoreStore'
import ToggleObserve from "components/ToggleObserve"
import Labels from 'components/Labels'
import { squareButton } from '../components/Generics'

const singleStore = id => Store(storeQuery(id))
const currentQuery = id => Store(storeQuery(id)).query === storeQuery(id)
export const storeQuery = id => `
  fragment contentFields on Content {
    id
    indexType
    name
    content
  }
  {
    work: search(type: "work", source: "database", filter: "id:${id}") {
      ... on Work {
        id
        indexType
        type
        name
        content
        custom
        information {
          id
          type
          name
          content(tesseract: true)
        }
        contents {
          id
          type
          indexType
          name
          content
        }
        tasks {
          id
          type
          indexType
          name
          assigned
          content
          end_date
          information {
            id
            type
            name
            content
          }
        }
        labels {
          id
          type
          name
          content
        }
        taxonomies {
          id
          type
          name
          content
        }
        taxonomables {
          id
          indexType
          parent_id
          parent_type
          taxonomy_id
        }
        observables {
          id
          indexType
          parent_id
          parent_type
          user_id
        }
      }
    }
    organization {
      users {
        id
        name
      }
    }
    getSettings {
      setting
      value
    }
    processes: search(type: "content", source: "database", filter: "type:bpmn") {
      ...contentFields
    }
    contracts: search(type: "content", source: "database", filter: "type:contract") {
      ...contentFields
    }
    offers: search(type: "content", source: "database", filter: "type:offer") {
      ...contentFields
    }
    grades: search(type: "content", source: "database", filter: "type:rating") {
      ...contentFields
    }
    taxonomies: search(type:"taxonomy", source:"database") {
      ... on Taxonomy {
        id
        name
        type
        content
      }
    }
  }`

let interval = null
const delayedSync = func => {
  if (interval) clearTimeout(interval)
  interval = setTimeout(func, 5000)
}

const Single = observer(class _Single extends React.Component {
  state = {
    newTask: '',
    openCalendar: null,
    activeTab: 0
  }
  componentDidMount () {
    singleStore(this.props.id)
  }
  componentWillUnmount () {
    singleStore(this.props.id).unlock()
  }
  set = path => e => {
    const value = e.target ? e.target.value : e
    const object = this.props.store.data.work[0]
    _.set(object, path, value)
  }
  get = path => {
    const object = this.props.store.data.work[0]
    return _.get(object, path)
  }
  save = () => {
    this.props.store.sync()
  }
  createTask = () => {
    this.props.store.executeMutation('createtask', {
      name: this.state.newTask,
      type: 'standard',
      parent_id: this.props.store.data.work[0].id,
      parent_type: 'sets'
    })
    this.setState({
      newTask: ''
    })
  }
  onChange = tab => {
    this.setState({
      activeTab: tab
    })
  }
  addContent = (type) => () => {
    nicePrompt({
      title: 'Nazwa nowej treści',
      confirm: 'Dodaj',
      cancel: 'Anuluj'
    }).then(name => {
      this.props.store.feedback = id => {
        const object = this.props.store.data.work[0]
        const content = JSON.parse(object.content)
        if (!content.contents) content.contents = []
        content.contents.push(id)
        object.content = JSON.stringify(content)
        this.props.store.sync()
      }
      this.props.store.executeMutation('createcontent', {
        name: name,
        type: type,
        content: '{}'
      })
    })
  }
  render () {
    const { get, set, save } = this
    const { id } = this.props
    const { store } = this.props
    if (!store.loaded || !currentQuery(id)) return null
    const object = store.data.work[0]

    return <Grid>
      <div style={{ gridArea: 'main_data', padding: '0 5px' }} size='100px' area='main_data' type='row'>
        <div>
          <div style={{ width: '100%', padding: 5, display: 'flex', alignItems: 'center' }}>
            <Link to={'/work'}>
              <div style={squareButton()}>
                <i className='fal fa-arrow-left' />
              </div>
            </Link>
            <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', marginRight: '10px' }} />
            <div>
              <Avatar name='Foo Bar' size={50} round />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <div>
                <input className='smoothInput' value={get('name')} onChange={set('name')} onBlur={save} />
              </div>
            </div>
          </div>
          <div style={{ width: '100%', padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <div style={{ marginRight: '10px' }}>
              <Labels store={store} type={'work'} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
              <ToggleObserve type={'work'} store={store} />
              <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
              <i className='fal fa-trash fa-fw' style={{ marginRight: '5px' }} onClick={() => {
                store.data.work[0]._delete = true
                store.sync()
                window.location.assign('/work')
              }} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ gridArea: 'tabs', position: 'relative' }} size='42px' area='tabs' type='row'>
        <Menu>
          <Menu.Item
            active={this.state.activeTab === 0}
            onClick={() => this.setState({activeTab: 0})}
          >
            Stream
          </Menu.Item>
          <Menu.Item
            active={this.state.activeTab === -1}
            onClick={() => this.setState({activeTab: -1})}
          >
            Custom
          </Menu.Item>
          {get('contents') && get('contents').filter(el => el.type === 'kanban').map((content, ind) => <Menu.Item
            active={this.state.activeTab === content.id + 1}
            onClick={() => this.setState({activeTab: content.id + 1})}
          >
            {ind}
          </Menu.Item>)}
          <Menu.Item
            onClick={this.addContent('kanban')}
          >
            + k
          </Menu.Item>
          {get('contents') && get('contents').filter(el => el.type === 'monday').map((content, ind) => <Menu.Item
            active={this.state.activeTab === content.id + 1}
            onClick={() => this.setState({activeTab: content.id + 1})}
          >
            {ind}
          </Menu.Item>)}
          <Menu.Item
            onClick={this.addContent('monday')}
          >
            + m
          </Menu.Item>
        </Menu>
      </div>
      <div style={{ gridArea: 'main', position: 'relative' }} area='main'>
        {this.state.activeTab === -1 && <div style={{ padding: 10, position: 'relative' }}>
          <FuncInput store={store} path='custom' mainPath='data.work.0.' customProps={{
             type: 'Work'
          }} Component={CustomData} />
        </div>}
        {this.state.activeTab === 0 && <div style={{ padding: 10, position: 'relative' }}>
          <Stream store={store} id={object.id} type='work' />
        </div>}
        {get('contents') && get('contents').map((content, ind) => this.state.activeTab === content.id + 1 && <div style={{ padding: 10, position: 'relative', overflowY: 'hidden' }}>
          {content.type === 'monday' && <div>
            <Input value={content.name} onBlur={store.sync} onChange={e => (content.name = e.target.value)} style={{ marginBottom: 10 }} /><br />
            <Monday
              id={content.id}
              parent={object.id}
              object={JSON.parse(content.content)}
              onChangeObject={(contentData) => {
                content.content = JSON.stringify(contentData)
                delayedSync(store.sync)
              }}
              tasks={get('tasks').filter(el => el.type === 'monday' && JSON.parse(el.content).monday === content.id)}
              onChangeTasks={tasks => {
                tasks.map(task => {
                  if (task.id) {
                    const originalTask = object.tasks.find(el => task.id === el.id)
                    originalTask.content = task.content
                    delayedSync(store.sync)
                  } else {
                    console.log(JSON.parse(JSON.stringify(object)))
                    object.tasks.push(task)
                    console.log(JSON.parse(JSON.stringify(object)))
                    store.sync()
                  }
                })
              }}
            />
          </div>}
          {content.type === 'kanban' && <div>
            <Input value={content.name} onBlur={store.sync} onChange={e => (content.name = e.target.value)} style={{ marginBottom: 10 }} /><br />
            <Board object={JSON.parse(content.content)} onChange={(contentData) => {
              content.content = JSON.stringify(contentData)
              delayedSync(store.sync)
            }} />
          </div>}
        </div>)}
      </div>
    </Grid>
  }
})

export default props => <Single store={singleStore(props.match.params.id)} id={props.match.params.id} {...props} />
