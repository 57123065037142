import React, { Component } from 'react'
import { Route, Redirect, BrowserRouter as Router, Link, Switch, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Grid from 'components/Grid'
import { SizeMe } from 'react-sizeme'
import {HotKeys} from 'react-hotkeys'
// import Main from './Main/Main'
// import Login from './Login/Login'
// import Logout from './Login/Logout'
// import ActionsList from './Actions/ActionsList'
// import ActionCreate from './Actions/ActionCreate'
// import ActionCreate2 from './Actions/ActionCreate2'
// import ActionEdit from './Actions/ActionEdit'
// import ActionGenerate from './Actions/ActionGenerate'
// import ActionGenerate2 from './Actions/ActionGenerate2'
// import ProcessList from './Process/ProcessList'
// import CustomForm from 'components/CustomForm';
// import BusinessForm from 'components/BusinessForm';
// import BusinessForm2 from 'components/BusinessForm2';
// import StepByStepForm from 'components/StepByStepForm';
import {JWT, GlobalStorage} from 'storwork-stores'
import {
  SearchkitManager, SearchkitProvider, SearchBox, Hits, RefinementListFilter
} from 'searchkit'
import Navbar from "./components/Navbar";
import moment from 'moment'
import localization from 'moment/locale/pl'
// import MarkdownEditor from "./components/MarkdownEditor";
import Contacts from './contacts/Routes'
import Settings from './settings/Routes'
import Contents from './contents/Routes'
import Products from './products/Routes'
import Sets from './sets/Routes'
import Chat from './chat/Chat'
import Notes from './notes/Notes'
import Work from './work/Routes'
import Dashboard from './work/Dashboard'
import Login from './auth/Login'
import Register from './auth/Register'
import OrganizationInvite from './auth/OrganizationInvite'
import InvitationManager from './auth/InvitationManager'
import Livechat from './chat/Livechat'
import Store from 'services/StoreStore'

const getNotificationStore = () => Store(`
  {
    notifications {
      id
      text
      parent_type
      parent_id
      isread
      time
      counter
    }
  }`, 'notifications')

const getTasksStore = () => Store(`
  fragment userFields on User {
    id
    first_name
    last_name
    name
    indexType
    tasks(completed:false) {
      id
      name
      parent_type
      parent_id
      start_date
      end_date
      assigned
      created_at
      updated_at
      content
      completed
      type
      indexType
    }
  }
  {
    me {
      ...userFields
    }
  }`, 'tasks')

moment.locale('pl', localization)

const map = {
  'addText': 'a t',
  'addImage': 'a i',
  'addRecord': 'a r'
}

const Redirector = ({location}) => {
  const token = window.localStorage.getItem('token')
  if (!token && location.pathname !== '/register' && location.pathname !== '/login') {
    // If you are not logged in...
    // And you are not in one of non-auth pages
    return <Redirect to={'/login'} /> // Go to login
  }
  return null
}

const Logout = () => {
  window.localStorage.clear()
  window.location.href = '/'
}

const TabIcon = ({badge = '', href = '#', title, icon, mostLeft, click = () => {}}) => {
  return <Link onClick={click} to={href} style={{borderLeft: mostLeft ? 0 : '1px solid rgba(0,0,0,0.2)', padding: '6px 0', color: '#111111', textDecoration: 'none', flexGrow: 1, fontSize: 9, fontVariant: 'small-caps', textAlign: 'center', width: 20}}>
    <div style={{fontSize: 16}}>
      <i className={icon}></i> {badge}
    </div>
    {title}
  </Link>
}

const QuickAddButton = ({link, icon, name, click}) => {
  return <Link to={link} onClick={click} style={{
    width: '90px',
    margin: '15px',
    float: 'left',
    textAlign: 'center',
    display: 'block'
  }}>
    <span style={{
      height: '90px',
      width: '90px',
      backgroundColor: 'rgba(0,0,0,0.6)',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <i className={'fal fa-fw fa-2x ' + icon} style={{color: 'white'}} />
    </span>
    <div style={{marginTop: 5}}>{name}</div>
  </Link>
}

const RouteListener = withRouter(class _RouteListener extends React.Component {
  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.onRouteChanged()
    }
  }
  render () {
    return null
  }
})

const MenuLink = ({to, children, closeMenu}) => <Link style={{
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderTop: '1px solid rgba(0,0,0,0.2)',
  fontSize: 18,
  textTransform: 'uppercase'
}} to={to} onClick={closeMenu}>{children}</Link>

const App = observer(class _App extends Component {
  state = {
    gsInit: false,
    menuActive: false,
    quickaddActive: false,
    searchActive: false,
    searchContent: ''
  }

  checkIsLogged = () => {
    GlobalStorage.initStorage([
      'group_users', 'group_taxonomies', 'strategies', 'organization_settings', 'JWT_TOKEN'
    ]).then(() => {
      this.setState({
        gsInit: true
      })
    })
  }

  componentWillMount () {
    this.checkIsLogged()
  }

  routeChanged = () => {
    this.setState({
      searchActive: false,
      searchContent: ''
    })
  }
  render () {
    const {notificationStore, tasksStore} = this.props
    if (!notificationStore.loaded) return null
    if (!tasksStore.loaded) return null
    const {notifications} = notificationStore.data
    // console.log(notifications)
    // console.log(tasksStore)
    const chatNotifications = notifications.filter(el => el.parent_type === 'channels')
    const restNotifications = notifications.filter(el => el.parent_type !== 'channels')

    const token = window.localStorage.getItem('token')
    const {menuActive, quickaddActive, searchActive, searchContent} = this.state
    return <HotKeys keyMap={map}><Router><React.Fragment>
      <RouteListener onRouteChanged={this.routeChanged} />
      <div style={{
        position: 'fixed',
        top: 50,
        right: menuActive ? 0 : '100%',
        bottom: 0,
        width: '100%',
        transition: '300ms ease-in-out',
        background: 'white',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly'
      }}>
        <MenuLink closeMenu={() => this.setState({menuActive: false})} to='/contacts'>Kontakty</MenuLink>
        <MenuLink closeMenu={() => this.setState({menuActive: false})} to='/contents'>Treści</MenuLink>
        <MenuLink closeMenu={() => this.setState({menuActive: false})} to='/work'>Prace</MenuLink>
        <MenuLink closeMenu={() => this.setState({menuActive: false})} to='/products'>Produkty</MenuLink>
        <MenuLink closeMenu={() => this.setState({menuActive: false})} to='/settings/custom'>Ustawienia</MenuLink>
        <MenuLink closeMenu={() => this.setState({menuActive: false})} to='/livechat'>Livechat</MenuLink>
        <MenuLink closeMenu={() => this.setState({menuActive: false})} to='/logout'>Wyloguj</MenuLink>
      </div>
      <div style={{
        position: 'fixed',
        top: quickaddActive ? 0 : 2000,
        right: 0,
        left: 0,
        height: '100%',
        transition: '300ms ease-in-out',
        background: 'white',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <div style={{
          position: 'absolute',
          right: 20,
          top: 20,
          cursor: 'pointer'
        }} onClick={() => this.setState({quickaddActive: false})}>
          <i className="fal fa-times"></i>
        </div>
        <div>
          <QuickAddButton click={() => this.setState({quickaddActive: false})} link={{
            pathname: '/contacts',
            state: {action: 'addText'}
          }} icon='fa-user-edit' name='Kontakt z nazwy' />
          <QuickAddButton click={() => this.setState({quickaddActive: false})} link={{
            pathname: '/contacts',
            state: {action: 'addImage'}
          }} icon='fa-user-circle' name='Kontakt ze zdjęcia' />
          <div style={{clear: 'both'}}></div>
          <QuickAddButton click={() => this.setState({quickaddActive: false})} link={{
            pathname: '/products',
            state: {action: 'addProductText'}
          }} icon='fa-edit' name='Przedmiot z nazwy' />
          <QuickAddButton click={() => this.setState({quickaddActive: false})} link={{
            pathname: '/products',
            state: {action: 'addProductImage'}
          }} icon='fa-camera' name='Przedmiot ze zdjęcia' />
          <div style={{clear: 'both'}}></div>
          <QuickAddButton click={() => this.setState({quickaddActive: false})} link={{
            pathname: '/notes',
            state: {action: 'addRecord'}
          }} icon='fa-microphone' name='Notatka głosowa' />
          <QuickAddButton click={() => this.setState({quickaddActive: false})} link={{
            pathname: '/notes',
            state: {action: 'addImage'}
          }} icon='fa-sticky-note' name='Notatka - zdjęcie' />
        </div>
      </div>
      <Grid>
        <div style={{gridArea: 'navbar'}} size='50px' area='navbar' type='row'>
          {token && <Navbar
            searchActive={searchActive}
            searchContent={searchContent}
            modifySearch={(e, cb) => this.setState({searchContent: e.target.value}, cb)}
            toggleSearch={(newState = !searchActive) => this.setState({searchActive: newState})}
            toggleMenu={() => this.setState({menuActive: !menuActive})}
          />}
        </div>
        <div style={{gridArea: 'tabs', display: 'flex', background: '#EEE', borderTop: '1px solid rgba(0,0,0,0.3)'}} size='50px' area='tabs' type='row' direction='bottom'>
          <TabIcon href='/calendar' title='Kalendarz' icon='fal fa-fw fa-calendar' mostLeft />
          <TabIcon href='/notes' title='Notatki' icon='fal fa-fw fa-book-open' />
          <TabIcon badge={restNotifications.filter(el => el.isread === 0).length} href='/' title='Dashboard' icon='fal fa-fw fa-home' />
          <TabIcon click={() => this.setState({quickaddActive: true})} title='Dodaj' icon='fal fa-fw fa-plus' />
          <TabIcon badge={chatNotifications.filter(el => el.isread === 0).length} href='/chat' title='Czat' icon='fal fa-fw fa-comment' />
        </div>
        <div style={{gridArea: 'main', position: 'relative', overflow: 'auto'}} area='main'>
          <div style={{clear: 'both'}} />
          <Route path='/' component={Redirector} />
          <Route exact path='/' render={props => <Dashboard {...props} tasksStore={tasksStore} notificationStore={notificationStore} notifications={restNotifications} />} />

          <Contacts />
          <Work />
          <Contents />
          <Route path='/settings' component={Settings} />
          <Route path='/logout' component={() => {
            Logout()
            return null
          }} />
          <Products />
          <Sets />
          <Route exact path='/notes' component={Notes} />
          <Route path='/chat' exact render={props => <Chat {...props} notificationStore={notificationStore} notifications={chatNotifications} />} />
          <Route path='/livechat' exact component={Livechat} />
          <Route path='/login' exact component={Login} />
          <Route path='/register' exact component={Register} />
          <Route path='/organization/invite' exact component={OrganizationInvite} />
          <Route path='/invitation/:token/:status' exact component={InvitationManager} />
        </div>
      </Grid>
    </React.Fragment></Router></HotKeys>
  }
})

export default () => <App notificationStore={getNotificationStore()} tasksStore={getTasksStore()} />
