import React from 'react'

export const CustomField = props => {
  return {
    name: 'custom_field',
    title: 'Custom field',
    className: 'fa fa-info-circle',
    action (editor) {
      console.log(editor)
      // console.log(props)
      const cm = editor.codemirror
      // cm.display.wrapper += '<div>jkhdsfhfjk</div>'
      console.log(cm.display.wrapper.CodeMirror)
      // let output = '';
      // const selectedText = cm.getSelection();
      // const text = selectedText || 'placeholder';
      //
      // output = '<div>' + text + '</div>';
      // cm.replaceSelection(output);
    }
  }
}

export const Popup = props => {
  return <div>To jest popup</div>
}
