import React, { Component } from 'react'
import Form from 'react-jsonschema-form'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import FormBuilder from 'components/FormBuilder'
import LabelSelect from 'components/semantic-ui/LabelSelect'
import SemanticInput from 'components/semantic-ui/SemanticInput'
import SemanticSelect from 'components/semantic-ui/SemanticSelect'
import MarkdownEditor from 'components/MarkdownEditor'

import { createActionForm } from './services/create'

// const b64DecodeUnicode = (str) => {
//   // Going backwards: from bytestream, to percent-encoding, to original string.
//   return decodeURIComponent(atob(str).split('').map(function (c) {
//     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
//   }).join(''))
// }

const Content = observer(class _Content extends Component {
  state = {
    action: null
  };

  render () {
    // if (!this.state.action) return null
    const type = this.props.from
    const asTaxonomies = ['category', 'label']
    const from = (asTaxonomies.indexOf(this.props.from) !== -1 ? 'taxonomies' : 'content')
    const record = this.props.store.data[from].find(el => el.id === parseInt(this.props.id))
    const content = JSON.parse(record.content)
    const schema = createActionForm(type)
    const jsonSchema = schema.json
    const uiSchema = schema.ui
    const formData = {
      // type: record.type,
      name: record.name,
      // description: action.description ? action.description : '',
      // validation: action.validation ? action.validation : '',
      content_value: (type === 'offer' || type === 'contract' ? content.content_value : {}),
      assign_label: (type === 'form' ? content.assign_label : {}),
      logic: (type === 'rating' ? content.logic : {}),
      json_schema: content.json_schema,
      ui_schema: content.ui_schema
    }

    const widgets = {
      formBuilder: FormBuilder,
      labelSelect: LabelSelect,
      semanticInput: SemanticInput,
      semanticSelect: SemanticSelect,
      markdownEditor: MarkdownEditor
    }

    return (
      <div className='bootstrap'>
        <div className='row' style={{ padding: '24px 0' }}>
          <div className={'col-sm-2 pull-left'} />
          <div className={'col-sm-8'}>
            <Form schema={jsonSchema}
              uiSchema={uiSchema}
              formData={formData}
              onChange={this.props.onChange}
              widgets={widgets}
            >
              <Link to={`/render/form/` + this.props.id} className={'btn btn-primary pull-left'}>Podgląd formularza</Link>
            </Form>
          </div>
          <div className={'col-sm-2 pull-left'} />
        </div>
      </div>
    )
  }
})

export default class ContentForm extends React.Component {
  componentDidMount () {
    if(this.props.beforeSave)
      this.props.beforeSave(() => {})
  }
  render () {
    const { id } = this.props
    return <Content store={this.props.store} id={id} onSubmit={this.props.onSubmit} from={this.props.from} onChange={this.props.onChange}/>
  }
}
