import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SortableTree, { getFlatDataFromTree, getTreeFromFlatData, addNodeUnderParent, changeNodeAtPath, removeNodeAtPath } from 'react-sortable-tree'

import 'react-sortable-tree/style.css'

export const uniqeID = (from = 2, to = 9) => {
  return Math.random().toString(36).substr(from, to)
}

const initialData = [
  { id: uniqeID(), name: 'Element 1', parent: null }
]

export default class Tree extends Component {
  state = {
    treeData: [],
    from: 'content'
  }
  constructor (props) {
    super(props)

    this.state = {
      treeData: getTreeFromFlatData({
        flatData: initialData.map(node => ({ ...node, title: node.name, expanded: true })),
        getKey: node => node.id, // resolve a node's key
        getParentKey: node => node.parent, // resolve a node's parent's key
        rootKey: null // The value of the parent key when there is no parent (i.e., at root level)
      })
    }
  }

  componentWillReceiveProps (props) {
    this.getData(props)
  }

  componentDidMount () {
    this.getData(this.props)
  }

  getData = (props) => {
    const asTaxonomies = ['category', 'label']
    const from = (asTaxonomies.indexOf(props.from) !== -1 ? 'taxonomies' : 'content')
    const record = props.store.data[from].find(el => el.id === parseInt(props.id))
    const content = JSON.parse(record.content)
    const keys = Object.keys(content)
    const data = keys.length > 0 ? content : initialData
    this.setState({
      treeData: getTreeFromFlatData({
        flatData: data.map(node => ({ ...node, title: node.name, expanded: true })),
        getKey: node => node.id, // resolve a node's key
        getParentKey: node => node.parent, // resolve a node's parent's key
        rootKey: null // The value of the parent key when there is no parent (i.e., at root level)
      }),
      from: from
    })
  }

  save = (e) => {
    console.log(this.state)
    const flatData = getFlatDataFromTree({
      treeData: this.state.treeData,
      getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
      ignoreCollapsed: false // Makes sure you traverse every node in the tree, not just the visible ones
    }).map(({ node, path }) => ({
      id: node.id,
      name: node.name,

      // The last entry in the path is this node's key
      // The second to last entry (accessed here) is the parent node's key
      parent: path.length > 1 ? path[path.length - 2] : null
    }))
    const record = this.props.store.data[this.state.from].find(el => el.id === parseInt(this.props.id))
    record.content = JSON.stringify(flatData)
    this.props.store.sync()
  }

  render () {
    const getNodeKey = ({ treeIndex }) => treeIndex
    return (
      <div style={{ height: 400 }}>
        <SortableTree
          treeData={this.state.treeData}
          onChange={treeData => this.setState({ treeData })}
          onClick={() => { console.log(this) }}
          generateNodeProps={({ node, path }) => ({
            title: (
              <input
                style={{ fontSize: '1.1rem' }}
                value={node.name}
                onChange={event => {
                  const name = event.target.value

                  this.setState(state => ({
                    treeData: changeNodeAtPath({
                      treeData: state.treeData,
                      path,
                      getNodeKey,
                      newNode: { ...node, name }
                    })
                  }))
                }}
              />
            ),
            buttons: [
              <button
                onClick={() => {
                  const num = node.children ? node.children.length + 1 : 1
                  // If you want make normal ID's just uncomment line bellow
                  // const id = path.concat([num]).join('-')
                  const id = uniqeID()
                  this.setState(state => ({
                    treeData: addNodeUnderParent({
                      treeData: state.treeData,
                      parentKey: path[path.length - 1],
                      expandParent: true,
                      getNodeKey,
                      newNode: {
                        title: 'Element',
                        name: 'Element ' + num,
                        id: id
                      },
                      addAsFirstChild: state.addAsFirstChild
                    }).treeData
                  }))
                }}
              >
                Add Child
              </button>,
              <button
                onClick={() =>
                  this.setState(state => ({
                    treeData: removeNodeAtPath({
                      treeData: state.treeData,
                      path,
                      getNodeKey
                    })
                  }))
                }
              >
                Remove
              </button>
            ]
          })}
        />
        <button onClick={() => this.save(this.state.treeData)}>Zapisz</button> <br />
        <Link to={`/contents`} className={'btn btn-primary pull-left'}>Powrót do listy</Link>
      </div>
    )
  }
}
