import React from 'react'
import { Route } from 'react-router-dom'

import Calendar from './Calendar'
import Monday from './Monday'
// import List from './List'
import List from 'components/List'
import Single from './Single'

export default () => {
  return <React.Fragment>
    <Route path='/calendar' exact component={Calendar} />
    <Route path='/work/:id' exact component={Single} />
    <Route path='/work' key='work-list' exact component={List} />
  </React.Fragment>
}
