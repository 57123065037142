import client from 'socket.io-client'
import { decorate, observable } from 'mobx'
import jwtDecode from 'jwt-decode'

const org1key = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoyfSwib3JnYW5pemF0aW9uIjp7ImlkIjoxfSwiaWF0IjoxNTQ3NzA3MzQ3fQ.8Z6yS5zRWWMDC2eGaBA-1ly22iZh_Ygfzvf8662e1r4`

export default class Store {
  // observables
  token = null
  data = null
  loaded = false

  socket = null
  query = null
  locked = false
  channel = ''

  setQuery = query => {
    // console.log('Channel: ' + this.channel)
    // console.log('Try to set query to: ' + query)
    // console.log('Current query: ' + this.query)
    // console.log(this.locked)
    if (!this.socket) return
    const locked = this.locked
    this.locked = true
    if (locked || !query || query === this.query) return
    this.loaded = false
    this.query = query
    if (this.socket) this.emitQuery()
  }
  emitQuery = () => {
    this.socket.emit('newQuery', this.query)
  }
  sync = () => {
    this.socket.emit('sync', this.data)
  }
  declareVoiceChannel = channel => {
    this.socket.emit('declareVoiceChannel', channel)
  }
  executeMutation = (mutation, input) => {
    this.socket.emit('executeMutation', {
      mutation, input
    })
  }
  getData = data => {
    console.log(data)
    this.data = data // Data set first, it's important
    this.loaded = true
  }
  loadMore = () => {
    this.socket.emit('loadMore')
  }
  reload = () => {
    this.socket.emit('reload')
  }
  unlock = () => {
    console.log('unlocked')
    console.log(this.locked)
    this.locked = false
  }
  feedback = (data) => {} // Change to get for example ID
  getUser = () => this.token ? jwtDecode(this.token) : null
  error = err => console.log(err)
  initSocket = (query) => {
    const localToken = window.localStorage.getItem('token')
    const token = localToken || org1key
    this.token = token
    const socket = client(process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011', {
      query: {
        authorization: 'Bearer ' + token
      }
    })
    socket.on('connect', () => {
      console.log('Connected')
      this.socket = socket
      this.socket.on('feedback', data => {
        this.feedback(data)
      })
      this.socket.on('disconnect', function () {
        console.log('Disconnected')
      })
      this.socket.on('error', this.error)
      this.socket.on('newDataLoaded', this.getData)
      if (query) {
        console.log('Initial query set')
        this.setQuery(query)
      }
    })
  }
  constructor (query, channel) {
    this.channel = channel
    this.initSocket(query)
  }
}

decorate(Store, {
  data: observable,
  token: observable,
  loaded: observable
})
