import React from 'react'
import { Link } from 'react-router-dom'
import { SearchkitManager, SearchkitProvider, Hits } from 'searchkit'
import SwipeableViews from 'react-swipeable-views'
import _ from 'lodash'
import { observer } from 'mobx-react'
import Form from 'react-jsonschema-form-semanticui-fixed'

import AlgoliaSearch from 'algoliasearch'
import { Creatable } from 'react-select'
import Select from 'react-select'
import Avatar from 'react-avatar'
import AutosizeInput from 'react-input-autosize'
import { Header, Dropdown, Divider, Input as SemanticInput, Icon } from 'semantic-ui-react'

import Stream from 'components/Stream'
import Grid from 'components/Grid'
import ToggleObserve from 'components/ToggleObserve'
import Labels from 'components/Labels'

import Store from 'services/StoreStore'
import { stateSetter } from 'services/helpers'
import { sendSms, sendEmail } from 'services/delivery'
import { registerVisit } from 'services/visits'
import { squareButton } from '../components/Generics'
import { deleteConnection, createConnection } from '../services/connections'
import ElasticSearchSelect from '../components/ElasticSearchSelect'
import { FullScreen, CloseButton } from 'components/Generics'
import Send from '../components/Send'
// import { SearchkitManager } from 'searchkit/lib/index'

export const singleStore = (id, channel = 'default') => Store(storeQuery(id), channel)
const currentQuery = id => Store(storeQuery(id)).query === storeQuery(id)
const storeQuery = id => `
  fragment contactFields on Contact {
    id
    indexType
    name
    position
    contact_data
    hourly
    status
    custom
    created_at
    updated_at
    information {
      id
      type
      name
      content(tesseract: true)
      created_at
      updated_at
    }
    tasks {
      id
      type
      indexType
      name
      assigned
      content
      completed
      end_date
      created_at
      updated_at
      information {
        id
        type
        name
        content
      }
    }
    labels {
      id
      type
      name
      content
    }
    taxonomies {
      id
      type
      name
      content
    }
    taxonomables {
      id
      indexType
      parent_id
      parent_type
      taxonomy_id
    }
    observables {
      id
      indexType
      parent_id
      parent_type
      user_id
    }
  }
  fragment contentFields on Content {
    id
    indexType
    type
    name
    content
  }
  {
    contact: search(type: "contact", source: "database", filter: "id:${id}") {
      ...contactFields
    }
    contacts: search(type:"contact", source:"database") {
      ... on Contact {
        id
        name
      }
    }
    getSettings {
      setting
      value
    }
    processes: search(type: "content", source: "database", filter: "type:bpmn") {
      ...contentFields
    }
    contracts: search(type: "content", source: "database", filter: "type:contract") {
      ...contentFields
    }
    offers: search(type: "content", source: "database", filter: "type:offer") {
      ...contentFields
    }
    grades: search(type: "content", source: "database", filter: "type:rating") {
      ...contentFields
    }
    connectionsFrom: search(type: "connection", source:"database", filter:"from_id:${id}") {
      ... on Connection {
        id
        indexType
        type
        from
        to
        from_id
        from_type
        to_id
        to_type
      }
    }
    connectionsTo: search(type: "connection", source:"database", filter:"to_id:${id}") {
      ... on Connection {
        id
        indexType
        type
        from
        to
        from_id
        from_type
        to_id
        to_type
      }
    }
    taxonomies: search(type:"taxonomy", source:"database") {
      ... on Taxonomy {
        id
        name
        type
        content
        indexType
      }
    }
    organization {
      users {
        id
        name
      }
    }
  }`

const CustomData = observer(class _CustomData extends React.Component {
  stateSetter = stateSetter(this)
  render () {
    const { value, onChange, onBlur, store } = this.props
    const realValue = value || '{}'
    if (!store.loaded) return null
    const setting = store.data.getSettings.find(el => el.setting === 'customContact')
    const schema = setting ? setting.value : '{}'
    const jsonSchema = JSON.parse(schema)
    const formSchema = {
      type: 'object',
      required: [],
      properties: jsonSchema
    }

    return <React.Fragment>
      <Form
        schema={formSchema}
        formData={JSON.parse(realValue)}
        onChange={val => {
          onChange(JSON.stringify(val.formData))
          // console.log('change')
        }}
        onBlur={() => {
          // console.log('blur')
          onBlur()
        }}>
        <span />
      </Form>
    </React.Fragment>
  }
})

const ConnectionsData = observer(class _ConnectionsData extends React.Component {
  render () {
    const { store, id } = this.props;
    const record = store.data.contact.find(el => el.id === parseInt(id))

    const connections = store.data.connectionsFrom.concat(store.data.connectionsTo)

    return connections && <div>{connections.map(connection => {
      if(connection.from_id !== parseInt(id))
        return <div key={connection.id} style={{width: '100%', float: 'left'}}>
          <AutosizeInput placeholder='Rodzaj...' style={{ fontSize: 16 }} inputClassName='smoothInput'  value={connection.type} onChange={() => {}} /> {connection.from}
          <div onClick={() => deleteConnection(connection.id, 'to', store)} style={{float: 'right', cursor: 'pointer'}}>
            <i className='fal fa-trash fa-fw'/>
          </div>
        </div>
      else if(connection.to_id !== parseInt(id))
        return <div key={connection.id} style={{width: '100%', float: 'left'}}>
          <AutosizeInput placeholder='Rodzaj...' style={{ fontSize: 16 }} inputClassName='smoothInput'  value={connection.type} onChange={() => {}} /> {connection.to}
          <div onClick={() => deleteConnection(connection.id, 'from', store)} style={{float: 'right', cursor: 'pointer'}}>
            <i className='fal fa-trash fa-fw'/>
          </div>
        </div>
    })}
    </div>
  }
})



// TODO: abstractować
const Tabs = observer((props) => {
  const { activeTab, onChange, id, store, openOO, messageAction, contacts, value } = props;
  // console.log(props)
  const slideStyles = {
    padding: 10
  }
  return <SwipeableViews enableMouseEvents style={{
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }} resistance slideStyle={{
    overflow: 'visible'
  }} index={activeTab} onChangeIndex={onChange}>
    <div style={slideStyles}>
      <Divider horizontal>
        <Header as='h5'>
          <i className='fal fa-fw fa-phone' />&nbsp;
          Dane kontaktowe
        </Header>
      </Divider>
      <Input store={store} path='contact_data' Component={props => <ContactData {...props} messageAction={messageAction} />} />
      <br />
      <Divider horizontal>
        <Header as='h5'>
          <i className='fal fa-fw fa-align-justify' />&nbsp;
          Dane własne
        </Header>
      </Divider>
      <br />
      <Input store={store} path='custom' Component={CustomData} />
      <Divider horizontal>
        <Header as='h5'>
          <i className='fal fa-fw fa-link' />&nbsp;
          Relacje
        </Header>
      </Divider>
      <div>
        <ConnectionCreator id={id} store={store} value={value} contacts={contacts} />
        <ConnectionsData store={store} id={id} />
      </div>
    </div>
    <div style={slideStyles}>
      <Stream store={store} id={id} type='contact' />
    </div>
  </SwipeableViews>
})

class ConnectionCreator extends React.Component {
  state = {
    object: false,
    type: '',
    results: [],
    query: ''
  };

  onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState(state => {
      state[name] = value
      return state
    })
  }

  render() {
    const {id, store, value, onChange, contacts} = this.props

    const options = [
      {
        value: 'employee',
        label: 'Pracownik'
      },
      {
        value: 'employer',
        label: 'Pracodawca',
      },
      {
        value: 'friend',
        label: 'Znajomy'
      }
    ];

    const styles = {
      padding: '5px 0'
    }

    return <div>
      <div style={{float: 'right'}} onClick={() => {
        createConnection({
          from_id: parseInt(id),
          from_type: 'contacts',
          to_id: this.state.object.value,
          to_type: 'contacts',
          type: this.state.type,
          status: 3
        }, 'from', store)
      }}>
        <i className='fal fa-fw fa-plus'/>
      </div>
      <br/>
      <div style={styles}>
        <Select name={'type'} value={this.state.value} onChange={(e) => this.onChange({target: {name: 'type', value: e.value}})} placeholder={'Rodzaj połączenia...'} options={options}/>
      </div>
      <div style={styles}>
        <ElasticSearchSelect onChange={this.onChange} searchType={'contact'} value={this.state.object}/>
      </div>
    </div>
  }
}

const Input = observer(({ store, path, Component, style = {}, placeholder = '', options, mainPath = 'data.contact.0.' }) => {
  const fullPath = mainPath + path
  const params = {
    store: store,
    value: _.get(store, fullPath) || '',
    onChange: val => _.set(store, fullPath, val),
    onBlur: store.sync,
    style,
    placeholder
  }
  if (!store.loaded) return null
  if (Component) return <Component {...params} />
  if (options) {
    return <Dropdown style={style} text={options[params.value] || 'wybierz typ...'} icon={null}>
      <Dropdown.Menu>
        {Object.keys(options).map(option => <Dropdown.Item key={'TYPE_' + options[option]} onClick={() => params.onChange(option)} text={options[option]} />)}
      </Dropdown.Menu>
    </Dropdown>
  }
  return <AutosizeInput
    {...params}
    inputClassName='smoothInput'
    style={style}
    onChange={e => params.onChange(e.target.value)}
  />
})

const MainData = observer(class _MainData extends React.Component {
  state = {
    visitSaved: false
  }
  render () {
    const { visitSaved } = this.state
    const { store } = this.props
    if (!store.loaded) return null
    if (!visitSaved) {
      registerVisit(store.data.contact[0])
      this.setState({
        visitSaved: true
      })
    }

    return <div>
      <div style={{ width: '100%', padding: 5, display: 'flex', alignItems: 'center' }}>
        <Link to={'/contacts'}>
          <div style={squareButton()}>
            <i className='fal fa-arrow-left' />
          </div>
        </Link>
        <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', marginRight: '10px' }} />
        <div>
          <Avatar name='Foo Bar' size={50} round />
        </div>
        <div style={{ marginLeft: '10px' }}>
          <div>
            <Input style={{ fontSize: 16, fontWeight: 'bold' }} store={store} path='name' />
          </div>
          <div>
            <Input placeholder='Stanowisko...' style={{ fontSize: 16 }} store={store} path='position' />
          </div>
        </div>
      </div>
      <div style={{ width: '100%', padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <div style={{ marginRight: '10px' }}>
          <Labels store={store} type={'contact'} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
          <ToggleObserve type={'contact'} store={store} />
          <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
          <i className='fal fa-trash fa-fw' style={{ marginRight: '5px' }} onClick={() => {
            store.data.contact[0]._delete = true
            store.sync()
            window.location.assign('/contacts')
          }} />
        </div>
      </div>
    </div>
  }
})

const ContactData = observer(class _ContactData extends React.Component {
  state = {
    newData: '',
    places: []
  }
  stateSetter = stateSetter(this)
  algoliaSearch = (query) => {
    if (query.query === '') {
      this.setState({ places: [] })
      return
    }
    var places = AlgoliaSearch.initPlaces('plNY4ZFL5ADQ', 'bd37a20d536c52d323f6990876afec86')
    places.search(query, (err, res) => {
      if (err) {
        throw err
      }
      this.setState({ places: res.hits })
    })
  }
  render () {
    const { value, onChange, onBlur, messageAction } = this.props
    const { newData } = this.state
    const datas = JSON.parse(value || '[]')
    const filteredPlaces = this.state.places.filter(el => el.is_city || el.is_highway)
    const options = filteredPlaces.map((el, i) => {
      if (el.is_city) {
        return {
          value: `${el.locale_names.default}, ${el.administrative}, ${el.country.default}`,
          label: `${el.locale_names.default}, ${el.administrative}, ${el.country.default}`
        }
      } else if (el.is_highway) {
        return {
          value: `${el.locale_names.default}, ${el.city ? el.city.default : el.county.default}, ${el.administrative}, ${el.country.default}`,
          label: `${el.locale_names.default}, ${el.city ? el.city.default : el.county.default}, ${el.administrative}, ${el.country.default}`
        }
      }
    })
    const addData = e => {
      e.preventDefault()
      const types = {
        email: /@/g,
        phone: /^\d{3}-?\s?\d{3}-?\s?\d{3}$/,
        website: /http/g
      }
      const type = Object.keys(types).find(type => {
        return types[type].test(newData)
      }) || 'other'

      // deactivate all other
      datas.map(data => {
        if (data.type === type) data.status = (data.status &= 1) // Remove '2' bit
      })

      datas.push({
        type,
        value: newData,
        genre: '',
        status: 3
      })
      this.setState({ newData: '' })
      onChange(JSON.stringify(datas))
      onBlur()
    }
    const setData = path => e => {
      const value = e.target.value
      _.set(datas, path, value)
      onChange(JSON.stringify(datas))
    }

    const defaultData = (type, index) => {
      const filtered = datas.map((data, key) => {
        if(data.type === type) {
          if (key === index) {
            datas[key].status = 3;
          } else {
            datas[key].status = (data.status &= 1);
          }
        }
      })
      onChange(JSON.stringify(datas))
      onBlur()
    }

    const deleteData = (idx, type, value) => {
      if (window.confirm("Czy na pewno chcesz usunąć " + type + ': ' + value)) {
        datas.splice(idx, 1)
        onChange(JSON.stringify(datas))
        onBlur()
      }
    }
    const getData = path => _.get(datas, path)
    return <React.Fragment>
      <form onSubmit={addData} style={{
        borderBottom: '1px solid rgba(0,0,0,0.3)'
      }}>
        <div>
          <input placeholder='Dodaj nową daną...' style={{ border: 'none' }} value={newData} onChange={(e) =>
            this.setState({ newData: e.target.value }, () => {
              if (this.state.newData.match(/\s/g) && !this.state.newData.match(/^\d/)) {
                this.algoliaSearch({ query: this.state.newData })
              } else {
                this.algoliaSearch({ query: '' })
              }
            })
          } />
          <div style={{ position: 'absolute', zIndex: '99' }}>
            {options.map((el, i) => {
              const isEven = i % 2 === 0
              return <div key={i} style={{ background: (isEven ? 'lightGrey' : 'white') }} onClick={() => this.setState({ newData: el.value, places: [] })}>{el.value}</div>
            })}
          </div>
          <button style={{
            float: 'right',
            padding: '1px 5px',
            background: 'white',
            border: 0,
            color: 'black',
            cursor: 'pointer'
          }}>
            <i className={'fal fa-plus fa-fw'} />
          </button>
        </div>
        <br />
      </form>
      {datas.map(({ type, genre, status, value, id }, index) => {
        const isMain = (status & 2) !== 0
        const isActive = (status & 1) !== 0
        return <div key={index} style={{
          fontWeight: isMain ? 'bold' : 'normal',
          textDecoration: isActive ? 'none' : 'line-through',
          height: 70,
          padding: '5px 0'
        }}>
          <div style={{ float: 'right', fontSize: 12, width: 20, height: 70, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
            <div style={{ cursor: 'pointer' }}>
              <Dropdown icon='ellipsis horizontal' floating className='icon'>
                <Dropdown.Menu className='left'>
                    {type === 'email' && <Dropdown.Item>
                      <a onClick={() => messageAction('email', value)}>
                        <i className='fal fa-at fa-fw' /> Wyślij wiadomość
                      </a>
                    </Dropdown.Item>}
                    {type === 'email' && <Dropdown.Item>
                      <a href={'mailto:' + value}>
                        <i className='fal fa-envelope fa-fw' /> Wyślij wiadomość (klient e-mail)
                      </a>
                    </Dropdown.Item>}
                    {type === 'phone' && <Dropdown.Item>
                      <a href={'tel:' + value}>
                        <i className='fal fa-phone fa-fw' /> Zadzwoń
                      </a>
                    </Dropdown.Item>}
                    {type === 'phone' && <Dropdown.Item>
                      <a onClick={() => messageAction('phone', value)}>
                        <i className='fal fa-sms fa-fw' /> SMS
                      </a>
                    </Dropdown.Item>}
                    {type === 'website' && <Dropdown.Item>
                      <a target='_blank' href={value}>
                        <i className='fal fa-link fa-fw' /> Idź do strony
                      </a>
                    </Dropdown.Item>}
                  <Dropdown.Divider />
                    {!isMain && <Dropdown.Item onClick={() => defaultData(type, index)}>
                      <i className='fal fa-star fa-fw' /> Ustaw jako domyślny
                    </Dropdown.Item>}
                    {isActive && <Dropdown.Item onClick={() => deleteData(index, type, value)}>
                      <i className='fal fa-trash fa-fw' /> Usuń
                    </Dropdown.Item>}
                    {/*{!isActive && <Dropdown.Item onClick={() => deleteData(index)}>*/}
                      {/*<i className='fal fa-trash-restore fa-fw' /> Ustaw jako aktywny*/}
                    {/*</Dropdown.Item>}*/}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div style={{ height: 70, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <AutosizeInput placeholder='Rodzaj...' style={{ fontSize: 12 }} inputClassName='smoothInput' onBlur={() => onBlur()} value={getData(index + '.genre')} onChange={setData(index + '.genre')} />
            <AutosizeInput placeholder='Treść...' style={{ fontSize: 16 }} inputClassName='smoothInput' onBlur={() => onBlur()} value={getData(index + '.value')} onChange={setData(index + '.value')} />
          </div>
        </div>
      })}
    </React.Fragment>
  }
})


const Single = observer(class _Single extends React.Component {
  state = {
    activeTab: 1,
    sender: {
      active: false,
      type: '',
      to: '',
    }
  }
  stateSetter = stateSetter(this)
  componentDidMount () {
    singleStore(this.props.id)
  }
  componentWillUnmount () {
    singleStore(this.props.id).unlock()
  }

  messageAction = (type, value) => {
    this.setState(state => {
      state.sender.active = !this.state.sender.active
      state.sender.type = type
      state.sender.to = value
      return state
    })
  }

  render () {
    const { activeTab } = this.state
    const { id, store } = this.props
    if (!store.loaded || !currentQuery(id)) return null

    return <React.Fragment>
      <Grid>
        <div style={{ gridArea: 'main_data', borderBottom: '1px solid rgba(0,0,0,0.1)' }} size='100px' area='main_data' type='row'>
          <MainData store={singleStore(id)} {...this.props} />
        </div>
        <div style={{ gridArea: 'main', position: 'relative' }} area='main'>
          {this.state.sender.active &&
          <FullScreen active={this.state.sender.active} direction='left' style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center'
          }}>
            <Send store={singleStore(id)} type={this.state.sender.type} to={this.state.sender.to} state={this.state} closeDelay={(seconds) => {
              setTimeout(() => {
                this.setState({
                  sender: {
                    active: false
                  }
                })
              }, seconds*1000)
            }}/>
            <CloseButton click={() => {
              this.setState({
                sender: {
                  active: false
                }
              })
            }} />
          </FullScreen>}
          {!this.state.sender.active && <Tabs messageAction={(type, to) => this.messageAction(type, to)} store={singleStore(id)} id={id} onChange={this.stateSetter('activeTab')} activeTab={activeTab} contacts={store.data.contacts}/>}
        </div>
      </Grid>
    </React.Fragment>
  }
})

export default props => <Single id={props.match.params.id} store={singleStore(props.match.params.id)} />
