import React, { Component } from 'react'
import SimpleMDEEditor from 'yt-simplemde-editor'

class MarkdownEditor extends Component {
  state = {
    value: false
  }
  onChange = (e) => {
    this.setState({ value: e })
    this.props.onChange(e)
  }
  render () {
    return <div>
      <SimpleMDEEditor
        onChange={this.onChange}
        value={this.props.value}
        options={{
          previewRender: this.renderMarkdown,
          toolbar: [
            'bold',
            'italic',
            'heading',
            '|',
            'quote',
            'code',
            'table',
            'horizontal-rule',
            'unordered-list',
            'ordered-list',
            '|',
            'link',
            'image',
            '|',
            'preview',
            'side-by-side',
            'fullscreen'
          ]
        }}
      />
    </div>
  }
}

export default MarkdownEditor
