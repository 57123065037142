import React from 'react'
import { observer } from 'mobx-react'
import Form from 'react-jsonschema-form-semanticui-fixed'
import { stateSetter } from 'services/helpers'

export default observer(class _CustomData extends React.Component {
  stateSetter = stateSetter(this)
  render () {
    const { value, onChange, onBlur, store, type = 'Contact' } = this.props
    const realValue = value || '{}'
    if (!store.loaded) return null
    const setting = store.data.getSettings.find(el => el.setting === 'custom' + type)
    const schema = setting ? setting.value : '{}'
    const jsonSchema = JSON.parse(schema)
    const formSchema = {
      type: 'object',
      required: [],
      properties: jsonSchema
    }
    return <React.Fragment>
      <Form
        schema={formSchema}
        formData={JSON.parse(realValue)}
        onChange={val => {
          onChange(JSON.stringify(val.formData))
          console.log('change')
        }}
        onBlur={() => {
          console.log('blur')
          onBlur()
        }}>
        <span />
      </Form>
    </React.Fragment>
  }
})
