import React from 'react'
import { Label, Dropdown } from 'semantic-ui-react'

import Store from 'services/StoreStore'

const labelStore = () => Store(`
    query {
      search(type:"taxonomy", source:"database", filter:"type:label") {
        ... on Taxonomy {
          id
          type
          name
        }
      }
    }
  `, 'labels')

class LabelSelect extends React.Component {
  state = {
    label: false
  }
  componentDidMount () {
    labelStore()
  }
  componentWillUnmount () {
    labelStore().unlock()
  }

  onChange = (event, data) => {
    this.setState(state => {
      state[data.name] = data.value
      return state
    })
    this.props.onChange(data.value)
  }

  render () {
    const { props, state } = this
    if (!this.props.store.loaded) return null
    const labels = this.props.store.data.search
    const { placeholder, value } = props
    const options = labels.map(label => {
      return {
        key: label.id,
        text: label.name,
        value: label.id
      }
    })

    return (
      <div className='my-custom-widget'>
        <Label>Label</Label>
        <Dropdown
          name={'label'}
          options={options}
          placeholder={placeholder}
          value={state.label ? state.label : value}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

export default () => <LabelSelect store={labelStore()} />
