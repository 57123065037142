import React, { Component } from 'react'
import { invite } from 'services/auth'

class OrganizationInvite extends Component {
  state = {
    email: '',
    organization: ''
  }

  onChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    this.setState(state => {
      state[name] = value
      return state
    })
  }

  render () {
    return <div style={{ width: '100%', display: 'flex' }}>
      <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '50px' }}>
        <h1>Invite to organization</h1>
        <div>
          <select name={'organization'} onChange={this.onChange}>
            <option value=''>Choose organization...</option>
            <option value='1'>Storwork</option>
            <option value='2'>Sekwencja</option>
          </select>
        </div>
        <div>
          <input type='text' name={'email'} placeholder={'E-mail...'} value={this.state.email} onChange={this.onChange} />
        </div>
        <div>
          <button onClick={(e) => {
            invite(this.state.email, this.state.organization)
          }}>Invite
          </button>
        </div>
      </div>
    </div>
  }
}
export default OrganizationInvite
