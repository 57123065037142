import React from 'react'
import { Route } from 'react-router-dom'

import Single from './Single'
import List from 'components/List'
import Render from './Render'

export default () => {
  return <React.Fragment>
    <Route path='/contents/:type/:id' exact component={Single} />
    <Route path='/render/:type/:id' exact component={Render} />
    <Route path='/contents/:type' exact component={List} />
    <Route path='/contents' exact component={List} />
  </React.Fragment>
}
