import React from 'react'
import _ from 'lodash'
import Select from 'react-select'
import {observer} from 'mobx-react'
import {Input, Select as SemanticSelect, Container} from 'semantic-ui-react'
import {CloseButton} from 'components/Generics'
// import chroma from 'chroma-js'

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',
  background: color,
  color: 'white'
})

const colorStyles = {
  control: styles => ({
    height: 55
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: data.background,
      color: 'white',
      cursor: isDisabled ? 'not-allowed' : 'default'
    }
  },
  input: styles => ({}),
  dropdownIndicator: styles => ({display: 'none'}),
  indicatorsContainer: styles => ({display: 'none'}),
  indicatorSeparator: styles => ({display: 'none'}),
  menu: styles => ({
    ...styles,
    marginTop: 0,
    paddingTop: 0
  }),
  menuList: styles => ({}),
  // placeholder: styles => ({}),
  singleValue: (styles, { data }) => ({ ...dot(data.background) }),
  valueContainer: styles => ({})
}

const randomString = () => Math.random().toString(36).substring(7)
export default observer(class Monday extends React.Component {
  state = {
    marked: false
  }
  newColumn = () => {
    this.modifyObject(state => {
      const newColumn = randomString()
      const stateCopy = _.cloneDeep(state)
      if (!stateCopy.columns) stateCopy.columns = {}
      if (!stateCopy.columnsOrder) stateCopy.columnsOrder = []
      stateCopy.columns[newColumn] = {
        name: '',
        default: '',
        options: {},
        optionsOrder: [],
        type: 'string',
        width: 200
      }
      stateCopy.columnsOrder.push(newColumn)
      stateCopy.marked = newColumn
      return stateCopy
    })
  }
  newOption = column => () => {
    this.modifyObject(state => {
      const stateCopy = _.cloneDeep(state)
      const newOption = randomString()
      stateCopy.columns[column].options[newOption] = {
        name: '',
        background: 'transparent'
      }
      stateCopy.columns[column].optionsOrder.push(newOption)
      return stateCopy
    })
  }
  changeColumn = (column, field) => (e, data) => {
    const value = e.target ? e.target.value : e
    const trueValue = value || data.value
    this.modifyObject(state => {
      state.columns[column][field] = trueValue
      return state
    })
  }
  newTask = () => {
    this.modifyTasks(state => {
      const stateCopy = _.cloneDeep(state)
      stateCopy.push({
        type: 'monday',
        content: JSON.stringify({
          monday: this.props.id
        })
      })
      return stateCopy
    })
  }
  changeTask = (idx, column) => e => {
    const value = e.target ? e.target.value : e
    this.modifyTasks(state => {
      console.log(idx);
      const content = JSON.parse(state[idx].content)
      content[column] = value
      state[idx].content = JSON.stringify(content)
      return state
    })
  }
  mark = toMark => () => this.setState({marked: toMark})
  changeOption = (column, option, field) => e => {
    const value = e.target ? e.target.value : e
    this.modifyObject(state => {
      state.columns[column].options[option][field] = value
      return state
    })
  }
  modifyTasks = func => {
    const {onChangeTasks, tasks} = this.props
    onChangeTasks(func(tasks))
  }
  modifyObject = func => {
    const {onChangeObject, object} = this.props
    onChangeObject(func(object))
  }
  render () {
    const {marked} = this.state
    const {columns = {}, columnsOrder = []} = this.props.object
    const {tasks} = this.props
    const columnsSumWidth = Object.keys(columns).reduce(
      (agg, column) => agg + columns[column].width, 0
    )
    return <div style={{width: columnsSumWidth + 150, paddingBottom: 300}}>
      <div style={{borderBottom: '1px solid rgba(0,0,0,0.2)', display: 'inline-block'}}>
        {columnsOrder.map(column => {
          const {name, type, width, optionsOrder, options, default: defaultValue} = columns[column]
          return <div style={{width, height: '50px', lineHeight: '50px', float: 'left', position: 'relative', padding: '0 10px'}}>
            {name}
            <span onClick={this.mark(column)} style={{float: 'right', cursor: 'pointer'}}>
              <i className='fal fa-cog' />
            </span>
            {marked === column && <div style={{position: 'fixed', top: 0, left: 0, width: '100%', bottom: 0, overflow: 'auto', background: 'white', zIndex: 100, padding: '50px 10px'}}><Container>
              <CloseButton click={this.mark(false)} />
              <Input label='Nazwa' fluid value={name} onChange={this.changeColumn(column, 'name')} />
              <SemanticSelect label='Typ' value={type} onChange={this.changeColumn(column, 'type')} options={[
                {value: 'string', text: 'Napis'},
                {value: 'select', text: 'Select'},
                {value: 'number', text: 'Liczba'}
              ]} />
              <Input fluid label='Domyślna' value={defaultValue} onChange={this.changeColumn(column, 'default')} />
              {type === 'select' && <div>
                {optionsOrder.map(option => <div>
                  <Input placeholder='Wartość' fluid value={options[option].name} onChange={this.changeOption(column, option, 'name')} />
                  <Input placeholder='Tło' fluid value={options[option].background} onChange={this.changeOption(column, option, 'background')} />
                </div>)}
                <span onClick={this.newOption(column)}>Nowa opcja</span>
              </div>}
            </Container></div>}
          </div>
        })}
        <div style={{cursor: 'pointer', float: 'left', height: 50, width: 150, display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '50px', background: 'rgba(0,0,0,0.05)'}} onClick={this.newColumn}>
          <i className='fal fa-plus' />&nbsp;Nowa kolumna
        </div>
      </div>
      {tasks.map((task, idx) => <div style={{clear: 'both', borderBottom: '1px solid rgba(0,0,0,0.2)', display: 'inline-block'}}>
        {columnsOrder.map(column => {
          const {name, type, width, options, optionsOrder} = columns[column]
          const enrichedOptions = optionsOrder.map(option => ({
            label: options[option].name,
            value: options[option].name,
            background: options[option].background
          }))
          return <div style={{width, height: '50px', lineHeight: '50px', float: 'left'}}>
            {(type === 'string' || type === 'number') && <input
              style={{
                height: 50,
                width: '100%',
                background: 'transparent',
                lineHeight: '50px',
                border: 0,
                color: 'black'
              }}
              placeholder={name}
              value={JSON.parse(task.content)[column]} onChange={this.changeTask(idx, column)} />}
            {type === 'select' && <Select
              isSearchable={false}
              defaultValue={enrichedOptions[0]}
              options={enrichedOptions}
              styles={colorStyles}
              onChange={this.changeTask(idx, column)}
              value={JSON.parse(task.content)[column]}
            />}
          </div>
        })}
      </div>)}
      <div style={{clear: 'both'}} />
      {columnsOrder.length > 0 && <div style={{cursor: 'pointer', float: 'left', height: 50, width: 150, display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '50px', background: 'rgba(0,0,0,0.05)'}} onClick={this.newTask}>
        <i className='fal fa-plus' />&nbsp;
        Nowe zadanie
      </div>}
    </div>
  }
})
