import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import {observer} from 'mobx-react'
import _ from 'lodash'
import Card from './Card'

const InnerQuoteList = observer(class _InnerQuoteList extends React.Component {
  shouldComponentUpdate (nextProps) {
    if (nextProps.cards !== this.props.cards) {
      return true
    }

    return false
  }

  render () {
    const {cards, changeCards} = this.props
    return <React.Fragment>
      <hr />
      {cards.map((card, index) => (
        <Draggable key={card.id} draggableId={card.id} index={index}>
          {(
            dragProvided,
            dragSnapshot
          ) => (
            <Card
              key={index}
              index={index}
              changeCard={card => {
                cards[index] = card
                changeCards(cards)
              }}
              onDelete={() => {
                cards.splice(index, 1)
                changeCards(cards)
              }}
              card={card}
              isDragging={dragSnapshot.isDragging}
              provided={dragProvided}
            />
          )}
        </Draggable>
      ))}
    </React.Fragment>
  }
})

const InnerList = observer(class InnerList extends React.Component {
  render () {
    const { cards, dropProvided, changeCards } = this.props
    const title = this.props.title ? this.props.title : null

    return (
      <div>
        {title}
        <div ref={dropProvided.innerRef}>
          <InnerQuoteList cards={cards} changeCards={changeCards} />
          {dropProvided.placeholder}
        </div>
      </div>
    )
  }
})

export default observer(class QuoteList extends React.Component {
  static defaultProps = {
    listId: 'LIST'
  };
  render () {
    const {
      ignoreContainerClipping,
      internalScroll,
      isDropDisabled,
      listId,
      listType,
      cards,
      changeCards
    } = this.props

    return (
      <Droppable
        droppableId={listId}
        type={listType}
        ignoreContainerClipping={ignoreContainerClipping}
        isDropDisabled={isDropDisabled}
      >
        {(
          dropProvided,
          dropSnapshot
        ) => (
          <div
            isDraggingOver={dropSnapshot.isDraggingOver}
            isDropDisabled={isDropDisabled}
            {...dropProvided.droppableProps}
          >
            {internalScroll ? (
              <div>
                <InnerList
                  cards={cards}
                  dropProvided={dropProvided}
                  changeCards={changeCards}
                />
              </div>
            ) : (
              <InnerList
                cards={cards}
                dropProvided={dropProvided}
                changeCards={changeCards}
              />
            )}
          </div>
        )}
      </Droppable>
    )
  }
})
