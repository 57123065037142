import { sendEmail } from './delivery'

// Takes email and login from component and returns status
// Inputs: email, password (from state)
// Returns: status (loggedIn)
// Side effect: jwt-client keeps token if status == loggedIn
export const login = (email, password, that) => {
  if (email && password) {
    fetch((process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011') + '/graphiql', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query: `
            query Login($email: String!, $password: String!) {
              loginUser(email: $email, password: $password)
            }
          `,
        variables: {
          email: email,
          password: password
        }
      })
    })
      .then(response => {
        console.log(response)
        return response.json()
          .then(data => ({
            ...data,
            status: response.status
          }))
      })
      .then(data => {
        console.log(data)
        if (data.data.loginUser === 'Invalid credentials') {
          that.setState({
            status: false
          })
          alert('Błędne dane logowania')
        } else if (data.data.loginUser === 'User not exists!') {
          that.setState({
            status: false
          })
          alert('Podany użytkownik nie istnieje')
        } else if (data.data.loginUser === 'Access denied!') {
          that.setState({
            status: false
          })
          alert('Brak uprawnień!')
        } else {
          const token = data.data.loginUser
          // JWT.keep(token);
          window.localStorage.setItem('token', token)

          that.setState({
            status: true
          })
        }
      }).catch(error => {
        console.log(error)
      })
  }
}

export const register = (that, invitationOrgId = '') => {
  const data = that.state
  if (data.email && data.password) {
    fetch((process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011') + '/graphiql', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query: `
            query Register($name: String!, $invitation_org_id: Int, $email: String!, $password: String!) {
              registerUser(
                name: $name,
                invitation_org_id: $invitation_org_id
                email: $email,
                password: $password
              )
            }
          `,
        variables: {
          name: data.first_name + " " + data.last_name,
          // company: data.company,
          invitation_org_id: parseInt(invitationOrgId),
          email: data.email,
          password: data.password,
          // confirm_password: data.confirm_password,
          // content: data.content
        }
      })
    })
      .then(response => {
        return response.json()
          .then(data => ({
            ...data,
            status: response.status
          }))
      })
      .then(data => {
        console.log(data)
        if (data.data.registerUser === 'error') {
          that.setState({
            status: false
          })
          alert('Registration failed')
        } else {
          const token = data.data.registerUser
          // JWT.keep(token);
          window.localStorage.setItem('token', token)
          that.setState({
            status: true
          })
        }
      })
  }
}

// export const getFlarumToken = (data) => {
//   const testData = {
//     'identification': 'KrzysztofSikorski',
//     'password': 'HB2Kb3BZjrNfF3',
//     'remember': true
//   }
// }
//
// export const createFlarumAccount = (data) => {
//   const testData = {
//     'data': {
//       'attributes': {
//         'username': 'AdamMazurczak',
//         'email': 'a.mazurczak@gmail.com',
//         'password': 'test123!@#'
//       }
//     }
//   }
// }

export const invite = (email, orgId) => {
  if (email && orgId) {
    fetch((process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011') + '/graphiql', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query: `
            query Invite($email: String!, $org_id: Int!){
              inviteUser(email:$email,org_id:$org_id) {
                email
                token
              }
            }
          `,
        variables: {
          email: email,
          org_id: parseInt(orgId)
        }
      })
    })
      .then(response => {
        return response.json()
          .then(data => ({
            ...data,
            status: response.status
          }))
      })
      .then(data => {
        // TODO SEND EMAIL - IT WORKS?
        if (data.status === 200) {
          const token = data.data.inviteUser.token
          const invitationData = {
            from: 'kontakt@storwork.pl',
            to: email,
            subject: 'Zaproszenie do organizacji',
            message: 'Zaproszenie do organizacji. Tu link: http://localhost:3000/invitation/' + token + '/accept'
          }
          sendEmail(invitationData.from, invitationData.to, invitationData.subject, invitationData.message)
        }
        // return data;
        // if(data.data.loginUser === 'Invalid credentials'){
        //   that.setState({
        //     status:false,
        //   });
        //   alert('Błędne dane logowania');
        // } else {
        //   const token = data.data.loginUser;
        //   // JWT.keep(token);
        //   window.localStorage.setItem('token', token)
        //
        //   that.setState({
        //     status: true,
        //   })
        // }
      })
  }
}

export const acceptInvitation = (token, status) => {
  if (token && status) {
    fetch((process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011') + '/graphiql', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query: `
            query Accept($token: String!, $response: String!){
              invitationResponse(token:$token,response:$response) {
                email
                token
                org_id
                content
              }
            }
          `,
        variables: {
          token: token,
          response: status
        }
      })
    })
      .then(response => {
        return response.json()
          .then(data => ({
            ...data,
            status: response.status
          }))
      })
      .then(data => {
        // TODO
        console.log('Invitation ->>>>>', data)
        if (data.data.invitationResponse.token === token) {
          window.localStorage.setItem('invitation', JSON.stringify({
            email: data.data.invitationResponse.email,
            token: data.data.invitationResponse.token,
            org_id: data.data.invitationResponse.org_id,
            content: data.data.invitationResponse.content
          }))
          window.location.href = '/register'
        } else {
          alert('Ivalid invitation!')
        }
      })
  }
}
