export const registerVisit = entity => {
  const lastVisits = window.localStorage.getItem('lastVisits') || '[]'
  const lastVisitsObject = JSON.parse(lastVisits)
  if (lastVisitsObject.length >= 3) lastVisitsObject.splice(0, 1)
  lastVisitsObject.push(entity)
  window.localStorage.setItem('lastVisits', JSON.stringify(lastVisitsObject))
}

export const getLastVisits = () => {
  const lastVisits = window.localStorage.getItem('lastVisits') || '[]'
  return JSON.parse(lastVisits)
}
