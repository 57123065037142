import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Input, Button } from 'semantic-ui-react'

import Grid from 'components/Grid'

import { login } from 'services/auth'

class Login extends Component {
  state = {
    email: '',
    password: '',
    status: false,
  }

  onChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    this.setState(state => {
      state[name] = value
      return state
    })
  }

  render () {
    if (this.state.status) {
      window.location.href = '/'
    }

    return <Grid>
      <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '50px', width: '75%'}}>
        <h1>Logowanie</h1>
        <div className={'input-padded'}>
          <Input
            fluid
            type='text'
            name={'email'}
            placeholder={'E-mail...'}
            value={this.state.email}
            onChange={this.onChange}
          />
        </div>
        <div className={'input-padded'}>
          <Input
            fluid
            type='password'
            name={'password'}
            placeholder={'Hasło...'}
            value={this.state.password}
            onChange={this.onChange}
          />
        </div>
        <div className={'input-padded'}>
          <div style={{float: 'left'}}>
            <Button
              primary
              onClick={(e) => login(this.state.email, this.state.password, this)}>
              Zaloguj
            </Button>
          </div>
          <div style={{float: 'right'}}>
            <Link to={'/register'}>
              <Button>Zarejestruj się!</Button>
            </Link>
          </div>
        </div>
      </div>
    </Grid>
  }
}

export default Login
