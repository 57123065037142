import React from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { observer } from 'mobx-react'

import { indexTypeToRoute } from 'services/url'

const setJson = (json, path, value) => {
  const parsedJson = JSON.parse(json) || {}
  _.set(parsedJson, path, value)
  return JSON.stringify(parsedJson)
}

export const stateSetter = that => target => value => {
  const realValue = value.target ? value.target.value : value
  that.setState(state => {
    _.set(state, target, realValue)
    return state
  })
}

export const StateInput = that => observer(props => {
  const { path, json, callback, options } = props
  const pathValue = _.get(that, path)
  const jsonValue = json ? _.get(JSON.parse(pathValue), json) : false
  const onChange = e => {
    const value = e.target ? e.target.value : e
    const newValue = json ? setJson(pathValue, json, value) : value
    _.set(that, path, newValue)
    if (callback && options) callback()
  }
  const value = json ? jsonValue : pathValue
  const insideProps = { onChange, value }
  if (props.children) return React.cloneElement(React.Children.only(props.children), insideProps)
  return options ? <select {...insideProps}>
    {options.map(option => <option value={option.value}>{option.label || option.value}</option>)}
  </select> : <input onBlur={callback || (() => {})} {...insideProps} />
})

export const transformer = (data) => {
  data.name = data.first_name + ' ' + data.last_name

  const mapping = {
    name: 'name',
    email: 'contact_data.email',
    phone: 'contact_data.phone',
    fax: 'contact_data.fax',
    messenger: 'contact_data.messenger',
    address: 'contact_data.address',
    label: 'taxonomables.taxonomy_id'
  }

  let contactObject = {}
  let contactData = []
  Object.keys(mapping).map(key => {
    if (data[key]) _.set(contactObject, mapping[key], data[key])
    if (['email', 'phone', 'fax', 'messenger', 'address'].indexOf(key) !== -1 && data[key]) {
      contactData.push({ type: key, value: data[key] })
      contactObject['contact_data'] = JSON.stringify(contactData)
    }
  })

  return contactObject
}

export const getLink = (list, item) => {
  if (['contact'].indexOf(item.indexType) !== -1) {
    return <Link to={'/' + indexTypeToRoute(item.indexType) + '/' + item.id}>
      {item.name || 'Przejdź do kontaktu'}
    </Link>
  }

  if (['work'].indexOf(item.indexType) !== -1) {
    return <Link to={'/' + indexTypeToRoute(item.indexType) + '/' + item.id}>
      {item.name || 'Przejdź do pracy'}
    </Link>
  }

  if (['content'].indexOf(item.indexType) !== -1) {
    return <Link to={'/' + indexTypeToRoute(item.indexType) + '/' + item.type + '/' + item.id}>
      {item.name || 'Przejdź do treści'}
    </Link>
  }

  if (['set'].indexOf(item.indexType) !== -1) {
    return <Link to={'/products' + '/' + item.id}>
      {item.name || 'Przejdź do zbioru'}
    </Link>
  }

  if (['product'].indexOf(item.indexType) !== -1) {
    return <Link to={'/' + indexTypeToRoute(item.indexType) + '/' + (item.set ? item.set.id : 0) + '/' + item.id}>
      {item.name || 'Przejdź do produktu'}
    </Link>
  }
}

export const getOrganizationUsers = async (dispatch) => {

  const query = `
    query {
      organization {
        users {
          id
          name
        }
      }
    }`;

  const data = await (await fetch((process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011') + '/graphiql', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      variables: {}
    })
  })).json()

  dispatch(data.data.organization);
}
