import React from 'react'
import { observer } from 'mobx-react'

import Store from 'services/StoreStore'

export const singleStore = id => Store(`
  {
    search(type: "set", source: "database", id: ${id}) {
      ... on Set {
        id
        indexType
        type
        name
        content
        products {
          id
          name
        }
      }
    }
  }`)

const Single = observer(class _Single extends React.Component {
  render () {
    const { id } = this.props.match.params
    const store = singleStore(id)
    if (!store.loaded) return null

    console.log(store)

    return <div>
      {store.data.search[0].id + ' ' + store.data.search[0].name}
    </div>
  }
})

export default Single
