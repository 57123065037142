import React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Input, Container } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import SwipeableViews from 'react-swipeable-views'
import Avatar from 'react-avatar'

import Labels from 'components/Labels'
import Stream from 'components/Stream'
import Grid from 'components/Grid'
import ToggleObserve from 'components/ToggleObserve'
import { squareButton } from 'components/Generics'

import Store from 'services/StoreStore'

export const singleStore = (id, channel = 'default') => Store(storeQuery(id), channel)
const currentQuery = id => Store(storeQuery(id)).query === storeQuery(id)
export const storeQuery = id => `
  fragment contentFields on Content {
    id
    indexType
    name
    content
  }
  {
    product: search(type: "product", source: "database", filter: "id:${id}") {
      ... on Product {
        id
        indexType
        type
        owner_id
        name
        public
        content
        set_id
        owner {
          id
          name
        }
        information {
          id
          type
          name
          content(tesseract: true)
        }
        tasks {
          id
          type
          indexType
          name
          assigned
          content
          end_date
          information {
            id
            type
            name
            content
          }
        }
        labels {
          id
          type
          name
          content
        }
        taxonomies {
          id
          type
          name
          content
        }
        taxonomables {
          id
          indexType
          parent_id
          parent_type
          taxonomy_id
        }
        observables {
          id
          indexType
          parent_id
          parent_type
          user_id
        }
        set {
          id
          name
          type
          address
          capacity
        }
      }
    }
    getSettings {
      setting
      value
    }
    processes: search(type: "content", source: "database", filter: "type:bpmn") {
      ...contentFields
    }
    contracts: search(type: "content", source: "database", filter: "type:contract") {
      ...contentFields
    }
    offers: search(type: "content", source: "database", filter: "type:offer") {
      ...contentFields
    }
    grades: search(type: "content", source: "database", filter: "type:rating") {
      ...contentFields
    }
    taxonomies: search(type:"taxonomy", source:"database") {
      ... on Taxonomy {
        id
        name
        type
        content
      }
    }
    organization {
      users {
        id
        name
      }
    }
  }`

const Single = observer(class _Single extends React.Component {
  componentDidMount () {
    singleStore(this.props.id)
  }
  componentWillUnmount () {
    singleStore(this.props.id).unlock()
  }

  // onSubmit = (formData, otherData, store) => {
  //   const record = store.data.product.find(el => el.id === parseInt(this.props.match.params.id));
  //   const content = Object.assign(otherData, formData);
  //   record.content = JSON.stringify(content);
  //   store.sync();
  // }

  save = () => {
    singleStore(this.props.id).sync()
  }

  togglePublic = () => {
    const store = singleStore(this.props.id)
    const content = store.data.product[0]
    content.public = Number(!Boolean(content.public))
    store.sync()
  }

  render () {
    const { id } = this.props
    const store = singleStore(id)
    if (!store.loaded || !currentQuery(id)) return null

    const record = store.data.product.find(el => el.id === parseInt(id))
    const slideStyles = {
      padding: 10
    }

    const getContent = path => JSON.parse(record.content)[path]
    const setContent = path => e => {
      const value = e.target ? e.target.value : e
      const content = JSON.parse(record.content)
      content[path] = value
      record.content = JSON.stringify(content)
    }

    return <React.Fragment>
      <Grid>
        <div style={{ gridArea: 'main_data', lineHeight: '20px', padding: '0 5px', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} size='100px' area='main_data' type='row'>
          <div style={{ width: '100%', padding: 5, display: 'flex', alignItems: 'center' }}>
            <Link to={'/products' + (record.set_id ? '/' + record.set_id : '')}>
              <div style={squareButton()}>
                <i className='fal fa-arrow-left' />
              </div>
            </Link>
            <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', marginRight: '10px' }} />
            <div>
              <Avatar name={record.name} size={50} round />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <div>
                <input className='smoothInput' value={record.name} onChange={e => (record.name = e.target.value)} onBlur={this.save} />
              </div>
              <div>
                <span>{record.type}</span>
              </div>
            </div>
          </div>
          <div style={{ width: '100%', padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <div style={{ marginRight: '10px' }}>
              <Labels store={store} type={'product'} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
              <ToggleObserve type={'product'} store={store} />
              <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
              <i className='fal fa-share-alt' onClick={this.togglePublic} style={{ color: record.public && 'green' }} />
              <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
              <i className='fal fa-trash fa-fw' style={{ marginRight: '5px' }} onClick={() => {
                record._delete = true
                store.sync()
                window.location.assign('/products')
              }} />
            </div>
          </div>
        </div>

        <div style={{ gridArea: 'main', position: 'relative' }} area='main'>
          <SwipeableViews enableMouseEvents style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          }} resistance slideStyle={{
            overflow: 'auto'
          }}>
            <div style={slideStyles}>
              <Stream store={store} id={id} type='product' />
            </div>
            <div style={slideStyles}>
              <Container>
                {record.type === 'individual' && <React.Fragment>
                  <Dropdown search selection fluid value={record.owner_id} onChange={(e, value) => {
                    record.owner_id = value.value
                    this.save()
                  }} options={store.data.organization.users.filter(el => el.name).map(user => ({
                    key: user.id,
                    text: user.name,
                    value: user.id
                  }))} />
                </React.Fragment>}
                {record.type === 'repeatable' && <React.Fragment>
                  <Input fluid placeholder='Aktualna ilość...' value={getContent('current')} onChange={setContent('current')} onBlur={this.save} /><br />
                  <Input fluid placeholder='Pożądana ilość...' value={getContent('max')} onChange={setContent('max')} onBlur={this.save} />
                </React.Fragment>}
                {record.type === 'commercial' && <React.Fragment>
                  <Input fluid placeholder='Stan magazynowy...' value={getContent('quantity')} onChange={setContent('quantity')} onBlur={this.save} /><br />
                  <Input fluid placeholder='Cena...' value={getContent('price')} onChange={setContent('price')} onBlur={this.save} /><br />
                  <Input fluid placeholder='VAT (procent)...' value={getContent('tax')} onChange={setContent('tax')} onBlur={this.save} />
                </React.Fragment>}
              </Container>
            </div>
          </SwipeableViews>
        </div>
      </Grid>
    </React.Fragment>
    // <div>
    //   <ToggleObserve type={'product'} store={store} />
    //   <div>{record.name}</div>
    //   <div>
    //     <strong>Taxonomies</strong>
    //     {record.taxonomies.map(taxonomy => {
    //       const content = JSON.parse(taxonomy.content);
    //       const recordContent = JSON.parse(record.content)
    //       return <div key={taxonomy.id}>
    //         {taxonomy.name}
    //         Form:
    //         <Form
    //           schema={JSON.parse(content.json_schema)}
    //           onSubmit={({formData}) => {this.onSubmit({[content.name]: formData}, recordContent, store)}}
    //           formData={recordContent[content.name]}
    //         />
    //       </div>
    //     })}
    //   </div>
    //
    //   <Dropzone onDrop={(accepted) => onDrop(accepted, store, id, 'Products')}>
    //     {({getRootProps, getInputProps, isDragActive}) => {
    //       return (
    //         <div
    //           {...getRootProps()}
    //         >
    //           <input {...getInputProps()} />
    //           {
    //             isDragActive ?
    //               <p>Drop files here...</p> :
    //               <p>Try dropping some files here, or click to select files to upload.</p>
    //           }
    //         </div>
    //       )
    //     }}
    //   </Dropzone>
    // </div>
  }
})

export default props => <Single id={props.match.params.id} store={singleStore(props.match.params.id)} />
