import React from 'react'
import _ from 'lodash'
import {observer} from 'mobx-react'
import Select from 'react-select'
import moment from 'moment'
import {Link} from 'react-router-dom'
import { GithubPicker } from 'react-color'
import BigCalendar from 'react-big-calendar'

import {nicePrompt} from 'services/prompt'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import Store from 'services/StoreStore'
import { CloseButton } from '../components/Generics'

const getStore = () => Store(storeQuery)
const currentQuery = () => Store(storeQuery).query === storeQuery

export const storeQuery = `
  {
    tasks: search(type: "b2ccontent", source: "database", filter: "type:meeting") {
      ... on B2CContent {
        id
        indexType
        name
        start_date
        end_date
        content
        assigned
        user_id
      }
    }
    organization {
      users {
        id
        name
      }
    }
  }`

const DraggableCalendar = withDragAndDrop(BigCalendar)
const Event = ({event, title}) => {
  const {place} = JSON.parse(event.content)
  return <div>
    <div>{title}</div>
    {place && <div>w {place}</div>}
  </div>
}

const formatDate = date => moment(date).format('YYYY-MM-DD HH:mm')
const Container = observer(class _Container extends React.Component {
  state = {
    chosenEvent: null,
    colorsActive: false
  }
  constructor(props){
    super(props)
  }

  componentDidMount () {
    getStore()
  }
  componentWillUnmount () {
    getStore().unlock()
  }
  render () {
    const {store} = this.props
    const localizer = BigCalendar.momentLocalizer(moment)
    if (!store.loaded || !currentQuery()) return null
    const { data } = store
    const { tasks } = data
    const user = store.getUser()
    const userId = user.user.id
    const handleSelect = ({ start, end }) => {
      nicePrompt({
        title: 'Wpisz nazwę wydarzenia',
        confirm: 'Dodaj',
        cancel: 'Anuluj'
      }).then(title => {
        store.executeMutation('createb2ccontent', {
          name: title,
          content: JSON.stringify({
            color: 'rgb(0, 77, 207)'
          }),
          start_date: formatDate(start),
          end_date: formatDate(end),
          type: 'meeting'
        })
      })
    }
    const selectEvent = ev => {
      if (ev.user_id !== userId) return alert('Nie możesz zmieniać nie swoich zdarzeń')
      this.setState({
        chosenEvent: ev.id
      })
    }
    const handleDrop = ({ start, end, event }) => {
      if (event.user_id !== userId) return alert('Nie możesz zmieniać nie swoich zdarzeń')
      const changedEvent = store.data.tasks.find(el => el.id === event.id)
      changedEvent.start_date = formatDate(start)
      changedEvent.end_date = formatDate(end)
      store.sync()
    }
    const tasksMapped = tasks.map(el => ({
      ...el,
      start: new Date(moment(el.start_date)),
      end: new Date(moment(el.end_date)),
      title: el.name
    }))
    const {chosenEvent, colorsActive} = this.state

    const chosenTask = tasks.find(el => {
      return el.id === chosenEvent
    })

    const niceParse = content => content ? JSON.parse(content) : {}
    const chosenGet = path => _.get(niceParse(chosenTask.content), path)
    const chosenSet = path => (e, sync = false) => {
      const value = e.target ? e.target.value : e
      const content = niceParse(chosenTask.content)
      _.set(content, path, value)
      chosenTask.content = JSON.stringify(content)
      if (sync) store.sync()
    }
    const users = store.data.organization.users
    const userToOption = user => ({
      value: user.id,
      label: user.name
    })

    const deleteEvent = () => {
      chosenTask._delete = true
      this.setState({
        chosenEvent: null
      })
      store.sync()
    }

    return <div>
      <div style={{height: '560px'}}>
        <DraggableCalendar
          selectable
          longPressThreshold={90}
          localizer={localizer}
          events={tasksMapped}
          onSelectSlot={handleSelect}
          onEventDrop={handleDrop}
          onEventResize={handleDrop}
          onSelectEvent={selectEvent}
          defaultView='day'
          components={{
            event: Event
          }}
          eventPropGetter={ev => {
            const content = niceParse(ev.content)
            return {
              style: {
                background: content.color,
                opacity: ev.user_id === userId ? 1 : 0.5
              }
            }
          }}
        />
      </div>
      {chosenEvent && <div style={{
        position: 'fixed',
        top: 50,
        left: 0,
        bottom: 0,
        right: 0,
        background: 'white',
        zIndex: 10000,
        padding: 10,
        paddingTop: 20
      }}>
        <div title={'Usuń wydarzenie'} style={{
          position: 'absolute',
          top: 50,
          right: 30,
          cursor: 'pointer',
          border: '1px solid rgba(0,0,0,0.2)',
          width: '30px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '1px solid rgba(0,0,0,0.2)'
        }} onClick={deleteEvent} >
          <i className='fal fa-trash' />
        </div>
        <CloseButton click={() => this.setState({chosenEvent: null})}/>
        <i style={{float: 'left'}} className='fal fa-fw fa-pencil' />
        <span style={{fontWeight: 900, fontSize: 18}}>
          <input style={{width: 'calc(100% - 40px)', marginLeft: 10}} className='smoothInput' onBlur={store.sync} value={chosenTask.name} onChange={e => chosenTask.name = e.target.value} placeholder='Nazwa...' />
        </span>
        <div style={{clear: 'both', width: '100%', height: 10}} />
        <i style={{float: 'left'}} className='fal fa-fw fa-map-marker' /><input style={{width: 'calc(100% - 40px)', marginLeft: 10}} className='smoothInput' onBlur={store.sync} value={chosenGet('place')} onChange={chosenSet('place')} placeholder='Miejsce...' />
        <div style={{clear: 'both', width: '100%', height: 10}} />
        <i style={{float: 'left'}} className='fal fa-fw fa-align-justify' /><textarea style={{width: 'calc(100% - 40px)', marginLeft: 10}} rows='3' className='smoothInput' onBlur={store.sync} value={chosenGet('description')} onChange={chosenSet('description')} placeholder='Opis...' />
        <div style={{clear: 'both', width: '100%', height: 10}} />
        <div style={{position: 'relative'}}>
          <i style={{float: 'left'}} className='fal fa-fw fa-palette' />
          <div onClick={() => this.setState({
            colorsActive: !colorsActive
          })} style={{float: 'left', marginLeft: 13, background: chosenGet('color'), height: 30, width: 30}} />
          {colorsActive && <div style={{
            position: 'absolute',
            top: 34,
            left: 29,
            zIndex: 100
          }}>
            <GithubPicker value={chosenGet('color')} onChange={color => {
              chosenSet('color')(color.hex, true)
              this.setState({
                colorsActive: false
              })
            }} />
          </div>}
        </div>
        <div style={{clear: 'both', width: '100%', height: 10}} />
        {console.log(chosenTask)}
        <i style={{float: 'left', marginRight: 12}} className='fal fa-fw fa-users' /><Select
          options={users.map(userToOption).filter(el => el.value !== user.user.id)}
          isMulti
          value={chosenTask.assigned ? users.filter(
            user => chosenTask.assigned.split(',').map(el => parseInt(el.substr(1))).indexOf(user.id) > -1
          ).map(userToOption) : []}
          onChange={val => {
            chosenTask.assigned = val.map(el => ':' + el.value).join(',') || 0
            store.sync()
          }}
        />
      </div>}
    </div>
  }
})

export default () => <Container store={getStore()} />

// {store.data.tasks.map(task => )}

// <button onClick={() => getStore().executeMutation('createcontact', {
//   first_name: 'Jan',
//   last_name: 'Kowalski'
// })}>Dodaj</button>
