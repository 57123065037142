import React from 'react'
import 'rc-tooltip/assets/bootstrap_white.css'

export const Fab = ({ click, style = {}, children = '+', title}) => <div title={title} style={{
  position: 'fixed',
  bottom: 60,
  right: 10,
  borderRadius: '50%',
  width: 50,
  height: 50,
  color: 'white',
  background: '#2c3e50',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 30,
  cursor: 'pointer',
  ...style
}} onClick={click}>
  {children}
</div>

export const CloseButton = ({ click }) => <div style={{
  position: 'absolute',
  top: 10,
  right: 30,
  zIndex: 1000000,
  cursor: 'pointer',
  border: '1px solid rgba(0,0,0,0.2)',
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '24px',
  color: '1px solid rgba(0,0,0,0.2)'
}} onClick={click}>
  <i className='fal fa-times' />
</div>

export const FullScreen = ({ children, direction, active, style }) => {
  const position = {
    bottom: (direction === 'top' && !active) ? '100%' : 0,
    top: (direction === 'bottom' && !active) ? '100%' : 50,
    left: (direction === 'right' && !active) ? '100%' : 0,
    right: (direction === 'left' && !active) ? '100%' : 0
  }
  if (direction === 'top') delete position.top
  if (direction === 'bottom') delete position.bottom
  if (direction === 'left') delete position.left
  if (direction === 'right') delete position.right
  return <div style={{
    position: 'fixed',
    background: 'white',
    height: 'calc(100% - 50px)',
    width: '100%',
    zIndex: 1000,
    transition: '300ms ease-in-out',
    ...position,
    ...style
  }}>{children}</div>
}

export const CreateContact = (props) => {
  return <div>
    <Fab click={() => props.create()} />
    <Fab style={{ right: 70 }} click={() => {
      props.image()
    }}><i className='fal fa-camera' />
    </Fab>
  </div>
}

export const CreateContent = (props) => {
  return <div>
    <Fab click={() => props.create()} />
  </div>
}

export const CreateWork = (props) => {
  return <div>
    <Fab click={() => props.create()} />
  </div>
}

export const CreateSet = (props) => {
  return <div>
    <Fab click={() => props.create()} />
  </div>
}

export const CreateProduct = (props) => {
  return <div>
    <Fab click={() => props.individual()} title={'Add Individual Product'}>
      <i className='fal fa-user' />
    </Fab>
    <Fab style={{ right: 70 }} click={() => props.repeatable()} title={'Add Repeatable Product'}>
      <i className='fal fa-repeat' />
    </Fab>
    <Fab style={{ right: 130 }} click={() => props.commercial()} title={'Add Commercial Product'}>
      <i className='fal fa-usd' />
    </Fab>
  </div>
}

export const squareButton = style => ({
  height: 40,
  width: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  ...style
})
