import React from 'react'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { singleStore } from '../Single'

const Content = observer(class _Content extends React.Component {
  state = {
    template: false
  }
  loadTemplate = () => {
    if (!this.props.store.loaded) return null
    const templateId = parseInt(this.props.id)
    const record = this.props.store.data.content.find(el => templateId === el.id)
    const content = JSON.parse(record.content)
    const grapes = content.grapes || '{}'
    const html = grapes.html
    const css = grapes.css

    localStorage.removeItem('gjs-html')
    localStorage.removeItem('gjs-components')
    localStorage.removeItem('gjs-css')
    localStorage.removeItem('gjs-styles')
    localStorage.removeItem('gjs-assets')

    const typeToPreset = {
      webpage: 'webpage',
      email_template: 'newsletter'
    }

    window.grapesjs.init({
      container: '#gjs',
      plugins: ['gjs-preset-' + typeToPreset[this.props.type], 'grapesjs-custom-code'],
      pluginsOpts: {
        'gjs-preset-webpage': {
          // options
        }
      },
      components: html,
      style: css
    })
    this.input.value = content.path || ''
  }
  componentWillReact () {
    this.loadTemplate()
  }
  componentDidMount () {
    this.loadTemplate()
    this.props.beforeSave(this.save)
  }
  save = () => {
    const html = localStorage.getItem('gjs-html')
    const css = localStorage.getItem('gjs-css')

    const templateId = parseInt(this.props.id)
    const recordIndex = this.props.store.data.content.findIndex(el => templateId === el.id)

    // const path = this.
    const content = JSON.parse(this.props.store.data.content[recordIndex].content)

    if (html) _.set(content, 'grapes.html', html)
    if (css) _.set(content, 'grapes.css', css)
    _.set(content, 'path', this.input.value)

    this.props.store.data.content[recordIndex].content = JSON.stringify(content)
    // this.props.store.sync()
  }
  render () {
    return <div>
      {this.props.store.loaded && <span />}
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} id='gjs' />
      </div>

      <input ref={input => (this.input = input)} style={{ position: 'absolute', top: '10px', left: '320px', zIndex: 100 }} />
    </div>
  }
})

// <span style={{position: 'absolute', top: '63px', left: '220px', zIndex: 100, color: 'white'}} onClick={this.save}>Save</span>
// <span style={{position: 'absolute', top: '63px', left: '150px', zIndex: 100, color: 'white'}}>
//   <Link to={'/render/template/' + this.props.id} style={{color: 'white'}}>Preview</Link>
// </span>

export default class Templates extends React.Component {
  render () {
    const { beforeSave } = this.props
    const { id, type } = this.props.match.params
    return <div>
      <Content beforeSave={beforeSave} store={singleStore(id)} id={id} type={type} />
    </div>
  }
}
