import React from 'react'
import { observer } from 'mobx-react'
import { GithubPicker } from 'react-color'
import _ from 'lodash'
import { Button, Card } from 'semantic-ui-react'
import moment from 'moment'
import SimpleMDEEditor from 'yt-simplemde-editor'

import Store from 'services/StoreStore'
import { fileUpload, fileDownload, filePath } from 'services/files'

export const getStore = () => Store(`
  {
    notes: search(type: "b2ccontent", source: "database", filter: "type:note") {
      ... on B2CContent {
        id
        indexType
        content
        type
      }
    }
  }`)

const Container = observer(class _Container extends React.Component {
  state = {
    content: '',
    colorActive: null,
    editedNote: null,
    rec: null,
    audioChunks: [],
    status: 'stopped'
  }
  constructor (props) {
    super(props)
    this.fileInput = React.createRef()
    this.imageInput = React.createRef()
  }
  componentWillUnmount () {
    getStore().unlock()
  }

  componentDidMount () {
    getStore()
    setTimeout(() => {
      if (!this.props.location.state) return
      if (this.props.location.state.action === 'addRecord') this.recordMessage()
      if (this.props.location.state.action === 'addImage') this.imageInput.current.click()
    }, 500)
  }
  addNote = () => {
    this.props.store.executeMutation('createb2ccontent', {
      type: 'note',
      content: JSON.stringify({
        content: this.state.content,
        type: 'text',
        color: '',
        name: ''
      })
    })
    this.setState({
      content: ''
    })
  }
  changeColor = (color) => {
    const note = this.props.store.data.notes[this.state.colorActive]
    const noteContent = JSON.parse(note.content)
    noteContent.color = color.hex
    note.content = JSON.stringify(noteContent)
    this.props.store.sync()
    this.setState({
      colorActive: false
    })
  }
  setContent = (path, obj) => value => {
    const noteContent = JSON.parse(obj.content)
    _.set(noteContent, path, value)
    obj.content = JSON.stringify(noteContent)
  }
  onDrop = (image = false, magicFile = false) => {
    const input = image ? this.imageInput : this.fileInput
    const file = magicFile || input.current.files[0]
    fileUpload(file, '/Notes/1/' + file.name).then(() => {
      this.props.store.executeMutation('createb2ccontent', {
        type: 'note',
        content: JSON.stringify({
          content: {
            name: file.name,
            mimetype: file.type,
            size: file.size
          },
          type: 'file',
          color: '',
          name: ''
        })
      })
    })
  }
  downloadFile = file => () => {
    fileDownload('/Notes/1/' + file, file)
  }
  showFile = content => {
    const regexImg = /jpg|png|jpeg|gif/g
    const regexWebm = /webm/g
    if (regexImg.test(content.name)) return <img style={{ width: '100%' }} src={filePath('/Notes/1/' + content.name)} />
    if (regexWebm.test(content.name)) {
      return <audio controls>
        <source src={filePath('/Notes/1/' + content.name)} type='audio/webm' />
      </audio>
    }
    return content.name
  }
  recordMessage = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((mediaStream) => {
        const rec = new MediaRecorder(mediaStream, {
          'mimeType': 'audio/webm'
        })
        this.setState({
          rec,
          audioChunks: [],
          status: 'recording'
        })
        rec.ondataavailable = e => {
          this.setState(state => {
            state.audioChunks.push(e.data)
            return state
          }, () => {
            if (rec.state === 'inactive') {
              let blob = new Blob(this.state.audioChunks, { type: 'audio/webm' })
              blob.lastModifiedDate = new Date()
              blob.name = moment().unix() + '.webm'
              this.onDrop(null, blob)
            }
          })
        }
        rec.start()
      })
  }
  stopRecording = () => {
    this.state.rec.stop()
    this.setState({
      status: 'stopped'
    })
  }
  render () {
    const { content, colorActive, status } = this.state
    const { store } = this.props
    if (!store.loaded) return null

    const { notes } = store.data

    return <div style={{ padding: 10 }}>
      <Button onClick={() => this.fileInput.current.click()}>Dodaj plik</Button>
      <Button onClick={() => this.imageInput.current.click()} style={{ marginLeft: 10 }}>Dodaj obrazek</Button>
      <Button onClick={status === 'recording' ? this.stopRecording : this.recordMessage} style={{ marginLeft: 10 }}>
        {status === 'recording' ? 'Stop' : 'Nagraj'}
      </Button>
      <div id='notes-simplemde' style={{ margin: '10px 0' }}>
        <SimpleMDEEditor
          value={content}
          onChange={value => {
            this.setState({ content: value })
          }}
          options={{
            status: false,
            toolbar: false,
            placeholder: 'Dodaj notatkę tekstową...',
            spellCheck: false
          }}
          getMdeInstance={simplemde => {
            this.simplemde = simplemde
            this.simplemde.codemirror.on('blur', () => {
              if (this.state.content !== '') this.addNote()
            })
          }}
        />
      </div>
      <div>
        {notes && notes.map((note, ind) => {
          const { content, color, type, name } = JSON.parse(note.content)
          return <Card key={note.id} style={{
            borderRadius: '5px',
            boxShadow: '0px 0px 3px rgba(0,0,0,0.3)',
            padding: 10,
            background: color,
            marginBottom: 10
          }} fluid>
            {(type === 'text' || !type) && <Card.Content>
              <input type='text' placeholder='nazwa notatki...' value={name || ''} style={{ background: 'transparent', border: 'none', width: '100%' }} onBlur={() => store.sync()} onChange={e => {
                this.setContent('name', store.data.notes[ind])(e.target.value)
              }} />
              <div className='smallMDE'><SimpleMDEEditor
                value={content}
                onChange={value => {
                  this.setContent('content', store.data.notes[ind])(value)
                }}
                options={{
                  status: false,
                  toolbar: false,
                  placeholder: 'Dodaj notatkę tekstową...',
                  spellCheck: false
                }}
                getMdeInstance={simplemde => {
                  this.simplemde = simplemde
                  this.simplemde.codemirror.on('blur', () => {
                    store.sync()
                  })
                }} /></div>
            </Card.Content>}

            {type === 'file' && <Card.Content>
              <input type='text' placeholder='nazwa pliku...' value={name || ''} style={{ background: 'transparent', border: 'none', width: '100%' }} onBlur={() => store.sync()} onChange={e => {
                this.setContent('name', store.data.notes[ind])(e.target.value)
              }} />
              {this.showFile(content)}
            </Card.Content>}

            <Card.Content extra style={{ marginTop: 10, display: 'flex' }}>
              {type === 'file' && <div style={{ marginRight: 10, cursor: 'pointer' }} onClick={this.downloadFile(content.name)}>
                <i className='fal fa-download' /> Pobierz
              </div>}
              <div onClick={() => {
                note._delete = true
                store.sync()
              }} style={{ marginRight: 10, cursor: 'pointer' }}>
                <i className='fal fa-archive' /> Archiwizuj
              </div>
              <div style={{ position: 'relative', cursor: 'pointer' }}>
                <span onClick={() => this.setState({
                  colorActive: ind
                })}><i className='fal fa-palette' /> Zmień kolor</span>
                {colorActive === ind && <div style={{ position: 'absolute', top: 25, left: -10, zIndex: 10000 }}>
                  <GithubPicker onChange={this.changeColor} />
                </div>}
              </div>
            </Card.Content>
          </Card>
        })}
      </div>

      <div style={{ display: 'none' }}>
        <input type='file' ref={this.fileInput} onChange={() => this.onDrop(false)} />
        <input type='file' accept='image/*' capture='camera' ref={this.imageInput} onChange={() => this.onDrop(true)} />
        <button onClick={this.recordMessage}>Record</button>
        <button onClick={this.stopRecording}>stop</button>
      </div>

    </div>
  }
})

export default props => <Container {...props} store={getStore()} />
