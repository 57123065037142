// Switch two items
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const reorderCards = ({
  quoteMap,
  source,
  destination
}) => {
  const current = quoteMap[source.droppableId]
  const next = quoteMap[destination.droppableId]
  const target = current.cards[source.index]

  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(
      current.cards,
      source.index,
      destination.index
    )
    const result = {
      ...quoteMap,
      [source.droppableId]: {
        name: current.name,
        cards: reordered
      }
    }
    return result
  }

  current.cards.splice(source.index, 1)
  next.cards.splice(destination.index, 0, target)

  const result = {
    ...quoteMap,
    [source.droppableId]: current,
    [destination.droppableId]: next
  }

  return result
}
