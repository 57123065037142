import axios from 'axios'

const server = (process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011')
export const fileUpload = (file, path) => new Promise(resolve => {
  let fd = new FormData()
  fd.append('file', file)
  fd.append('path', path)

  const token = window.localStorage.getItem('token')
  const config = { headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': 'Bearer ' + token
  } }
  console.log(config)

  axios.post(`${server}/upload`, fd, config)
    .then((response) => {
      console.log('upload done');
      resolve(true)
      console.log(response)
    })
    .catch(error => {
      console.log(error)
    })
})

export const filePath = (path) => {
  return `${server}/download?path=${path}`
}

export const fileDownload = (path, name) => {
  const token = window.localStorage.getItem('token')
  const config = { headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': 'Bearer ' + token
  },
  responseType: 'blob' }
  axios.get(`${server}/download?path=${path}`, config)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    })
    .catch(error => {
      console.log(error)
    })
}

/**
 * onDrop
 *
 * @param acceptedFiles
 * @param store
 * @param id
 * @param type
 */
export const onDrop = (acceptedFiles, store, id, type) => {
  fileUpload(acceptedFiles[0], '/Attachments/' + type + '/' + id + '/' + acceptedFiles[0].name)
  store.executeMutation('createinformation', {
    type: 'file',
    name: acceptedFiles[0].name,
    content: JSON.stringify({
      name: acceptedFiles[0].name,
      mimetype: acceptedFiles[0].type,
      size: acceptedFiles[0].size
    }),
    parent_type: type.toLowerCase(),
    parent_id: id
  })
}
