import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

export const bugsnagClient = bugsnag('7b3770043500dd856079fddf24496861')
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

require('dotenv').config()

const ErrorComponent = () => <div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h1>Ups...</h1>
				<h2>Coś poszło nie tak. Błąd został już do nas zgłoszony. Odśwież stronę, a jeśli błąd się powtarza, napisz email na kontakt@storwork.pl</h2>
			</div>
			<a href=".">Odśwież</a>
      <a href="/">Strona główna</a>
		</div>
	</div>

ReactDOM.render(<ErrorBoundary FallbackComponent={ErrorComponent}>
    <App />
  </ErrorBoundary>
, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
