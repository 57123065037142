import React from 'react';
import _ from 'lodash'
import TextareaAutosize from 'react-autosize-textarea'
import {observer} from 'mobx-react'
import getStore from 'services/Store'

const Container = props => <div {...props} style={{
  borderRadius: '3px',
  backgroundColor: 'white',
  boxShadow: '2px 2px 1px rgba(0,0,0,0.2)',
  padding: '5px',
  minHeight: '40px',
  marginBottom: '5px',
  userSelect: 'none',
  transition: 'background-color 0.1s ease',
  color: 'black',
  margin: '5px 0',
  ...props.style
}} ref={props.innerRef}>
  {props.children}
</div>

export default observer(class QuoteItem extends React.PureComponent {
  state = {
    edited: false
  }
  edit = () => {
    this.setState({
      edited: !this.state.edited
    }, () => {
      if (this.area) this.area.focus()
    })
  }
  changeCard = content => {
    const {card, changeCard} = this.props
    card.name = content
    changeCard(card)
  }

  deleteCard = () => {
    this.props.onDelete()
  }

  render () {
    const { card, isDragging, provided } = this.props
    const md = require('markdown-it')()
      .use(require('markdown-it-checkbox'), {
        divWrap: true,
        divClass: 'cb',
        idPrefix: 'cbx_'
      })
    return this.state.edited ? <React.Fragment>
      <div onClick={this.edit} style={{position: 'fixed', background: 'rgba(0,0,0,0.5)', top: 0, left: 0, bottom: 0, right: 0}} />
      <Container style={{position: 'relative', zIndex: 100}}>
        <TextareaAutosize
          innerRef={area => this.area = area}
          value={card.name}
          onChange={val => this.changeCard(val.target.value)}
          style={{color: 'black', width: '100%', background: 'transparent', border: 0, resize: 'none'}} />
        <i style={{cursor: 'pointer'}} onClick={this.deleteCard} className="fal fa-trash"></i>
      </Container>
    </React.Fragment> : <Container
      style={{
        width: '100%',
        minHeight: '50px'
      }}
      onClick={this.edit}
      isDragging={isDragging}
      innerRef={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div dangerouslySetInnerHTML={{__html: md.render(card.name)}} />
    </Container>
  }
})
