import React from 'react'
import { observer } from 'mobx-react/index'
import SimpleMDEEditor from 'yt-simplemde-editor'
import { compiler } from 'markdown-to-jsx'
import ReactDOMServer from 'react-dom/server'

import * as P from './Toolbar'
import { singleStore } from '../Single'

const decode = require('unescape')

const Content = observer(class _Content extends React.Component {
  state = {
    article: ''
  };

  componentDidMount () {
    const record = this.props.store.data.content.find(el => el.id === parseInt(this.props.id))
    const content = JSON.parse(record.content)
    this.setState({
      article: content.articleContent ? content.articleContent : ''
    })
    this.props.beforeSave(this.save)
  }

  renderMarkdown = (text) => {
    const setup = compiler(text, {
      overrides: {}
    })
    const html = decode(ReactDOMServer.renderToStaticMarkup(setup))
    return html
  }

  onChange = (value) => {
    this.setState({
      article: value
    })
  }

  save = () => {
    const record = this.props.store.data.content.find(el => el.id === parseInt(this.props.id))
    record.content = JSON.stringify({ articleContent: this.state.article })
    // this.props.store.sync()
  }

  render () {
    return <div>
      <SimpleMDEEditor
        onChange={this.onChange}
        value={this.state.article}
        options={{
          previewRender: this.renderMarkdown,
          toolbar: [
            'bold',
            'italic',
            'heading',
            '|',
            'quote',
            'code',
            'table',
            'horizontal-rule',
            'unordered-list',
            'ordered-list',
            '|',
            'link',
            'image',
            '|',
            'preview',
            'side-by-side',
            'fullscreen',
            '|',
            P.CustomField(this)
          ]
        }}
      />
    </div>
  }
})

class Markdown extends React.Component {
  render () {
    const { beforeSave } = this.props
    const { id, type } = this.props.match.params
    return <div>
      <Content store={singleStore(id)} beforeSave={beforeSave} id={id} type={type} />
    </div>
  }
}

export default Markdown
