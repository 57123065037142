import { observer } from 'mobx-react/index'
import React from 'react'
import { Input, Button, Form, TextArea } from 'semantic-ui-react'
import { sendSms, sendEmail } from 'services/delivery'
import Select from 'react-select'
import {singleStore} from '../contacts/Single'

const Send = observer(class _Send extends React.Component {
  state = {
    from: '',
    to: '',
    subject: '',
    message: '',
    status: false
  }

  send = (type) => {
    const contact = this.props.store.data.contact[0]
    const chance = require('chance').Chance()
    const token = chance.string({ pool: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789' })

    const regex = /href="([^"]+)"/g
    const attachToLinks = content => content.replace(regex, (match, link) => {
      return link.indexOf('?') > -1 ? `href="${link}&email=${this.props.to}"` : `href="${link}?email=${this.props.to}"`
    })

    // TODO: GET ORG FROM TOKEN

    this.props.store.feedback = id => {
      if (type === 'email') {
        const result = sendEmail(this.state.from, this.props.to, this.state.subject,
          attachToLinks(
            this.state.message
          ),
          data => {
            this.setState({
              status: data
            })
          }
        )
      }
    }

    if (type === 'phone') {
      const result = sendSms(this.props.to, this.state.message, data => {
        this.setState({
          status: data
        })
      })
    }

    this.props.store.executeMutation('createinformation', {
      type: 'message',
      content: JSON.stringify({
        type: type,
        from: this.state.from,
        to: this.props.to,
        subject: this.state.subject,
        message: this.state.message,
        token: token,
        status: ''
      }),
      parent_id: contact.id,
      parent_type: 'contacts'
    })


  }

  onChange = (e) => {
    const value = e.target.value
    const name = e.target.name

    this.setState(state => {
      state[name] = value
      return state
    })
  }

  render () {
    const { store, type } = this.props

    if (!store.loaded) return null

    const data = store.data.contact[0].contact_data ? JSON.parse(store.data.contact[0].contact_data) : [];

    const inputStyle = {
      margin: '12px 0'
    }

    const trans = {
      phone: 'SMS',
      email: 'e-mail'
    }

    const options = data.filter(el => el.type === type);
    const selectedFromSingle = {value: this.props.to};
    const defaultOption = selectedFromSingle.value ? selectedFromSingle : (options.find(el => el.status === 3) || options[0]);

    if(!options.length) return null;

    console.log(this.state.status)

    if(this.state.status[0] === 'OK') {
      this.props.closeDelay(5);
      return <div style={{position: 'absolute', top: '80px', width: '75%'}}>
        <h3>Wiadomość {trans[type]} została wysłana.</h3>
      </div>
    }

    return <div style={{position: 'absolute', top: '80px', width: '75%'}}>
      <div style={{fontSize: '16px', margin: '16px 0', lineHeight: '32px'}}>
        <div>Wyślij {trans[type]} do</div>
        <Select
          options={options.map(option => {
            return {value: option.value, label: option.value}
          })}
          value={{label: defaultOption.value}}
        />
      </div>
      {type === 'email' && <div style={inputStyle}><Input fluid onFocus name={'from'} value={this.state.from} type='text' id={'from'} placeholder={'Nadawca...'} onChange={this.onChange} /></div>}
      {type === 'email' && <div style={inputStyle}><Input fluid name={'subject'} value={this.state.subject} type='text' id={'subject'} placeholder={'Temat...'} onChange={this.onChange} /></div>}
      <div style={inputStyle}>
        <Form><TextArea name={'message'} value={this.state.message} type='text' id={'message'} placeholder={'Treść wiadomości...'} onChange={this.onChange} /></Form>
      </div>
      <Button fluid onClick={() => this.send(type)}>Wyślij</Button>
      {(this.state.status.length > 0 && this.state.status[0] !== 'OK') && <div>Wystąpił błąd podczas wysyłki: {this.state.status.join(":")}</div>}
    </div>
  }
})

export default Send;
