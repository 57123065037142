import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Input, Button } from 'semantic-ui-react'
import { register } from 'services/auth'
import Grid from 'components/Grid'

class Register extends Component {
  state = {
    email: '',
    first_name: '',
    last_name: '',
    company: '',
    invitation_org_id: '',
    password: '',
    confirm_password: '',
  }
  onChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    this.setState(state => {
      state[name] = value
      return state
    })
  }

  componentDidMount () {
    const invitation = window.localStorage.getItem('invitation') ? JSON.parse(
      window.localStorage.getItem('invitation')) : false
    if (invitation) {
      this.setState({
        email: invitation.email,
        invitation_org_id: invitation.org_id,
      })
    }
  }

  render () {
    if (this.state.status) {
      window.location.href = '/'
    }

    const invitation = window.localStorage.getItem('invitation') ? JSON.parse(
      window.localStorage.getItem('invitation')) : false

    return <Grid>
      <div style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '50px',
        width: '75%',
      }}>
        <h1>Rejestracja</h1>
        <div className={'input-padded'}>
          <Input
            fluid
            type='text'
            name={'email'}
            placeholder={'E-mail...'}
            value={invitation ? (invitation.email
              ? invitation.email
              : this.state.email) : this.state.email}
            onChange={this.onChange}
            readOnly={invitation.email && true}/>
        </div>
        <div className={'input-padded'}>
          <Input
            fluid
            type='text'
            name={'first_name'}
            placeholder={'Imię...'}
            value={this.state.first_name}
            onChange={this.onChange}/>
        </div>
        <div className={'input-padded'}>
          <Input
            fluid
            type='text'
            name={'last_name'}
            placeholder={'Nazwisko...'}
            value={this.state.last_name}
            onChange={this.onChange}/>
        </div>
        <div className={'input-padded'}>
          <Input
            fluid
            type='password'
            name={'password'}
            placeholder={'Hasło...'}
            value={this.state.password}
            onChange={this.onChange}/>
        </div>
        <div className={'input-padded'}>
          <Input
            fluid
            type='password'
            name={'confirm_password'}
            placeholder={'Powtórz hasło...'}
            value={this.state.confirm_password}
            onChange={this.onChange}/>
        </div>
        <div className={'input-padded'}>
          <div style={{float: 'left'}}>
            <Button
              primary
              onClick={(e) => register(this, (invitation ? invitation.org_id : null))}>
              Zarejestruj
            </Button>
          </div>
          <div style={{float: 'right'}}>
              <Link to={'/login'}>
                <Button>Zaloguj</Button>
              </Link>
        </div>
        </div>
      </div>
    </Grid>
  }
}

export default Register
