import React from 'react'
import { Route } from 'react-router-dom'

import Single from './Single'
import List from 'components/List'

export default () => {
  return <React.Fragment>
    <Route path='/set/:id' exact component={Single} />
    <Route path='/sets' exact component={List} />
  </React.Fragment>
}
