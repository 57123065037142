export const sendSms = (to, message, callback = () => {}) => {
  const server = process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011'
  if (to && message) {
    fetch(server + '/graphiql', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query: `
            query SendSMS($to: String!, $message: String!){
                sendSms(
                  to: $to,
                  message: $message
                )
            }
          `,
        variables: {
          to: to,
          message: message
        }
      })
    })
      .then(response => {
        return response.json()
          .then(data => ({
            ...data,
            status: response.status
          }))
      })
      .then(data => {
        const response = data.data.sendSms.split(":")
        callback(response)
        // if(data.data.loginUser === 'Invalid credentials'){
        //   that.setState({
        //     status:false,
        //   });
        //   alert("Błędne dane logowania");
        // } else {
        //   const token = data.data.loginUser;
        //   // JWT.keep(token);
        //   window.localStorage.setItem('token', token)
        //
        //   that.setState({
        //     status: true,
        //   })
        // }
      })
  }
}

export const sendEmail = (from, to, subject, message, callback = () => {}) => {
  const server = process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011'
  console.log(from)
  console.log(to)
  if (from && to) {
    fetch(server + '/graphiql', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query: `
            query SendEmail($from: String!, $to: String!, $subject: String, $message: String){
                sendEmail(
                  from: $from,
                  to: $to,
                  subject: $subject,
                  message: $message
                )
            }
          `,
        variables: {
          from: from,
          to: to,
          subject: subject,
          message: message
        }
      })
    })
      .then(response => {
        return response.json()
          .then(data => ({
            ...data,
            status: response.status
          }))
      })
      .then(data => {
        callback(data.data.sendEmail.split(":"))
      })
  }
}
