import React from 'react'
import { Input } from 'semantic-ui-react'

class SemanticInput extends React.Component {
  state = {
    value: this.props.value
  }

  handleChange = (event, data) => {
    this.setState(state => {
      state['value'] = data.value
      return state
    })
    this.props.onChange(data.value)
  }

  render () {
    const { props } = this

    return <div style={{ padding: '4px 0' }}>
      <Input
        name={props.id}
        label={props.label}
        placeholder={props.placeholder}
        value={this.state.value}
        onChange={(event, data) => this.handleChange(event, data)}
      />
    </div>
  }
}

export default SemanticInput
