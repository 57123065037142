import convert from 'xml-js'

const alwaysArray = el => el && el[0] ? el : [el]
const findNext = (json, element) => {
  if (!element['bpmn2:outgoing']) return null
  return alwaysArray(element['bpmn2:outgoing']).map(el => {
    const elements = alwaysArray(json['bpmn2:endEvent'])
      .concat(alwaysArray(json['bpmn2:startEvent']))
      .concat(alwaysArray(json['bpmn2:task']))
      .concat(alwaysArray(json['bpmn2:intermediateThrowEvent']))
    return elements.find(nexter => {
      const sequenceObject = alwaysArray(json['bpmn2:sequenceFlow']).find(seq => seq._attributes.id === el._text)
      return nexter._attributes.id === sequenceObject._attributes.targetRef
    })
  })
}
const getType = element => {
  if (/Task/g.test(element._attributes.id)) return 'task'
  if (/EndEvent/g.test(element._attributes.id) || /IntermediateThrowEvent/g.test(element._attributes.id)) return 'end'
  if (/StartEvent/g.test(element._attributes.id)) return 'start'
}
const recursive = (json, element) => {
  return {
    name: element._attributes.name,
    type: getType(element),
    nexts: findNext(json, element) ? findNext(json, element).map(el => recursive(json, el)) : null
  }
}
const findPhase = (process, name) => {
  if (process.name === name) return process
  if (!process.nexts) return false
  return process.nexts.map(el => findPhase(el, name)).find(el => el)
}

export const myPhase = (process, processes) => {
  const processId = JSON.parse(process.content).process
  const sourceProcess = processes.find(el => el.id === processId)
  const xml = JSON.parse(sourceProcess.content).xml
  const json = JSON.parse(convert.xml2json(xml, { compact: true }))['bpmn2:definitions']['bpmn2:process']
  console.log(json)
  const result = recursive(json, json['bpmn2:startEvent']).nexts[0]
  const phase = JSON.parse(process.content).phase
  return findPhase(result, phase)
}

export const addProcess = (process, store, id, type) => () => {
  const xml = JSON.parse(process.content).xml
  const json = JSON.parse(convert.xml2json(xml, { compact: true }))['bpmn2:definitions']['bpmn2:process']
  console.log(json)
  const result = recursive(json, json['bpmn2:startEvent'])
  if(!result.nexts)  return null;
  const nexts = result.nexts[0]
  const contact = store.data[type][0]
  contact.information.push({
    type: 'process',
    name: process.name,
    content: JSON.stringify({
      process: process.id,
      phase: nexts.name
    }),
    parent_id: id,
    parent_type: 'contacts'
  })
  store.sync()
}
