import React from 'react'
import { Dropdown, Label, Form, Input } from 'semantic-ui-react'

class SemanticSelect extends React.Component {
  state = {
    label: false
  }

  onChange = (event, data) => {
    this.setState(state => {
      state[data.name] = data.value
      return state
    })
    this.props.onChange(data.value)
  }

  render () {
    const { props, state } = this
    const { placeholder, value, options } = props
    let selectOptions = [
      {
        key: '',
        value: '',
        text: placeholder
      }
    ]
    options.enumOptions.map(opt => {
      selectOptions.push({
        key: opt.value,
        value: opt.value,
        text: opt.label
      })
    })

    console.log(props)

    return (
      <div className='my-custom-widget' style={{ padding: '4px 0' }}>
        <div className={'ui labeled input'} style={{float: 'left'}}>
          <div className={'ui label label'}>{props.label}</div>
        </div>
        <div>
          <Dropdown
            name={'label'}
            options={selectOptions}
            placeholder={placeholder}
            value={state.label ? state.label : value}
            onChange={this.onChange}
            selection
            compact
          />
        </div>
      </div>
    )
  }
}

export default SemanticSelect
