import React from 'react'
import _ from 'lodash'
import Radium from 'radium'
// import Avatar from 'react-avatar'
import { FullScreen, CloseButton } from 'components/Generics'
import Send from '../components/Send'
import { getLink } from 'services/helpers'
import { singleStore } from '../contacts/Single'
import { singleStore as productStore } from '../products/Single'
import { contentTranslations } from '../services/dictionaries'
import { nicePrompt } from 'services/prompt'
import { getOrganizationUsers } from '../services/helpers'

const SecondaryText = (props) => {
  const {item} = props;
  let text = '';
  if(item.indexType === 'contact') {
    const position = item.connectionsFrom && item.connectionsFrom.filter(el => el.status === '3').map(connection => {
      return connection.type + ' ' + connection.to
    });
    text = item.position //(position ? position[0] : '');
  } else if (item.indexType === 'content') {
    text = contentTranslations[item.type];
  } else if (item.indexType === 'product') {
    const contentObject = item.content_object;
    if(item.type === 'commercial') text = 'Cena: '+contentObject.price + ' (' + contentObject.quantity + ' szt.)'
    if(item.type === 'repeatable') text = contentObject.current + '/' + contentObject.max //' ('+((contentObject.current/contentObject.max)*100)+'%)'
    if(item.type === 'individual') text = item.owner && item.owner.name;
  }

  return <div style={{ fontSize: '14px', color: 'darkGray' }}>
    {text}
  </div>;
}

class UpdateProduct extends React.Component {

  componentDidMount() {

  }

  render() {
    return <div>dasad</div>
  }
}

const Avatar = ({name}) => {
  const initials = name.match(/\b\w/g) || []
  const formatted = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  return <div style={{
    height: 50,
    width: 50,
    lineHeight: '50px',
    background: '#2f3640',
    color: 'white',
    textAlign: 'center',
    borderRadius: '50%'
  }}>{formatted}</div>
}

class ListItem extends React.Component {
  state = {
    store: {loaded:false},
    hover: false,
    users: [],
    sender: {
      active: false,
      type: '',
      to: ''
    }
  }

  toggleSender = (store, type) => {
    this.setState({
      sender: {
        active: true,
        type: type
      },
      store: singleStore(this.props.id, 'sender')
    })
  }

  senderUnmount() {
    this.setState({
      sender: {active: false}
    })
    singleStore(this.props.id, 'sender').unlock()
  }

  updateRecord = (store, item) => {
    const productType = item.type;
    const content = item.content ? JSON.parse(item.content) : {};

    if(productType === 'commercial') {
      nicePrompt({
        title: 'Zmień stan magazynowy',
        confirm: 'Potwierdź',
        cancel: 'Anuluj',
        type: 'number',
        value: content.quantity,
      }).then(data => {
        content.quantity = data;
        store.data.product[0].content = JSON.stringify(content);
        store.sync()
      })
    } else if (productType === 'repeatable') {
      nicePrompt({
        title: 'Zmień aktualny stan',
        confirm: 'Potwierdź',
        cancel: 'Anuluj',
        type: 'number',
        value: content.current
      }).then(data => {
        content.current = data;
        store.data.product[0].content = JSON.stringify(content);
        store.sync()
      })
    } else if (productType === 'individual') {
      getOrganizationUsers(data => {
        const options = _.chain(data.users)
          .keyBy('id')
          .mapValues('name')
          .value();

        nicePrompt({
          id: item.id,
          title: 'Zmień właściciela',
          confirm: 'Potwierdź',
          cancel: 'Anuluj',
          type: 'select',
          value: item.owner.id,
          options: options,
        }).then(owner => {
          if(store.data.product[0].owner_id !== owner) {
            store.data.product[0].owner_id = owner;
            store.sync()
          }
        })
      })
    }
  }

  render() {
    const { result, checked, toggle, toggleObserve, list } = this.props;
    const { _source: item, highlight: hintText} = result

    const link = getLink(list, item)


    let allowedAddons = ['observe']
    if (item.indexType === 'contact') {
      allowedAddons = allowedAddons.concat(['phone', 'message'])
    } else if (item.indexType === 'product') {
      allowedAddons = allowedAddons.concat(['updateProduct'])
    }

    let style = {
      height: 50,
      width: '100%',
      margin: '10px 0'
    }

    const parsedContactData = item.contact_data ? JSON.parse(item.contact_data) : []

    return <div style={style}>
      <FullScreen active={this.state.sender.active} direction='left' style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center'
      }}>
        <Send store={this.state.store} type={this.state.sender.type} to={this.state.sender.to} data={parsedContactData} />
        <CloseButton click={() => this.senderUnmount()} />
      </FullScreen>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div onClick={toggle} style={{cursor: 'pointer'}}>
            <Avatar maxInitials={2} name={checked ? 'X' : (item.name || item.indexType)} size={50} round />
          </div>
          <div style={{ padding: '0px 10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ fontSize: '18px', width: '220px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{link}</div>
            {item && <SecondaryText item={item} />}
          </div>
        </div>
        <ListAddons toggleObserve={toggleObserve} item={item} addons={allowedAddons} toggleSender={this.toggleSender} data={parsedContactData} updateProduct={this.updateRecord} />
      </div>
    </div>
  }
}

export const ListAddons = (props) => {
  const { addons, item, data } = props

  const phones = data.filter(el => el.type === 'phone');
  const emails = data.filter(el => el.type === 'email');
  const defaultPhone = phones.find(el => el.status === 3);
  const defaultEmail = emails.find(el => el.status === 3);

  return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
    {phones.length > 0 && addons.indexOf('phone') !== -1 && <span onClick={() => {}}><a href={`tel:${defaultPhone ? defaultPhone.value : phones[0].value}`}><i className='fal fa-phone fa-fw' /></a></span>}
    {emails.length > 0 && addons.indexOf('message') !== -1 && <span style={{cursor: 'pointer'}} onClick={() => props.toggleSender(singleStore(item.id), 'email')}><i className='fal fa-at fa-fw' /></span>}
    {addons.indexOf('updateProduct') !== -1 && <span style={{cursor: 'pointer'}} onClick={() => {props.updateProduct(productStore(item.id, 'product'), item)}}><i className='fal fa-fw fa-edit' /></span>}
    {/*{addons.indexOf('observe') !== -1 && <span onClick={() => props.toggleObserve(props.id)}><i className='fal fa-eye fa-fw' /></span>}*/}
  </div>
}

const components = {
  contact: ListItem,
  content: ListItem,
  set: ListItem,
  product: ListItem,
  work: ListItem
}

export const Everylist = ({ hits, checked = [], toggle = () => {}, store }) => {
  return hits.filter(el => el._source !== null).map((hit, i) => {
    const Component = components[hit._source.indexType]
    return <Component
      key={hit._id}
      checked={checked.indexOf(hit._source.id) > -1}
      toggle={toggle(hit._source.id)}
      result={hit}
    />
  })
}

export default Radium(ListItem)
