import React from 'react'
import { observer } from 'mobx-react'
import { Button } from 'semantic-ui-react'

import FormBuilder from 'components/FormBuilder'

import Store from 'services/StoreStore'

const getStore = () => Store(storeQuery)
const currentQuery = () => Store(storeQuery).query === storeQuery
const storeQuery = `
  {
    getSettings {
      setting
      value
    }
  }`

const Container = observer(class _Container extends React.Component {
  componentDidMount () {
    getStore()
  }

  componentWillUnmount () {
    getStore().unlock()
  }

  handleSave = (store, type, json) => {
    store.executeMutation('setSettings', { ['custom' + type]: json })
  }

  render () {
    const {store, type} = this.props
    if (!store.loaded || !currentQuery()) return null
    const hasJson = store.data.getSettings.find(el => el.setting === `custom${type}`)
    if (!hasJson) {
      store.data.getSettings.push({
        setting: `custom${type}`,
        value: '{}'
      })
    }
    const index = store.data.getSettings.findIndex(el => el.setting === `custom${type}`)
    const json = store.data.getSettings.find(el => el.setting === `custom${type}`).value

    return <div>
      <FormBuilder
        value={JSON.stringify({
          type: 'object',
          properties: JSON.parse(json)
        })}
        onChange={value => {
          store.data.getSettings[index].value = JSON.stringify(JSON.parse(value).properties)
        }} />
      <br /><br />
      <Button onClick={() => this.handleSave(store, type, json)}>Zapisz</Button>
    </div>
  }
})

export default ({ match }) => <Container store={getStore()} type={match.params.type} />
