import React from 'react'
import { observer } from 'mobx-react'
import _ from 'lodash'
import { Button, Input, Container } from 'semantic-ui-react'
import Store from 'services/StoreStore'

export const singleStore = () => Store(storeQuery)
const currentQuery = () => Store(storeQuery).query === storeQuery
export const storeQuery = `
  {
    organization {
      id
      indexType
      settings
    }
  }`

const UserSettings = observer(class _UserSettings extends React.Component {
  componentDidMount () {
    singleStore()
  }

  componentWillUnmount () {
    singleStore().unlock()
  }
  render () {
    const { store } = this.props
    if (!store.loaded || !currentQuery()) return null
    const unpack = e => e.target ? e.target.value : e
    const organization = store.data.organization
    const settings = JSON.parse(organization.settings)
    const get = path => _.get(settings, path)
    const set = (path, value) => {
      _.set(settings, path, unpack(value))
      organization.settings = JSON.stringify(settings)
    }

    console.log(settings)

    return <Container>
      <Input fluid value={get('flarum.url')} onChange={(e) => set('flarum.url', unpack(e))} placeholder='flarum.url' /><br />
      <Input fluid value={get('flarum.admin')} onChange={(e) => set('flarum.admin', unpack(e))} placeholder='flarum.admin' /><br />
      <Input fluid value={get('flarum.password')} onChange={(e) => set('flarum.password', unpack(e))} placeholder='flarum.password' /><br />
      <Button onClick={() => store.sync()}>Zapisz</Button><br />
    </Container>
  }
})

export default props => <UserSettings store={singleStore()} />
