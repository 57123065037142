import React from 'react'
import _ from 'lodash'
import { Input, Select, Label, TextArea, Form } from 'semantic-ui-react'

const personalityOptions = (index) => {
  const list = {
    0: [
      {
        key: 0,
        value: 0,
        text: 'Neutralne'
      },
      {
        key: -2,
        value: -2,
        text: 'Mocno introwertyczna'
      },
      {
        key: -1,
        value: -1,
        text: 'Lekko introwertyczna'
      },
      {
        key: 2,
        value: 2,
        text: 'Mocno ekstrawertyczna'
      },
      {
        key: 1,
        value: 1,
        text: 'Lekko ekstrawertyczna'
      }
    ],
    1: [
      {
        key: 0,
        value: 0,
        text: 'Neutralne'
      },
      {
        key: -2,
        value: -2,
        text: 'Mocno neurotyczna'
      },
      {
        key: -1,
        value: -1,
        text: 'Lekko neurotyczna'
      },
      {
        key: 2,
        value: 2,
        text: 'Mocno stabilna'
      },
      {
        key: 1,
        value: 1,
        text: 'Lekko stabilna'
      }
    ],
    2: [
      {
        key: 0,
        value: 0,
        text: 'Neutralne'
      },
      {
        key: -2,
        value: -2,
        text: 'Mocno zamknięta'
      },
      {
        key: -1,
        value: -1,
        text: 'Lekko zamknięta'
      },
      {
        key: 2,
        value: 2,
        text: 'Mocno otwarta'
      },
      {
        key: 1,
        value: 1,
        text: 'Lekko otwarta'
      }
    ],
    3: [
      {
        key: 0,
        value: 0,
        text: 'Neutralne'
      },
      {
        key: -2,
        value: -2,
        text: 'Mocno ugodowa'
      },
      {
        key: -1,
        value: -1,
        text: 'Lekko ugodowa'
      },
      {
        key: 2,
        value: 2,
        text: 'Mocno antagonistyczna'
      },
      {
        key: 1,
        value: 1,
        text: 'Lekko antagonistyczna'
      }
    ],
    4: [
      {
        key: 0,
        value: 0,
        text: 'Neutralne'
      },
      {
        key: -2,
        value: -2,
        text: 'Mocno sumienna'
      },
      {
        key: -1,
        value: -1,
        text: 'Lekko sumienna'
      },
      {
        key: 2,
        value: 2,
        text: 'Mocno nieukierunkowana'
      },
      {
        key: 1,
        value: 1,
        text: 'Lekko nieukierunkowana'
      }
    ]
  }

  return list[index]
}

const Content = props => {
  const { get, set, add, save } = props
  return <div>
    <div>
      <h1>Krok 1 - ramy</h1>
      <Label pointing={'below'}>
        Najpierw zdefiniuj do czego potrzebny będzie ten zbiór person - np. 'pracownik-programista'
      </Label>
      <Input
        fluid
        value={get('name')}
        onChange={set('name')}
      />
    </div>
    <div className={'persona-section'}>
      <Label pointing={'below'}>
        Założenia podstawowe - jakie kompetencje powinien mieć pracownik? Jakie problemy musi chcieć rozwiązać klient?
      </Label>
      <Form>
        <TextArea value={get('basic')} onChange={set('basic')} />
      </Form>
    </div>
    <div>
      <Label pointing={'below'}>
        Wartości - cechy wymagane do współpracy (pracownicy/partnerzy)
      </Label>
      <Form>
        <TextArea value={get('values')} onChange={set('values')} />
      </Form>
    </div>
    <div>
      <h1>Krok 2 - od firmy do osoby</h1>
      <div className={'persona-section'}>
        <Label pointing={'below'}>
          Na podstawie kroku pierwszego, zdefiniuj cele osoby
        </Label>
        <Form>
          <TextArea value={get('targets')} onChange={set('targets')} />
        </Form>
      </div>
      <div className={'persona-section'}>
        <Label pointing={'below'}>
          I jej motywacje...
        </Label>
        <Form>
          <TextArea value={get('motivations')} onChange={set('motivations')} />
        </Form>
      </div>
    </div>

    <div>
      <h2>A także osobowość (big 5)</h2>
      <div className='personality'>
        <Label pointing={'below'}>Introwersja</Label>
        <Select fluid value={get('personality.0')} onChange={set('personality.0')} options={personalityOptions(0)} placeholder={'Wybierz wartość...'} />
      </div>
      <div className='personality'>
        <Label pointing={'below'}>Stabilność emocjonalna</Label>
        <Select fluid value={get('personality.1')} onChange={set('personality.1')} options={personalityOptions(1)} placeholder={'Wybierz wartość...'} />
      </div>
      <div className='personality'>
        <Label pointing={'below'}>Otwartność na doswiadczenie</Label>
        <Select fluid value={get('personality.2')} onChange={set('personality.2')} options={personalityOptions(2)} placeholder={'Wybierz wartość...'} />
      </div>
      <div className='personality'>
        <Label pointing={'below'}>Ugodowość vs. antagonizm</Label>
        <Select fluid value={get('personality.3')} onChange={set('personality.3')} options={personalityOptions(3)} placeholder={'Wybierz wartość...'} />
      </div>
      <div className='personality'>
        <Label pointing={'below'}>Sumienność vs. nieukierunkowanie</Label>
        <Select fluid value={get('personality.4')} onChange={set('personality.4')} options={personalityOptions(4)} placeholder={'Wybierz wartość...'} />
      </div>
    </div>

    <div>
      <Label pointing={'below'}>Ograniczenia demograficzne</Label>
      <Form>
        <TextArea value={get('demographics')} onChange={set('demographics')} />
      </Form>
    </div>

    <h1>Krok 3 - od osoby do firmy</h1>
    {get('personas').map((el, ind) => {
      const insideGet = key => get('personas.' + ind + '.' + key)
      const insideSet = key => set('personas.' + ind + '.' + key)
      return <div>
        <div className={'persona-section'}>
          <Input fluid label={'Imię'} value={insideGet('name')} onChange={insideSet('name')} />
        </div>
        <div className={'persona-section'}>
          <Input fluid label={'Zdjęcie'} value={insideGet('photo')} onChange={insideSet('photo')} />
        </div>
        <div className={'persona-section'}>
          <Label pointing={'below'}>BIO</Label>
          <Form>
            <TextArea value={insideGet('bio')} onChange={insideSet('bio')} />
          </Form>
        </div>
        <div className={'persona-section'}>
          <Input fluid label={'Wykształcenie'} value={insideGet('learn')} onChange={insideSet('learn')} />
        </div>
        <div className={'persona-section'}>
          <Input fluid label={'Wiek'} value={insideGet('age')} onChange={insideSet('age')} />
        </div>
        <div className={'persona-section'}>
          <Input fluid label={'Zawód'} value={insideGet('emoployment')} onChange={insideSet('emoployment')} />
        </div>
        <div className={'persona-section'}>
          <Input fluid label={'Dochód'} value={insideGet('cash')} onChange={insideSet('cash')} />
        </div>
        <div className={'persona-section'}>
          <Input fluid label={'Status rodzinny'} value={insideGet('family')} onChange={insideSet('family')} />
        </div>
        <div className={'persona-section'}>
          <Label pointing={'below'}>Cele osoby</Label>
          <Form>
            <TextArea value={insideGet('targets')} onChange={insideSet('targets')} />
          </Form>
        </div>
        <div className={'persona-section'}>
          <Label pointing={'below'}>I jej motywacje...</Label>
          <Form>
            <TextArea value={insideGet('motivations')} onChange={insideSet('motivations')} />
          </Form>
        </div>
        <h2>A także osobowość (big 5)</h2>
        <div className={'personality'}>
          <Label pointing={'below'}>Introwersja</Label>
          <Select fluid value={insideGet('personality.0')} onChange={insideSet('personality.0')} options={personalityOptions(0)} />
        </div>
        <div className={'personality'}>
          <Label pointing={'below'}>Stabilność emocjonalna</Label>
          <Select fluid value={insideGet('personality.1')} onChange={insideSet('personality.1')} options={personalityOptions(1)} />
        </div>
        <div className={'personality'}>
          <Label pointing={'below'}>Otwartność na doswiadczenie</Label>
          <Select fluid value={insideGet('personality.2')} onChange={insideSet('personality.2')} options={personalityOptions(2)} />
        </div>
        <div className={'personality'}>
          <Label pointing={'below'}>Ugodowość vs. antagonizm</Label>
          <Select fluid value={insideGet('personality.3')} onChange={insideSet('personality.3')} options={personalityOptions(3)} />
        </div>
        <div className={'personality'}>
          <Label pointing={'below'}>Sumienność vs. nieukierunkowanie</Label>
          <Select fluid value={insideGet('personality.4')} onChange={insideSet('personality.4')} options={personalityOptions(4)} />
        </div>
        <div className={'persona-section'}>
          <Input fluid label={'Social media'} value={insideGet('social')} onChange={insideSet('social')} />
        </div>
        <div className={'persona-section'}>
          <Input fluid label={'Media'} value={insideGet('media')} onChange={insideSet('media')} />
        </div>
        <div className={'persona-section'}>
          <Input fluid label={'Społeczności'} value={insideGet('society')} onChange={insideSet('society')} />
        </div>
      </div>
    })}
    <button onClick={add}>Nowa persona</button>
    <button onClick={save}>Zapisz</button>
  </div>
}

class Persona extends React.Component {
  state = {
    persona: {
      personas: []
    }
  }
  componentDidMount () {
    this.props.beforeSave(() => {})
  }
  addPersona = () => {
    this.setState(state => {
      state.persona.personas.push({
        personality: state.persona.personality,
        targets: state.persona.targets,
        motivations: state.persona.motivations
      })
      return state
    })
  }
  delPersona = ind => {
    this.setState(state => {
      state.persona.personas.splice(ind, 1)
      return state
    })
  }
  setPersona = path => (e, data) => {
    const value = data ? data.value : e
    this.setState(state => {
      _.set(state, 'persona.' + path, value)
      return state
    })
  }
  getPersona = path => _.get(this.state, 'persona.' + path)

  savePersona = () => {
    const record = this.props.store.data.content.find(el => el.id === parseInt(this.props.id))
    record.content = JSON.stringify({ content: this.state.persona })
    this.props.store.sync()
  }

  render () {
    return <Content set={this.setPersona} get={this.getPersona} add={this.addPersona} del={this.delPersona} save={this.savePersona} />
  }
}

export default Persona
