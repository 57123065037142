import React from 'react'
import Select from 'react-select'
import _ from 'lodash'
import Form from 'react-jsonschema-form-semanticui-fixed'
import filesize from 'filesize'
import { observer } from 'mobx-react'
import SimpleMDEEditor from 'yt-simplemde-editor'
import {Link} from 'react-router-dom'
import Mustache from 'mustache'
import moment from 'moment'
import Datetime from 'react-datetime'
import { Button, Card, Header, Dropdown, List } from 'semantic-ui-react'

import { TimerCalendar } from 'components/TimerCalendar'

import { nicePrompt } from 'services/prompt'
import axios from 'services/axios'
import pureAxios from 'axios'
import { fileUpload, fileDownload, filePath } from 'services/files'
import { myPhase, addProcess } from 'services/processes'
import { verifyContent } from '../services/verify'

const userToOption = user => ({
  value: user.id,
  label: user.name
})

const directory = {
  contact: 'Contacts',
  product: 'Products',
  set: 'Sets'
}

const File = observer(({ file, openOO, id, type, store }) => {
  const path = directory[type]
  const ooFormats = [
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ]
  const clickFile = (attachment, mimetype) => {
    if (ooFormats.indexOf(mimetype) > -1) {
      openOO({
        name: attachment.name,
        path: `/Attachments/${path}/` + id + '/' + attachment.name
      })
    } else {
      fileDownload(`/Attachments/${path}/` + id + '/' + attachment.name, attachment.name)
    }
  }
  const icon = {
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'fa-file-powerpoint',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa-file-word',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'fa-file-spreadsheet',
    'image/png': 'fa-file-image'
  }
  const imageFormats = [
    'image/png',
    'image/jpeg',
    'image/jpg'
  ]
  const content = JSON.parse(file.content)

  return <Card fluid style={{ borderRight: '10px solid red', lineHeight: '50px' }}>
    <Card.Content>
      <i style={{ position: 'relative', top: 5 }} className={'fal fa-fw fa-2x ' + (icon[content.mimetype] || 'fa-file')} />
      {file.name}
      <div style={{ float: 'right' }}>
        <i style={{ marginRight: '5px' }} onClick={() => clickFile(file, content.mimetype)} className={'fal fa-download'} />
        <i style={{ marginRight: '5px' }} onClick={() => {
          file._delete = true
          store.sync()
        }} className={'fal fa-trash-alt'} />
        {filesize(content.size)}
      </div>

      {imageFormats.indexOf(content.mimetype) > -1 && <div>
        <img style={{ maxWidth: '100%' }} alt={file.name} src={filePath(`/Attachments/${path}/` + id + '/' + file.name)} /><br />
        Treść(OCR): {content.tesseractText}
      </div>}
    </Card.Content>
  </Card>
})

const Task = observer(({ task, users, calendar, store }) => {
  const content = JSON.parse(task.content)
  console.log(task.end_date)
  return <Card fluid style={{ borderRight: '10px solid orange' }}>
    <Card.Content>
      <input value={task.name} className='smoothInput' placeholder='name...' onChange={e => {
        task.name = e.target.value
      }} onBlur={store.sync} />
      <input value={content.description} className='smoothInput' placeholder='description...' onChange={e => {
        content.description = e.target.value
        task.content = JSON.stringify(content)
      }} onBlur={store.sync} />
      <Select
        options={users.map(userToOption)}
        isMulti
        value={task.assigned ? users.filter(
          user => task.assigned.split(',').map(el => parseInt(el.substr(1))).indexOf(user.id) > -1
        ).map(userToOption) : []}
        onChange={val => {
          task.assigned = val.length > 0 ? val.map(el => ':' + el.value).join(',') : ' '
          store.sync()
        }}
      />
      <br />
      <Button size={'mini'} onClick={calendar} style={{ float: 'right' }}>
        mark work
      </Button>
      <Datetime renderInput={
        (props, openCalendar) => <div style={{ float: 'left' }} onClick={openCalendar}>{task.end_date ? moment(task.end_date).format('YYYY-MM-DD') : '(choose finish date)'}</div>
      } value={moment(task.end_date)} onChange={date => {
        task.end_date = date.format('YYYY-MM-DD')
        store.sync()
      }} timeFormat={false} />
      <div style={{ clear: 'both' }} />
      {task.completed
        ? <i style={{ float: 'left', color: 'green', fontSize: '24px', marginTop: '10px' }} onClick={() => {
          task.completed = 0
          store.sync()
        }} className='fal fa-check-square' />
        : <i style={{ float: 'left', fontSize: '24px', marginTop: '10px' }} onClick={() => {
          task.completed = 1
          store.sync()
        }} className='fal fa-square' />}
    </Card.Content>
  </Card>
})

const Note = observer(({ note, store }) => {
  const content = JSON.parse(note.content)
  return <Card fluid style={{ borderRight: '10px solid yellow' }}>
    <Card.Content>
      {content.content}
      <i className='fal fa-trash' style={{ float: 'right' }} onClick={() => {
        note._delete = true
        store.sync()
      }} />
    </Card.Content>
  </Card>
})

const Visit = observer(({ visit }) => {
  const content = JSON.parse(visit.content)
  const {website, date} = content
  const dateFormatted = moment.unix(date).format('YYYY-MM-DD HH:mm')
  return <Card fluid style={{ borderRight: '10px solid yellow' }}>
    <Card.Content>
      <List>
        <List.Item>
          <List.Header>Strona</List.Header>
          <Link to={'/contents/webpage/' + website}>Przejdź</Link>
        </List.Item>
        <List.Item>
          <List.Header>Do</List.Header>
          {dateFormatted}
        </List.Item>
      </List>
    </Card.Content>
  </Card>
})

const Message = observer(({ message }) => {
  const content = JSON.parse(message.content)
  const {status, from, to, subject, message: messageContent} = content
  return <Card fluid style={{ borderRight: '10px solid grey' }}>
    <Card.Content>
      <List>
        <List.Item>
          <List.Header>Od</List.Header>
          {from}
        </List.Item>
        <List.Item>
          <List.Header>Do</List.Header>
          {to}
        </List.Item>
        <List.Item>
          <List.Header>Temat</List.Header>
          {subject}
        </List.Item>
        <List.Item>
          <List.Header>Wiadomość</List.Header>
          {messageContent}
        </List.Item>
        <List.Item>
          <List.Header>Status</List.Header>
          {status === 'read' ? 'Przeczytana' : 'Nieprzeczytana'}
        </List.Item>
      </List>
    </Card.Content>
  </Card>
})

// const Payment = observer(({ payment, store }) => {
//   const name = payment.name
//   const content = JSON.parse(payment.content)
//   const value = content.value
//   const setValue = val => {
//     content.value = val
//     payment.content = JSON.stringify(content)
//   }
//   return <Card fluid style={{ borderRight: '10px solid pink' }}><Card.Content>
//     Nazwa: <input value={name} onChange={e => {
//       payment.name = e.target.value
//     }} onBlur={() => {
//       store.sync()
//     }} className='smoothInput' />
//   Wartość: <input value={value} onChange={e => {
//       setValue(e.target.value)
//     }} onBlur={() => {
//       store.sync()
//     }} className='smoothInput' />
//   </Card.Content></Card>
// })

const Process = observer(({ process, store, processes, changeContent }) => {
  const content = JSON.parse(process.content)
  return <Card fluid style={{ borderRight: '10px solid green' }}>
    <Card.Content>
      <Header as='h3'>
        <input value={process.name} onChange={e => {
          process.name = e.target.value
        }} onBlur={() => {
          store.sync()
        }} className='smoothInput' style={{width: '100%'}} />
      </Header>
      <div style={{ width: 75, float: 'right' }}>
        <input value={content.value} onChange={e => {
          content.value = e.target.value
          process.content = JSON.stringify(content)
        }} onBlur={store.sync} className='smoothInput' />
      </div>
      <div style={{ textTransform: 'uppercase', fontWeight: 200 }}>{content.phase}</div>
      <div style={{ marginTop: 10 }}>
        {myPhase(process, processes).nexts ? myPhase(process, processes).nexts.map(next => <Button style={{ width: '100%', marginBottom: 10 }} onClick={() => changeContent('phase')(next.name)}>
          {next.name}
        </Button>) : 'Proces zakończony'}
      </div>
    </Card.Content>
  </Card>
})

const Document = observer(({ document, contracts, offers, store, getDocument }) => {
  const content = JSON.parse(document.content)

  const offerAndContracts = offers.concat(contracts).find(el => el.id === JSON.parse(document.content).source)

  if (!verifyContent(offerAndContracts.content, 'OFFER_CONTRACT')) return null

  const schema = JSON.parse(offerAndContracts.content).json_schema

  return <Card fluid style={{ borderRight: '10px solid black' }}>
    <Card.Content>
      {!schema ? <div>Niepoprawny formularz dokumentu!</div>
        : <Form
          schema={JSON.parse(schema)}
          formData={JSON.parse(document.content).content}
          onChange={({ formData }) => {
            const content = JSON.parse(document.content)
            content.content = formData
            document.content = JSON.stringify(content)
          }}
          onSubmit={() => store.sync()}
        >
          <Button onClick={() => store.sync()}>Zapisz</Button>
          <Button onClick={() => getDocument(content.source, document.id)}>generuj</Button>
        </Form>}
    </Card.Content>
  </Card>
})

const Grade = observer(({ grade, grades, store }) => {
  const gradeContent = JSON.parse(grade.content)
  const gradeContentSource = gradeContent.source
  const mainGrade = grades.find(el => el.id === gradeContentSource)

  if(!mainGrade) return null;

  if (!verifyContent(mainGrade.content, 'GRADE')) return null

  const schema = JSON.parse(mainGrade.content).json_schema

  return <Card fluid style={{ borderRight: '10px solid blue' }}>
    <Card.Content>
      {!schema ? <div>Niepoprawny formularz oceny!</div>
        : <Form
          schema={JSON.parse(schema)}
          formData={JSON.parse(grade.content).content}
          onChange={({ formData }) => {
            const content = JSON.parse(grade.content)
            content.content = formData
            grade.content = JSON.stringify(content)
          }}
        >
          <Header as='h4'>Wynik: {JSON.parse(grade.content).result}</Header>
          <Button onClick={() => {
            const gradeContent = JSON.parse(grades.find(el => el.id === JSON.parse(grade.content).source).content)
            const content = JSON.parse(grade.content)
            if (gradeContent.logic) {
              const equation = gradeContent.logic.equation || false
              const calc = gradeContent.logic.calc || false
              const equationFields = Mustache.parse(equation).filter(el => el[0] === 'name')
              const isEquationValid = equationFields.filter(el => content.content[el[1]]).length === equationFields.length
              if ((equation || calc) && isEquationValid) {
                const equ = Mustache.render(equation, content.content)
                if (calc === 'average') {
                  content.result = eval(equ) / Object.keys(content.content).length
                } else {
                  content.result = eval(equ)
                }
                grade.content = JSON.stringify(content)
                store.sync()
              }
            }
          }}>Przelicz</Button>
        </Form>
      }
    </Card.Content>
  </Card>
})

const Stream = observer(class _Stream extends React.Component {
  constructor (props) {
    super(props)
    this.fileInput = React.createRef()
    this.imageInput = React.createRef()
  }
  state = {
    newNote: '',
    openCalendar: false,
    editorOpen: false
  }
  fileType = name => {
    if (/docx/g.test(name)) return 'text'
    if (/xlsx/g.test(name)) return 'spreadsheet'
    if (/pptx/g.test(name)) return 'presentation'
  }
  openOO = file => {
    this.setState({
      editorOpen: true
    }, () => {
      if (this.docEditor) {
        this.docEditor.destroyEditor()
      }
      this.docEditor = new window.DocsAPI.DocEditor('placeholder', {
        documentType: this.fileType(file.name),
        'document': {
          'fileType': file.name.split('.').slice(-1).join('.'),
          'title': file.name,
          'url': filePath(file.path).replace(/localhost/g, 'host').replace('https://mega2.storwork.pl', 'http://host:3011'),
          key: window.btoa(file.path)
        },
        'editorConfig': {
          'callbackUrl': 'http://host:3011/onlyoffice/track'
        }
      })
    })
  }
  render () {
    const { store, id, type } = this.props
    const { openOO } = this
    const path = directory[type]

    if (!store.loaded) return null

    const { data } = store

    const { processes, offers, contracts, grades } = data
    const { information, tasks } = data[type][0]
    const merge = tasks.concat(information)
    const sortedInfoTask = merge.sort((a, b) => a.updated_at - b.updated_at)
    console.log(information);
    const onDrop = (image = false, magicFile = false) => {
      const input = image ? this.imageInput : this.fileInput
      const file = magicFile || input.current.files[0]
      console.log(file)
      fileUpload(file, '/Attachments/' + path + '/' + id + '/' + file.name).then(() => {
        store.executeMutation('createinformation', {
          type: 'file',
          name: file.name,
          content: JSON.stringify({
            name: file.name,
            mimetype: file.type,
            size: file.size,
            path: `/Attachments/${path}/` + id + '/' + file.name
          }),
          parent_type: type + 's',
          parent_id: id
        })
      })
    }
    const onlyOffice = fileType => {
      nicePrompt({
        title: 'Nazwa dokumentu',
        confirm: 'Dodaj',
        cancel: 'Anuluj'
      }).then(name => {
        pureAxios({
          url: 'https://mega2.storwork.pl/assets/onlyoffice/' + fileType + '.' + fileType,
          method: 'GET',
          responseType: 'blob'
        }).then((response) => {
          const ooFormats = {
            xlsx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            pptx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
          const file = new window.File([response.data], name + '.' + fileType, {
            type: ooFormats[fileType]
          })
          fileUpload(file, '/Attachments/' + path + '/' + id + '/' + file.name).then(() => {
            store.executeMutation('createinformation', {
              type: 'file',
              name: file.name,
              content: JSON.stringify({
                name: file.name,
                mimetype: file.type,
                size: file.size,
                path: `/Attachments/${path}/` + id + '/' + file.name
              }),
              parent_type: type + 's',
              parent_id: id
            })
          })
        })
        // https://mega2.storwork.pl/assets/onlyoffice/docx.docx
        // https://mega2.storwork.pl/assets/onlyoffice/xlsx.xlsx
        // https://mega2.storwork.pl/assets/onlyoffice/pptx.pptx






      })
    }
    const getDocument = (contentId, informationId) => {
      const localToken = window.localStorage.getItem('token')
      axios(localToken).get((process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011') + `/document/${type}/${id}/${contentId}/${informationId}`, {
        responseType: 'blob'
      }).then((response) => {
        console.log('============',response)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'zlecenie.pdf')
        document.body.appendChild(link)
        link.click()
      })
    }
    const changeContent = id => path => nextName => {
      const process = information.find(el => el.id === id)
      const content = JSON.parse(process.content)
      _.set(content, path, nextName)
      process.content = JSON.stringify(content)
      store.sync()
    }
    const createGradeLike = (gradeId, type = 'rating') => {
      const element = store.data[this.props.type][0]
      element.information.push({
        type,
        content: JSON.stringify({
          source: gradeId,
          content: {}
        }),
        parent_id: id,
        parent_type: type + 's'
      })
      store.sync()
    }
    const addNote = () => {
      const element = store.data[this.props.type][0]
      element.information.push({
        type: 'note',
        content: JSON.stringify({
          content: this.state.newNote
        }),
        parent_id: id,
        parent_type: type + 's'
      })
      store.sync()
      this.setState({
        newNote: ''
      })
    }
    const addTask = () => {
      nicePrompt({
        title: 'Nazwa nowego zadania',
        confirm: 'Dodaj',
        cancel: 'Anuluj'
      }).then(name => {
        store.executeMutation('createtask', {
          name: name,
          type: 'task',
          content: '{}',
          parent_id: id,
          parent_type: (type === 'work' ? 'sets' : type + 's')
        })
      })
    }
    const contentGrades = grades.filter(el => verifyContent(el.content, 'GRADE'))
    // const addPayment = () => {
    //   store.executeMutation('createinformation', {
    //     name: '',
    //     type: 'payment',
    //     content: '{}',
    //     parent_id: id,
    //     parent_type: type + 's'
    //   })
    // }

    const users = store.data.organization.users

    if (this.state.editorOpen) return <Editor />
    return <div style={{ position: 'relative' }}>
      {this.state.openCalendar && <TimerCalendar close={() => this.setState({ openCalendar: false })} task={tasks.find(el => el.id === this.state.openCalendar)} store={store} />}
      <div style={{ display: 'none' }}>
        <input type='file' ref={this.fileInput} onChange={() => onDrop(false)} />
        <input type='file' accept='image/*' capture='camera' ref={this.imageInput} onChange={() => onDrop(true)} />
      </div>
      {contentGrades.length > 0 &&
        <Dropdown button text='Ocena' style={{ marginBottom: 5 }}>
          <Dropdown.Menu>
            {contentGrades.map(grade => {
              return <Dropdown.Item key={'GRADE_ITEM_' + grade.id} text={grade.name} onClick={() => createGradeLike(grade.id, 'rating')} />
            })}
          </Dropdown.Menu>
        </Dropdown>
      }
      <Dropdown button text='Załącznik' style={{ marginBottom: 5 }}>
        <Dropdown.Menu>
          <Dropdown.Item text='Dodaj plik' onClick={() => this.fileInput.current.click()} />
          <Dropdown.Item text='Dodaj obraz' onClick={() => this.imageInput.current.click()} />
          <Dropdown.Item text='Dodaj dokument' onClick={() => onlyOffice('docx')} />
          <Dropdown.Item text='Dodaj arkusz' onClick={() => onlyOffice('xlsx')} />
          <Dropdown.Item text='Dodaj prezentację' onClick={() => onlyOffice('pptx')} />
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown button text='Proces' style={{ marginBottom: 5 }}>
        <Dropdown.Menu>
          {processes.map(process => {
            console.log(process);
            return <Dropdown.Item key={'PROCESS_ITEM_' + process.id} onClick={addProcess(process, store, id, type)} text={'Dodaj proces ' + process.name} />
          })}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown button text='Oferta' style={{ marginBottom: 5 }}>
        <Dropdown.Menu>
          {offers.filter(el => verifyContent(el.content, 'OFFER')).map(offer => <Dropdown.Item key={'OFFER_ITEM_' + offer.id} onClick={() => createGradeLike(offer.id, 'offer')} text={'Utwórz ' + offer.name} />)}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown button text='Umowa' style={{ marginBottom: 5 }}>
        <Dropdown.Menu>
          {contracts.filter(el => verifyContent(el.content, 'CONTRACT')).map(contract => <Dropdown.Item key={'CONTRACT_ITEM_' + contract.id} onClick={() => createGradeLike(contract.id, 'contract')} text={'Utwórz ' + contract.name} />)}
        </Dropdown.Menu>
      </Dropdown>
      <Button onClick={addTask}>Zadanie</Button>
      {/*<Button onClick={addPayment}>Payment</Button>*/}
      <div id='notes-simplemde' style={{ marginBottom: 10 }}>
        <SimpleMDEEditor
          value={this.state.newNote}
          onChange={value => {
            this.setState({ newNote: value })
          }}
          options={{
            status: false,
            toolbar: false,
            placeholder: 'Dodaj notatkę tekstową...',
            spellCheck: false
          }}
          getMdeInstance={simplemde => {
            this.simplemde = simplemde
            this.simplemde.codemirror.on('blur', () => {
              if (this.state.newNote !== '') addNote()
            })
          }}
        />
      </div>
      {sortedInfoTask.map((el, i) => {
        if (el.type === 'process') return <Process key={`${el.type}_${el.id}`} store={store} process={el} processes={processes} changeContent={changeContent(el.id)} />
        if (el.type === 'file') return <File key={`${el.type}_${el.id}`} store={store} type={type} openOO={openOO} file={el} id={id} />
        if (el.type === 'rating') return <Grade key={`${el.type}_${el.id}`} store={store} grades={grades} grade={el} />
        if (el.type === 'offer' || el.type === 'contract') return <Document key={`${el.type}_${el.id}`} getDocument={getDocument} store={store} document={el} offers={offers} contracts={contracts} />
        if (el.type === 'note') return <Note key={`${el.type}_${el.id}`} store={store} note={el} />
        if (el.type === 'task') return <Task key={`${el.type}_${el.id}`} store={store} task={el} users={users} calendar={() => this.setState({ openCalendar: el.id })} />
        if (el.type === 'message') return <Message key={`${el.type}_${el.id}`} store={store} message={el} />
        if (el.type === 'websiteVisit') return <Visit key={`${el.type}_${el.id}`} store={store} visit={el} />
      })}
    </div>
  }
})
// {tasks.map(task => <Task key={task.id} store={store} task={task} users={users} calendar={() => {
//   this.setState({ openCalendar: task.id })
// }} />)}

const Editor = observer(class _Editor extends React.Component {
  render () {
    return <div style={{
      position: 'absolute',
      zIndex: 10000,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }}>
      <div style={{
        position: 'absolute',
        zIndex: 10001,
        right: 50,
        top: 50
      }} onClick={this.props.closeEditor}>CLOSE EDITOR</div>
      <div id='placeholder' />
    </div>
  }
})

export default Stream
