import React, { Component } from 'react'
import Select from 'react-select'
import { SearchkitManager, SearchkitProvider, Hits } from 'searchkit'

const searchkit = new SearchkitManager(process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011', {
  httpHeaders: {
    'Authorization': window.localStorage.getItem('token')
  }
})

const toOption = item => ({
  value: item.id,
  label: item.name
})

class ElasticSearchSelect extends Component {

  state = {
    object: false,
    type: '',
    results: [],
    query: ''
  };

  componentDidMount() {
    searchkit.setQueryProcessor((plainQueryObject) => {
      plainQueryObject.query = {
        bool: {
          must: {
            query_string: {
              query: "*" + this.state.query + "*",
              fields: ['name']
            }
          },
          filter: {
            term: {
              indexType: this.props.searchType
            }
          }
        }
      }
      return plainQueryObject
    })
  }

  modifySearch = (val) => {
    const results = searchkit.results.hits.hits.map(hit => {
      return {id: hit._source.id, name: hit._source.name};
    })

    this.setState({
      query: val,
      results: results
    })

    searchkit.reloadSearch()
  }

  render () {
    if(!searchkit) return null;
    return <SearchkitProvider searchkit={searchkit}>
      <Select
        isClearable={true}
        options={this.state.results.map(toOption)}
        value={this.props.value}
        name={'object'}
        onChange={(val) => this.props.onChange({target: {value: val, name: 'object'}})}
        onInputChange={(val) => {this.modifySearch(val)}}

      />
    </SearchkitProvider>
  }

}

export default ElasticSearchSelect