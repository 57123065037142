export const createConnection = (data, direction, store) => {
  if(data) {
    fetch((process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011') + '/graphiql', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query: `
          mutation createConnection($input:ConnectionInputType!) {
            createConnection(input:$input) {
              id
              from_id
              from_type
              to_id
              to_type
              from
              to
              type
            }
          }
        `,
        variables: {
          input: data
        }
      })
    }).then(response => {
      return response.json().then(data => ({
        ...data,
        status: response.status
      }))
    }).then(data => {
      console.log(data)
      if (data.data.createConnection) {
        const connections = store.data['connections' + (direction.charAt(0).toUpperCase() + direction.slice(1))]
        connections.push(data.data.createConnection)
      }
    }).catch(error => {
      console.log(error)
    })
  }
}

export const deleteConnection = (id, direction, store) => {
  if (id) {
    fetch((process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011') + '/graphiql', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query: `
            mutation deleteConnection($id:Int!) {
              deleteConnection(id:$id)
            }
          `,
        variables: {
          id: id
        }
      })
    })
    .then(response => {
      return response.json()
      .then(data => ({
        ...data,
        status: response.status
      }))
    })
    .then(data => {
      if(data.data.deleteConnection === true) {
        const connections = store.data['connections'+(direction.charAt(0).toUpperCase() + direction.slice(1))]
        const indexToRemove = connections.findIndex(el => el.id === id)
        connections.splice(indexToRemove, 1)
      }
    }).catch(error => {
      console.log(error)
    })
  }
}