import React from 'react'
import { observer } from 'mobx-react'
import { Input, Icon, Button } from 'semantic-ui-react'

import GoogleConnect from 'components/GoogleConnect'
import { FullScreen, CloseButton } from 'components/Generics'

export default observer(class _MassActions extends React.Component {
  state = {
    activeTab: false,
    emailFrom: ''
  }
  render () {
    const { activeTab, emailFrom, smsContent } = this.state
    const { checked, active, store, type, hideMassActions, allowed, getCheckedRecords, uncheckAll } = this.props

    const executeMassAction = (command, params) => {
      store.executeMutation('createaction', {
        command,
        type,
        params,
        ids: checked.join(','),
        progress: 0,
        is_completed: 0
      })
      this.setState({
        activeTab: false
      })
      hideMassActions()
      uncheckAll()
    }

    if (!store.loaded) return null
    return <FullScreen direction='left' active={active}>
      {activeTab && <div onClick={() => this.setState({ activeTab: false })}>
        <i className='fal fa-arrow-left' /> Powrót
      </div>}
      {activeTab === 'email' && <div className={'mass-action'}>
        <Input icon='mail' iconPosition='left' fluid value={emailFrom} onChange={e => this.setState({ emailFrom: e.target.value })} />
        {store.data.templates.map(template => <div onClick={() => executeMassAction('mail', JSON.stringify({
          template: template.id,
          from: emailFrom
        }))}>
          <div className={'mass-action-button'}>
            <Button fluid size={'mini'}>
              {template.name}
            </Button>
          </div>
        </div>)}
      </div>}

      {activeTab === 'google' && <GoogleConnect onClose={() => {
        this.setState({
          activeTab: false
        })
        hideMassActions()
      }} contacts={getCheckedRecords().filter(el => checked.indexOf(el.id) > -1)} groupName='storwork' />}

      {activeTab === 'sms' && <div className={'mass-action'}>
        <Input icon='text telephone' iconPosition='left' fluid value={smsContent} onChange={e => this.setState({ smsContent: e.target.value })} />
        <div className={'mass-action-button'}>
          <Button fluid size={'mini'} onClick={() => executeMassAction('sms', JSON.stringify({
            content: smsContent
          }))}>Wyślij</Button>
        </div>
      </div>}

      {(activeTab === 'tag' || activeTab === 'untag') && <div className={'mass-action'}>
        {store.data.taxonomies.map(taxonomy => <div className={'mass-action-button'} onClick={() => executeMassAction('taxonomy', JSON.stringify({
          direction: activeTab === 'tag' ? 'on' : 'off',
          taxonomyId: taxonomy.id
        }))}>
          <Button basic fluid size={'mini'}>
            {taxonomy.name}
          </Button>
        </div>)}
      </div>}

      {activeTab === 'owner' && <div className={'mass-action'}>
        {store.data.organization.users.map(user => <div className={'mass-action-button'} onClick={() => executeMassAction('field', JSON.stringify({
          field: 'owner_id',
          value: user.id
        }))}>
          <Button basic fluid size={'mini'}>
            {user.name}
          </Button>
        </div>)}
      </div>}

      {!activeTab && <div className={'mass-action'}>
        {allowed.indexOf('email') !== -1 && <div onClick={() => this.setState({ activeTab: 'email' })} className={'mass-action-button'}>
          <Button icon labelPosition='left' fluid>
            <Icon name='mail' />
            E-mail
          </Button>
        </div>}
        {allowed.indexOf('sms') !== -1 && <div onClick={() => this.setState({ activeTab: 'sms' })} className={'mass-action-button'}>
          <Button icon labelPosition='left' fluid>
            <Icon name='text telephone' />
            SMS
          </Button>
        </div>}
        {allowed.indexOf('observe') !== -1 && <div onClick={() => executeMassAction('observe', JSON.stringify({ direction: 'on', userId: store.getUser().user.id }))} className={'mass-action-button'}>
          <Button icon labelPosition='left' fluid>
            <Icon name='eye' />
            Obserwuj
          </Button>
        </div>}
        {allowed.indexOf('unobserve') !== -1 && <div onClick={() => executeMassAction('observe', JSON.stringify({ direction: 'off', userId: store.getUser().user.id }))} className={'mass-action-button'}>
          <Button icon labelPosition='left' fluid>
            <Icon name='eye slash' />
            Przestań obserwować
          </Button>
        </div>}
        {allowed.indexOf('tag') !== -1 && <div onClick={() => this.setState({ activeTab: 'tag' })} className={'mass-action-button'}>
          <Button icon labelPosition='left' fluid>
            <Icon name='tag' />
            Dodaj tag do rekordu
          </Button>
        </div>}
        {allowed.indexOf('untag') !== -1 && <div onClick={() => this.setState({ activeTab: 'untag' })} className={'mass-action-button'}>
          <Button icon labelPosition='left' fluid>
            <Icon name='trash' />
            Usuń tag z rekordu
          </Button>
        </div>}
        {allowed.indexOf('owner') !== -1 && <div onClick={() => this.setState({ activeTab: 'owner' })} className={'mass-action-button'}>
          <Button icon labelPosition='left' fluid>
            <Icon name='user' />
            Zmień właściciela
          </Button>
        </div>}
        <div onClick={() => this.setState({ activeTab: 'google' })} className={'mass-action-button'}>
          <Button icon labelPosition='left' fluid>
            <Icon name='user' />
            Dodaj do kontaktów Google
          </Button>
        </div>
      </div>}

      <CloseButton click={() => hideMassActions()} />
    </FullScreen>
  }
})
