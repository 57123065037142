import React from 'react'
import { observer } from 'mobx-react'
import { Input, Container, Table } from 'semantic-ui-react'

import Store from 'services/StoreStore'
import { invite } from 'services/auth'

export const singleStore = () => Store(storeQuery)
const currentQuery = () => Store(storeQuery).query === storeQuery
export const storeQuery = `
  {
    organization {
      id
      name
      indexType
      users {
        id
        name
        indexType
        permissions {
          id
          indexType
          org_id
          user_id
          role
          token
        }
      }
    }
  }`
const UserSettings = observer(class _UserSettings extends React.Component {
  state = {
    invitationEmail: ''
  }

  componentDidMount () {
    singleStore()
  }

  componentWillUnmount () {
    singleStore().unlock()
  }

  onChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState(state => {
      state[name] = value
      return state
    })
  }

  sendInvitation = (email, orgId) => {
    invite(email, orgId)
    this.setState({
      invitationEmail: ''
    })
  }

  /**
   * Gerara here
   *
   * @param userId
   * @param orgId
   * @param store
   */
  youAreFired = (userId, orgId, store) => {
    const record = store.data.organization.users.find(el => el.id === userId).permissions.find(el => el.org_id === orgId)
    record._delete = true
    store.sync()
  }

  toggleRole = (userId, orgId, role, store) => {
    const record = store.data.organization.users.find(el => el.id === userId).permissions.find(el => el.org_id === orgId)
    record.role = role
    store.sync()
  }

  render () {
    const { store } = this.props
    if (!store.loaded || !currentQuery()) return null
    const { organization } = store.data
    const { users } = organization

    const tokenData = store.getUser()

    return <Container>
      <Input
        fluid
        action={{ content: 'Send invitation', onClick: () => this.sendInvitation(this.state.invitationEmail, tokenData.organization.id) }}
        label={'Invite to organization:'}
        name={'invitationEmail'}
        type='email'
        id={'invitationEmail'}
        placeholder={'E-mail address...'}
        value={this.state.invitationEmail}
        onChange={this.onChange}
      />
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='4' textAlign={'center'}>Organization users</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell width={6}>Name</Table.HeaderCell>
            <Table.HeaderCell width={3}>Current permissions</Table.HeaderCell>
            <Table.HeaderCell width={3} colSpan={2}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users.map(user => <Table.Row key={user.id}>
            <Table.Cell>{user.name}</Table.Cell>
            {user.permissions.filter(el => el.org_id === tokenData.organization.id).map(permission => {
              return <Table.Cell key={permission.id}>
                {permission.role || 'Brak uprawnień'}
              </Table.Cell>
            })}
            {user.permissions.filter(el => el.org_id === tokenData.organization.id).map(permission => {
              const role = permission.role === 'User' ? 'SuperAdmin' : 'User'
              return <Table.Cell key={permission.id} className={'action-icon'} onClick={() => this.toggleRole(user.id, tokenData.organization.id, role, store)}>
                {role}
              </Table.Cell>
            })}
            {tokenData.user.id !== user.id && <Table.Cell className={'action-icon'} onClick={() => this.youAreFired(user.id, tokenData.organization.id, store)}>remove</Table.Cell>}
          </Table.Row>)}
        </Table.Body>
      </Table>
    </Container>
  }
})

export default props => <UserSettings store={singleStore()} />
