import React, { Component } from 'react'
import {observer} from 'mobx-react'
import _ from 'lodash'
import { Draggable } from 'react-beautiful-dnd'

import CardList from './CardList'

const uuidv4 = require('uuid/v4')

export default observer(class Column extends Component {
  state = {
    edited: false
  }
  startEdit = () => {
    this.setState({
      edited: true
    }, () => {
      this.input.focus()
    })
  }
  stopEdit = () => {
    this.setState({
      edited: false
    })
  }
  changeName = name => {
    const column = this.props.column
    column.name = name
    this.props.onChange(column)
  }
  deleteList = () => {
    this.props.onDelete()
  }
  render () {
    const {column, onChange, columnId, index} = this.props
    return (
      <Draggable draggableId={columnId} index={index}>
        {(provided, snapshot) => (
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }} ref={provided.innerRef} {...provided.draggableProps}>
            <div style={{
              background: '#dfe3e6',
              borderRadius: '3px',
              width: 200,
              marginRight: '20px'
            }}>
              <div style={{
                padding: '10px 10px 5px 10px',
                fontWeight: 'bold',
                minHeight: '15px'
              }} {...provided.dragHandleProps} isDragging={snapshot.isDragging} onClick={this.startEdit}>
                {this.state.edited ? <div>
                  <input onBlur={this.stopEdit} ref={input => this.input = input} value={column.name} onChange={e => this.changeName(e.target.value)} />
                  <div style={{float: 'right', cursor: 'pointer'}} onClick={this.deleteList}>
                    <i className="fal fa-trash"></i>
                  </div>
                </div> : <div>
                  {column.name}
                </div>}
              </div>
              <div style={{padding: '0 10px'}}>
                <CardList listId={columnId} listType="QUOTE" cards={column.cards} changeCards={cards => {
                  column.cards = cards
                  onChange(column)
                }} />
              </div>
              <div style={{
                padding: '8px 10px',
                cursor: 'pointer',
                color: 'grey'
              }} onClick={() => {
                column.cards.push({
                  id: uuidv4().replace(/-/g, ''),
                  name: ''
                })
                onChange(column)
              }}>
                <i className="fal fa-plus"></i> Dodaj nową kartę...
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
})
