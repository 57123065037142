import React from 'react'
import { observer } from 'mobx-react'
import $ from 'jquery'
import moment from 'moment'
import { Form, TextArea, Button, Message } from 'semantic-ui-react'

import Store from 'services/StoreStore'
import axios from 'services/axios'
import { bugsnagClient } from '../index'

export const getMessagesStore = id => Store(`
  {
    messages: search(type: "message", source: "database", filter: "channel_id:${id}") {
      ... on Message {
        id
        content
      }
    }
  }`, 'messages')

const getStore = () => Store(storeQuery)
const currentQuery = () => Store(storeQuery).query === storeQuery

export const storeQuery = `
  {
    me {
      id
      settings
      channels {
        id
        indexType
        name
        active
        group_id
        public
        message_count
        users_json
        users {
          id
          name
        }
      }
    }
    organization {
      id
      users {
        id
        name
      }
      channels {
        id
        name
      }
    }
  }`

class AudioVideo extends React.Component {
  buildMediaComponent = () => {
    const mediaElement = this.props.useVideo ? $('<video width="320" height="240">') : $('<audio>')
    mediaElement.attr('autoplay', 'autoplay')
    this.props.muted ? mediaElement.prop('muted', true) : mediaElement.prop('muted', false)
    mediaElement.attr('controls', '')
    if (this.props.hide) {
      mediaElement.hide()
    }
    mediaElement[0].srcObject = this.props.src
    this.props.returnMedia(mediaElement)
    $('#video-container-' + this.props.id).append(mediaElement)
  }
  componentDidMount () {
    this.buildMediaComponent()
  }
  shouldComponentUpdate (nextProps, nextState) {
    /*
      Handling zmian - propsy umożliwiają komunikację z VIDEO.
    */
    return false
  }
  render () {
    return <div id={'video-container-' + this.props.id} style={{ display: 'none' }} />
  }
}

const Messages = observer(class _Messages extends React.Component {
  render () {
    const { store } = this.props
    if (!store.loaded) return null
    console.log(store.data.messages)
    return <div style={{ marginBottom: '10px' }}>
      {store.data.messages.map(messages => <div>
        {JSON.parse(messages.content).map(message => <Message size='mini'>
          {moment(message.date).format('MMMM Do YYYY, h:mm:ss a')}<br />
          {message.content}
        </Message>)}
      </div>)}
    </div>
  }
})

const Container = observer(class _Container extends React.Component {
  state = {
    chosenChannel: null,
    voiceChannel: null,
    myId: '',
    myPeer: null,
    mediaStream: null,
    peerStreams: {},
    localMedia: null,
    peerMedias: {},
    newMessage: '',
    microphoneOn: true,
    speakersOn: true
  }
  componentDidMount () {
    getStore()
  }
  componentWillUnmount () {
    getStore().unlock()
  }
  microphoneTurn = (on = !this.state.microphoneOn) => {
    this.state.mediaStream.getAudioTracks()[0].enabled = on
    this.setState({ microphoneOn: on })
  }
  speakersTurn = (on = !this.state.speakersOn) => {
    const mediasIds = Object.keys(this.state.peerMedias)
    mediasIds.map(mediaId => this.state.peerMedias[mediaId].prop('muted', !on))
    this.setState({ speakersOn: on })
  }
  turnoff = () => {
    this.setState({
      voiceChannel: null,
      myId: '',
      myPeer: null,
      mediaStream: null,
      peerStreams: {},
      localMedia: null,
      peerMedias: {}
    })
  }
  saveLocalMedia = content => {
    this.setState(state => {
      state.localMedia = content
      return state
    })
  }
  saveRemoteMedia = id => content => {
    this.setState(state => {
      state.peerMedias[id] = content
      return state
    })
  }
  changeChannel = channel => () => {
    this.setState({
      chosenChannel: channel
    })
  }
  joinVoice = channel => () => {
    const { id: userId } = this.props.store.getUser().user

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((mediaStream) => {
        const host = process.env.REACT_APP_PEER_SERVER_HOST || 'localhost'
        const port = process.env.REACT_APP_PEER_SERVER_PORT || 9000
        const key = process.env.REACT_APP_PEER_SERVER_KEY || 'storwork'
        this.setState({ mediaStream }, () => {
          const peer = new window.Peer(channel.id + '-' + userId, {
            iceServers: [
              { url: 'stun:stun.l.google.com:19302' }
            ],
            key: key,
            host: host,
            port: port,
            secure: true
          })
          this.setState({
            peer
          })
          peer.on('open', (id) => {
            console.log('My peer ID is: ' + id)
          })
          peer.on('call', (call) => {
            call.answer(this.state.mediaStream)
            call.on('stream', (mediaStream) => {
              this.setState(state => {
                state.peerStreams[call.peer.id] = mediaStream
                return state
              })
            })
          })

          const active = channel.active || ''
          const userIds = active.split(',')

          this.props.store.declareVoiceChannel(channel.id)
          this.setState({
            voiceChannel: channel
          })

          userIds.map(userId => {
            if (!userId) return null
            const call = peer.call(channel.id + '-' + userId, this.state.mediaStream)
            call.on('stream', (mediaStream) => {
              this.setState(state => {
                state.peerStreams[channel.id + '-' + userId] = mediaStream
                return state
              })
            })
          })
        })
      })
      .catch(err => {
        bugsnagClient.notify(new Error(err))
      })
  }
  sendMessage = () => {
    this.props.store.executeMutation('sendMessage', {
      channel_id: this.state.chosenChannel.id,
      author: this.props.store.data.me.id,
      date: moment().format(),
      content: this.state.newMessage
    })
    this.setState({
      newMessage: ''
    })
  }
  chooseChannel = id => {
    // console.log(JSON.stringify(this.props.store.data.me.channels))
    return this.props.store.data.me.channels.find(el => el.id === id)
  }
  render () {
    const { store, notifications, notificationStore } = this.props
    const { peerStreams, chosenChannel, voiceChannel, newMessage, microphoneOn, speakersOn } = this.state
    if (!store.loaded || !currentQuery()) return null
    const { me, organization } = store.data
    const { id: userId, channels, settings } = me
    const { users, channels: organizationChannels } = organization
    const jsonSettings = JSON.parse(settings)

    if (chosenChannel) {
      const notification = notifications.find(el => el.parent_id === chosenChannel.id && el.isread === 0)
      if (notification) {
        setTimeout(() => {
          notification.isread = 1
        }, 500)
        notificationStore.executeMutation('notificationRead', {
          id: notification.id,
          isread: 1
        })
      }
    }

    const showChannel = channel => {
      // ROzróżnenie mieðzy kanałami userskimi i zwykłymi
      const matches = channel.name.match(/([0-9]+)-([0-9]+)/g)
      if (matches) {
        const user = channel.users.find(user => user.id !== parseInt(userId))
        return user.name
      } else {
        return channel.name
      }
    }

    const usersChannel = user => {
      const channelName = userId > user.id ? user.id + '-' + userId : userId + '-' + user.id
      return channels.find(channel => channel.name === channelName)
    }

    // console.log(JSON.stringify(channels))
    const changeChannelOneOnOne = user => () => {
      const channel = usersChannel(user)
      if (channel) return this.changeChannel(channel)
      // TODO: add channel
      const channelName = userId > user.id ? user.id + '-' + userId : userId + '-' + user.id
      store.feedback = id => {
        setTimeout(() => {
          this.setState({
            chosenChannel: this.chooseChannel(id)
          })
        }, 1000)
      }
      store.executeMutation('createchannel', {
        name: channelName,
        users_json: JSON.stringify([
          { id: userId, message_count: 0 },
          { id: user.id, message_count: 0 }
        ])
      })
    }

    const channelNotifications = channel => {
      const { notifications } = this.props
      const notification = notifications.find(el => el.parent_id === channel.id)
      return notification ? notification.counter : ''
    }

    const loginFlarum = () => {
      const server = process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011'
      axios().get(server + '/flarum').then(response => {
        const win = window.open(response.data, '_blank')
        win.focus()
      })
    }
    // console.log(JSON.stringify(chosenChannel))
    return <div style={{ padding: '10px' }}>
      {this.state.mediaStream && <AudioVideo src={this.state.mediaStream} id='local' muted returnMedia={this.saveLocalMedia} />}
      {Object.keys(peerStreams).map(key => <AudioVideo src={peerStreams[key]} id={key} returnMedia={this.saveRemoteMedia(key)} />)}
      {chosenChannel === null && <div style={{ padding: 10 }}>
        <Title>Twoje rozmowy</Title>
        {channels.map(channel => {
          return <Channel onClick={this.changeChannel(channel)}>
            {showChannel(channel)} {channelNotifications(channel)}
          </Channel>
        })}
        <Title>Kanały organizacji</Title>
        {organizationChannels.map(channel => {
          return <Channel onClick={this.changeChannel(channel)}>{channel.name} {channelNotifications(channel)}</Channel>
        })}
        <Title>Usery organizacji</Title>
        {users.map(user => {
          if (user.id === userId) return null
          return <Channel onClick={changeChannelOneOnOne(user)}>{user.name}</Channel>
        })}
      </div>}
      {chosenChannel !== null && <div>
        <div style={{ height: '50px', lineHeight: '50px', width: '100%' }}>
          <div onClick={() => {
            getMessagesStore().unlock()
            this.setState({
              chosenChannel: null
            })
          }} style={{ float: 'left', width: 50, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className='fal fa-arrow-left' />
          </div>
          <div style={{ float: 'right', width: 50, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {voiceChannel === null && <div onClick={this.joinVoice(chosenChannel)}><i className='fal fa-phone' /></div>}
          </div>
          {chosenChannel.name}
        </div>
        <Messages store={getMessagesStore(chosenChannel.id)} />
        <Form onSubmit={e => {
          e.preventDefault()
          this.sendMessage()
        }}>
          <Form.Field>
            <TextArea rows={2} placeholder='Wpisz wiadomosc...' value={newMessage} onChange={e => this.setState({
              newMessage: e.target.value
            })} />
          </Form.Field>
          <Button type='submit'>Wyślij</Button>
        </Form>
      </div>}
      <div style={{ padding: 10 }}>
        {voiceChannel !== null ? <div>
          Kanał: {voiceChannel.name}<br />
          <span onClick={() => this.microphoneTurn()}>({microphoneOn ? 'wyłącz' : 'włącz'} mikrofon)</span>
          <span onClick={() => this.speakersTurn()}>({speakersOn ? 'wyłącz' : 'włącz'} głośniki)</span>
          <span onClick={this.turnoff}>(rozłącz)</span>
        </div> : <div>Nie podłączono</div>}
        {
          jsonSettings && jsonSettings.flarumLogin && jsonSettings.flarumPassword &&
          <div style={{ cursor: 'pointer' }} onClick={loginFlarum}>Przejdź do forum</div>
        }
      </div>
    </div>
  }
})

const Channel = (props) => <div {...props} style={{
  fontSize: 13,
  padding: '10px 0',
  cursor: 'pointer'
}}>{props.children}</div>

const Title = (props) => <div {...props} style={{
  fontSize: 13,
  fontWeight: 300,
  textTransform: 'uppercase'
}}>{props.children}</div>

export default props => <Container {...props} store={getStore()} />
