import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import Templates from './templates/Templates'
import BPMN from './bpmn/Bpmn'
import ContentForm from './forms/ContentForm'
import Markdown from './markdown/Markdown'
import Persona from './persona/Persona'
import Labels from 'components/Labels'

import Store from 'services/StoreStore'
import ToggleObserve from 'components/ToggleObserve'
import Grid from 'components/Grid'
import { squareButton } from 'components/Generics'
import webtemplates from 'dictionaries/webtemplates'
import emailtemplates from 'dictionaries/emailtemplates'

export const singleStore = id => Store(storeQuery(id))
const currentQuery = id => Store(storeQuery(id)).query === storeQuery(id)
export const storeQuery = id => `
  {
    content: search(type: "content", source: "database", id: ${id}, filter: "id:${id}") {
      ... on Content {
        id
        indexType
        type
        name
        content
        public
        labels {
          id
          type
          name
          content
        }
        taxonomies {
          id
          type
          name
          content
        }
        taxonomables {
          id
          indexType
          parent_id
          parent_type
          taxonomy_id
        }
        observables {
          id
          indexType
          parent_id
          parent_type
          user_id
        }
      }
    }
    taxonomies: search(type:"taxonomy", source:"database") {
      ... on Taxonomy {
        id
        name
        type
        content
      }
    }
  }`


const Single = observer(class _Single extends React.Component {
  state = {
    beforeSave: () => {}
  }
  componentDidMount () {
    singleStore(this.props.match.params.id)
  }
  componentWillUnmount () {
    singleStore(this.props.match.params.id).unlock()
  }

  onChange = (e, store) => {
    const { formData } = e
    const templateId = parseInt(this.props.match.params.id)
    const record = store.data.content.find(el => templateId === el.id)
    record.content = JSON.stringify(formData)
  }

  beforeSave = func => {
    this.setState({
      beforeSave: func
    })
  }

  save = () => {
    this.state.beforeSave()
    singleStore(this.props.match.params.id).sync()
  }

  togglePublic = () => {
    const store = singleStore(this.props.match.params.id)
    const content = store.data.content[0]
    content.public = Number(!Boolean(content.public))
    store.sync()
  }

  emailTemplateLoad = () => {
    const store = singleStore(this.props.match.params.id)
    const content = store.data.content[0]
    const data = JSON.parse(content.content)
    data.grapes = emailtemplates.qweasd
    content.content = JSON.stringify(data)
    store.sync()
    setTimeout(() => {
      window.location.reload()
    }, 2000)
  }

  webTemplateLoad = () => {
    const store = singleStore(this.props.match.params.id)
    const content = store.data.content[0]
    const data = JSON.parse(content.content)
    data.grapes = webtemplates.qweasd
    content.content = JSON.stringify(data)
    store.sync()
    setTimeout(() => {
      window.location.reload()
    }, 2000)
  }

  render () {
    const { id, type } = this.props.match.params
    const store = singleStore(id)
    if (!store.loaded || !currentQuery(id)) return null
    const record = store.data.content[0]

    return <Grid>
      <div style={{ gridArea: 'main_data', padding: '0 5px', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} size='90px' area='main_data' type='row'>
        <div>
          <div style={{ width: '100%', padding: 5, display: 'flex', alignItems: 'center' }}>
            <Link to={'/contents'}>
              <div style={squareButton({ float: 'left' })}>
                <i className='fal fa-arrow-left' />
              </div>
            </Link>
            <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', marginRight: '10px' }} />
            <div style={{ marginLeft: '10px' }}>
              <div>
                <input value={record.name} onChange={e => (store.data.content[0].name = e.target.value)} className='smoothInput' />
              </div>
              <div>
                <span>{record.type}</span>
              </div>
            </div>
          </div>
          <div style={{ width: '100%', padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <div style={{ marginRight: '10px' }}>
              <Labels store={store} type={'content'} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
              <ToggleObserve type={'content'} store={store} />
              <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
              <i className='fal fa-share-alt' onClick={this.togglePublic} style={{ color: record.public && 'green' }}/>
              <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
              <i className='fal fa-save' onClick={this.save} />

              {type === 'email_template' && <React.Fragment>
                <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
                <i className='fal fa-angle-down' onClick={this.emailTemplateLoad} />
              </React.Fragment>}

              {type === 'webpage' && <React.Fragment>
                <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
                <i className='fal fa-angle-down' onClick={this.webTemplateLoad} />
              </React.Fragment>}

              <div style={{ height: '30px', width: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'inline-block', margin: '0 10px' }} />
              <i className='fal fa-trash fa-fw' style={{ marginRight: '5px' }} onClick={() => {
                store.data.content[0]._delete = true
                store.sync()
                window.location.assign('/contents')
              }} />
            </div>
          </div>
        </div>
      </div>
      {['webpage', 'email_template'].indexOf(type) !== -1 && <Templates beforeSave={this.beforeSave} store={store} id={id} {...this.props} />}
      {['bpmn'].indexOf(type) !== -1 && <BPMN beforeSave={this.beforeSave} store={store} id={id} {...this.props} />}
      {['form', 'offer', 'contract'].indexOf(type) !== -1 && <ContentForm beforeSave={this.beforeSave} store={store} id={id} {...this.props} onChange={(e) => this.onChange(e, store)} from={type} />}
      {['article'].indexOf(type) !== -1 && <Markdown beforeSave={this.beforeSave} store={store} id={id} {...this.props} />}
      {['persona'].indexOf(type) !== -1 && <Persona beforeSave={this.beforeSave} store={store} id={id} {...this.props} />}
      {['rating'].indexOf(type) !== -1 && <ContentForm beforeSave={this.beforeSave} store={store} id={id} {...this.props} onChange={(e) => this.onChange(e, store)} from={type} />}
    </Grid>
  }
})
// {['tree'].indexOf(type) !== -1 && <Tree beforeSave={this.beforeSave} store={store} id={id} {...this.props} />}

export default Single
