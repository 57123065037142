import React from 'react'
/**
 * Observe from single
 *
 * @param type
 * @param store
 */
export const toggleObserve = (type, store) => {
  if (!store.loaded) return null
  const record = store.data[type][0]
  const user = store.getUser().user
  let isObserved = record.observables.filter(el => el.user_id === user.id)

  if (isObserved.length > 0) {
    const index = record.observables.filter(el => el.user_id === user.id)
    index.map((i, key) => {
      record.observables[key]._delete = true
    })
  } else {
    record.observables.push({
      user_id: user.id
    })
  }
  store.sync()
}

const ToggleObserve = (props) => {
  const { type, store } = props

  let isObserved = false
  if (!store.data[type][0]) isObserved = false

  isObserved = store.data[type][0].observables.filter(el => el.user_id === store.getUser().user.id)
  return <React.Fragment>
    <span style={props.styles} onClick={() => toggleObserve(type, store)}>
      {isObserved.length > 0
        ? <span title={'Observed'}><i style={{color: 'green'}} className='fal fa-rss fa-fw' /></span>
        : <span title={'Not observed'}><i style={{color: 'black'}} className='fal fa-rss fa-fw' /></span>
      }
    </span>
  </React.Fragment>
}

export default ToggleObserve
