import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Menu, Grid, Container } from 'semantic-ui-react'

import CategoryTree from './CategoryTree'
import CustomFields from './CustomFields'
import UserSettings from './UserSettings'
import OrganizationSettings from './OrganizationSettings'
import TeamSettings from './TeamSettings'

export default class Routes extends React.Component {
  state = {
    redirect: false
  }
  render () {
    const { redirect } = this.state
    if (redirect) {
      this.setState({ redirect: false })
      return <Redirect to={'/settings/' + redirect} />
    }
    const activeItem = this.props.location.pathname.split('/').slice(-1)[0]
    return <Container fluid><Grid stackable columns={2}>
      <Grid.Column width={3}>
        <Menu fluid pointing secondary vertical>
          <Menu.Item content='Pola własne kontaktu' active={activeItem === 'Contact'} onClick={() => this.setState({ redirect: 'custom/Contact' })} />
          <Menu.Item content='Pola własne pracy' active={activeItem === 'Work'} onClick={() => this.setState({ redirect: 'custom/Work' })} />
          <Menu.Item content='Pola własne przedmiotu' active={activeItem === 'Product'} onClick={() => this.setState({ redirect: 'custom/Product' })} />
          {/*<Menu.Item name='Kategorie' active={activeItem === 'categories'} onClick={() => this.setState({ redirect: 'categories' })} />*/}
          <Menu.Item content='Labels' active={activeItem === 'labels'} onClick={() => this.setState({ redirect: 'labels' })} />
          <Menu.Item content='Użytkownik' active={activeItem === 'user'} onClick={() => this.setState({ redirect: 'user' })} />
          <Menu.Item content='Organizacja' active={activeItem === 'organization'} onClick={() => this.setState({ redirect: 'organization' })} />
          <Menu.Item content='Zespół' active={activeItem === 'team'} onClick={() => this.setState({ redirect: 'team' })} />
        </Menu>
      </Grid.Column>
      <Grid.Column width={13}>
        <br />
        <Route path='/settings/custom/:type' exact component={CustomFields} />
        {/*<Route path='/settings/categories' exact component={CategoryTree} />*/}
        <Route path='/settings/labels' exact component={CategoryTree} />
        <Route path='/settings/user' exact component={UserSettings} />
        <Route path='/settings/organization' exact component={OrganizationSettings} />
        <Route path='/settings/team' exact component={TeamSettings} />
      </Grid.Column>
    </Grid></Container>
  }
}
