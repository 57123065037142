import Swal from 'sweetalert2'
import { singleStore as productStore } from '../products/Single'

export const nicePrompt = ({ title, confirm, cancel, value = '', type = 'text', options, id }) => new Promise(resolve => {
  Swal.fire({
    title: title,
    input: type,
    inputValue: value,
    inputAttributes: {
      autocapitalize: 'off'
    },
    inputOptions: options,
    showCancelButton: true,
    confirmButtonText: confirm,
    cancelButtonText: cancel,
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
    if (result.value) {
      resolve(result.value)
    }
  })
})
