import React from 'react'
import { observer } from 'mobx-react'
import _ from 'lodash'
import { Button, Input, Container } from 'semantic-ui-react'
import Store from 'services/StoreStore'

export const singleStore = () => Store(storeQuery)
const currentQuery = () => Store(storeQuery).query === storeQuery
export const storeQuery = `
  {
    me {
      id
      indexType
      settings
    }
  }`

const UserSettings = observer(class _UserSettings extends React.Component {
  componentDidMount () {
    singleStore()
  }

  componentWillUnmount () {
    singleStore().unlock()
  }
  render () {
    const { store } = this.props
    if (!store.loaded || !currentQuery()) return null
    const unpack = e => e.target ? e.target.value : e
    const user = store.data.me
    const settings = JSON.parse(user.settings)
    const get = path => _.get(settings, path)
    const set = path => value => {
      _.set(settings, path, unpack(value))
      user.settings = JSON.stringify(settings)
    }
    console.log(settings)
    return <Container>
      <Input fluid value={get('flarum.username')} onChange={set('flarum.login')} placeholder='flarum.login' /><br />
      <Input fluid value={get('flarum.password')} onChange={set('flarum.password')} placeholder='flarum.password' /><br />
      <Button onClick={store.sync}>Zapisz</Button>
    </Container>
  }
})

export default props => <UserSettings store={singleStore()} />
