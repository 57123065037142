export const contentTranslations = {
  webpage: 'strona internetowa',
  rating: 'ocena',
  bpmn: 'proces',
  form: 'formularz',
  persona: 'edytor person',
  article: 'artykuł',
  email_template: 'szablon email',
  tree: 'drzewo',
  offer: 'sprzedaż',
  contract: 'umowa'
}
