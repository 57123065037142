import React, { Component } from 'react'
import _ from 'lodash'
import Form from 'react-jsonschema-form'
import { observer } from 'mobx-react'
import { Input, Icon, Select, Label, Button, Grid } from 'semantic-ui-react'

import SemanticInput from './semantic-ui/SemanticInput'
import SemanticSelect from './semantic-ui/SemanticSelect'
import LabelSelect from './semantic-ui/LabelSelect'

function slugify (text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

const FormBuilder = observer(class _FormBuilder extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeField: 'new',
      fieldToChange: '',
      schema: props.value ? JSON.parse(props.value) : {
        type: 'object',
        properties: {}
      },
      uiSchema: {}
    }
  }
  componentWillReceiveProps (props) {
    // const newState = {
    //   activeField: 'new',
    //   fieldToChange: '',
    //   schema: props.value ? JSON.parse(props.value) : {
    //     type: 'object',
    //     properties: {}
    //   }
    // }
    // if (JSON.stringify(newState.schema) === JSON.stringify(this.props.schema)) return
    this.setState({
      schema: props.value ? JSON.parse(props.value) : {
        type: 'object',
        properties: {}
      }
    })
  }
  onClick = e => {
    if (!e) return
    this.setState({
      activeField: e,
      fieldToChange: e
    })
  }
  CustomFieldTemplate = props => {
    const { id, classNames, label, help, required, description, errors, children } = props
    const classNamesWithActive = this.state.activeField === props.help._owner.key ? classNames + ' active' : classNames + ' pointer'
    const excludeRoot = id === 'root' ? '' : classNamesWithActive
    return (
      <div className={excludeRoot} onClick={() => this.onClick(props.help._owner.key)} style={{ padding: '4px 0' }}>
        <label htmlFor={id}>{required ? '*' : null}</label>
        {description}
        {children}
        {errors}
        {help}
      </div>
    )
  }
  handleChange = (path) => (value) => {
    const newValue = value.target ? value.target.value : value
    this.setState(state => {
      _.set(state, path, newValue)
      return state
    }, this.onChange)
  }
  addQuestion = (callback = this.onChange) => {
    const title = 'Nowe pole ' + Object.keys(this.state.schema.properties).length
    this.setState(state => {
      state.schema.properties[slugify(title)] = {
        swType: 'text',
        type: 'string',
        title: title
      }
      return state
    }, () => {
      callback(slugify(title))
    })
  }
  newEnum = name => () => {
    this.setState(state => {
      state.schema.properties[name].enum.push('')
      state.schema.properties[name].enumNames.push('')
      return state
    }, this.onChange)
  }
  deleteEnum = (name, ind) => {
    this.setState(state => {
      state.schema.properties[name].enum.splice(ind, 1)
      state.schema.properties[name].enumNames.splice(ind, 1)
      return state
    }, this.onChange)
  }
  changeType = name => (e, val) => {
    let value = e.target ? e.target.value : e
    if(!value) {
      value = val.value
    }
    this.setState(state => {
      state.schema.properties[name].swType = value
      if (value === 'text') {
        state.schema.properties[name].type = 'string'
        delete state.schema.properties[name].enum
        delete state.schema.properties[name].enumNames
      }
      if (value === 'number') {
        state.schema.properties[name].type = 'integer'
        delete state.schema.properties[name].enum
        delete state.schema.properties[name].enumNames
      }
      if (value === 'enum') {
        state.schema.properties[name].type = 'string'
        state.schema.properties[name].enum = []
        state.schema.properties[name].enumNames = []
      }
      return state
    }, this.onChange)
  }
  addCustomQuestion = value => {
    this.addQuestion((name) => {
      this.changeType(name)(value)
    })
  }
  deleteField = e => {
    this.setState(state => {
      const { activeField } = this.state
      delete state.schema.properties[activeField]
      state.activeField = 'new'
      return state
    }, this.onChange)
  }
  moveField = () => {
    this.setState(state => {
      const { fieldToChange, activeField } = this.state
      state.schema.properties[fieldToChange] = state.schema.properties[activeField]
      delete state.schema.properties[activeField]
      state.activeField = fieldToChange
      return state
    }, this.onChange)
  }
  onChange = () => {
    this.props.onChange(JSON.stringify(this.state.schema))
  }
  render () {
    const fieldTypeOptions = [
      {
        key: 'text',
        text: 'tekst',
        value: 'text'
      },
      {
        key: 'number',
        text: 'numer',
        value: 'number'
      }, {
        key: 'enum',
        text: 'radio',
        value: 'enum'
      }
    ]

    const { schema, activeField } = this.state
    const { properties } = schema
    const activeElement = properties[activeField]
    const widgets = {
      labelSelect: LabelSelect,
      semanticInput: SemanticInput,
      semanticSelect: SemanticSelect
    }
    let uiSchema = {}

    Object.keys(properties).map(field => {
      const swType = properties[field].swType

      if (swType === 'text') {
        uiSchema[field] = {
          'ui:widget': 'semanticInput'
        }
      } else if (swType === 'number') {
        uiSchema[field] = {
          'ui:widget': 'semanticInput'
        }
      } else if (swType === 'enum') {
        uiSchema[field] = {
          'ui:widget': 'semanticSelect',
          'ui:placeholder': 'Wybierz opcję...'
        }
      }
    })

    const MyForm = props => <Form schema={this.state.schema} uiSchema={uiSchema} widgets={widgets} FieldTemplate={this.CustomFieldTemplate} >
      <span />
    </Form>

    return <div padding='15px'><Grid>
      {this.state.activeField === 'new' ? <Grid.Column width={4}>
        Dodaj pole<br />
        <div style={{ padding: '4px 0px', float: 'left' }}>
          <Button
            size={'mini'}
            icon
            labelPosition='right'
            onClick={() => this.addCustomQuestion('text')}
          >
            Tekstowe
            <Icon name='plus' />
          </Button>
        </div>
        <div style={{ padding: '4px 0px', float: 'left' }}>
          <Button
            size={'mini'}
            icon
            labelPosition='right'
            onClick={() => this.addCustomQuestion('number')}
          >
            Numeryczne
            <Icon name='plus' />
          </Button>
        </div>
        <div style={{ padding: '4px 0px', float: 'left' }}>
          <Button
            size={'mini'}
            icon
            labelPosition='right'
            onClick={() => this.addCustomQuestion('enum')}
          >
            Jednokrotnego wyboru
            <Icon name='plus' />
          </Button>
        </div>
      </Grid.Column> : <Grid.Column width={4}>
        Edytuj pole
        <div>
          <div className='form-group' style={{ padding: '4px 0' }}>
            <Label pointing={'right'}>Nazwa pola</Label>
            <Input
              value={activeElement ? activeElement.title : ''}
              onChange={this.handleChange('schema.properties.' + activeField + '.title')}
              placeholder='Enter field title...'
            />
          </div>
        </div>
        <div className='form-group' style={{ padding: '4px 0' }}>
          <div className='control'>
            <Label pointing={'right'}>Slug</Label>
            <Input
              value={this.state.fieldToChange}
              onChange={this.handleChange('fieldToChange')}
              onBlur={this.moveField}
              placeholder='Enter slug...'
            />
          </div>
        </div>
        <div className='form-group' style={{ padding: '4px 0' }}>
          <div className='control'>
            <Label pointing={'right'}>Typ</Label>
            <Select
              placeholder={'Select field type...'}
              options={fieldTypeOptions}
              value={activeElement ? activeElement.swType : ''}
              onChange={this.changeType(activeField)}
            />
          </div>
        </div>
        <br /><hr /><br />
        <div style={{ float: 'left' }}>
          <Button
            icon
            size={'mini'}
            labelPosition='right'
            onClick={() => this.onClick('new')}
          >
            Dodaj pole
            <Icon name='plus' />
          </Button>
        </div>
        <div style={{ float: 'left' }}>
          <Button
            color={'red'}
            size={'mini'}
            icon
            labelPosition='right'
            onClick={() => this.deleteField()}
          >
            Usuń pole
            <Icon name='trash' />
          </Button>
        </div>
        {activeElement && activeElement.swType === 'enum' && <React.Fragment>
          <Button onClick={this.newEnum(activeField)}>Nowa wartość</Button>
          {(activeElement && activeElement.enum.length > 0) && 'Lista wartości:'}
          {activeElement && activeElement.enum.map((__, ind) => <div key={ind} className='columns'>
            <div style={{ padding: '10px 0px' }}>
              <div className='col-sm-5'>
                <Input
                  placeholder='Wartość...'
                  value={activeElement.enum[ind]}
                  onChange={this.handleChange('schema.properties.' + activeField + '.enum.' + ind)}
                />
              </div>
              <div className='col-sm-5'>
                <Input
                  placeholder='Opis wartości...'
                  value={activeElement.enumNames[ind]}
                  onChange={this.handleChange('schema.properties.' + activeField + '.enumNames.' + ind)}
                />
              </div>
              <div className={'col-sm-2 text-center'}>
                <Button
                  color={'red'}
                  size={'small'}
                  icon
                  labelPosition='right'
                  onClick={() => this.deleteEnum(activeField, ind)}
                >
                  Usuń wartość
                  <Icon name='trash' />
                </Button>
              </div>
            </div>
          </div>
          )}
        </React.Fragment>
        }
      </Grid.Column>}
      <Grid.Column>
        <MyForm />
      </Grid.Column>
    </Grid>
  </div>
  }
})

export default FormBuilder
