export const createActionForm = (type) => {
  const logic = (type === 'rating' ? {
    'title': '',
    'type': 'object',
    'properties': {
      'equation': {
        'title': 'Równanie',
        'type': 'string'
      },
      'calc': {
        'title': 'Funkcja',
        'placeholder': 'ddd',
        'type': 'string',
        'enum': [
          'sum',
          'average'
        ],
        'enumNames': [
          'Suma',
          'Średnia'
        ]
      }
    }
  } : {})

  const content = ((type === 'contract' || type === 'offer') ? {
    'title': 'Content',
    'type': 'string'
  } : {})

  const label = (type === 'form' ? {
    'title': 'Label',
    'type': 'integer'
  } : {})

  return {
    'json': {
      'type': 'object',
      'properties': {
        'name': {
          'title': 'Nazwa',
          'type': 'string'
        },
        'description': {
          'title': 'Opis',
          'type': 'string',
          'default': '[]'
        },
        // 'mapping': {
        //   'type':'object',
        //   'properties': {
        //     'src': {
        //       'title': 'Source',
        //       'type': 'string',
        //       'enum':[
        //         'pole z buildera 1',
        //         'pole z buildera 2',
        //       ]
        //     },
        //     'dest': {
        //       'title': 'Destination',
        //       'type': 'string',
        //       'enum':[
        //         'pole z bazy 1',
        //         'pole z bazy 2',
        //       ]
        //     }
        //   }
        // },
        'type': {
          'title': 'Rodzaj',
          'type': 'string',
          'enum': [
            'start',
            'normal',
            'final',
            'main'
          ],
          'enumNames': [
            'Start',
            'Normal',
            'Final',
            'Main'
          ],
          'default': 'main'
        },
        'logic': logic,
        'assign_label': label,
        'content_value': content,
        'json_schema': {
          'title': 'JSON Schema',
          'type': 'string'
        },
        'ui_schema': {
          'title': 'UI Schema',
          'type': 'string',
          'default': '{}'
        },
        'validation': {
          'title': 'Walidacja',
          'type': 'string'
        }
      },
      'required': ['name', 'type', 'json_schema']
    },
    'ui': {
      'name': {
        'ui:widget': 'semanticInput',
        'ui:options': {
          'label': false
        }
      },
      'description': {
        'ui:widget': 'hidden',
        'ui:options': {
          'rows': 3
        }
      },
      'type': {
        'ui:widget': 'hidden'
      },
      'logic': {
        'classNames': 'no-margin form-builder-section',
        'ui:options': {
          'label': false,
          'title': false
        },
        'equation': {
          'ui:widget': 'semanticInput',
          'ui:options': {
            'label': false
          }
        },
        'calc': {
          'ui:widget': 'semanticSelect',
          'ui:options': {
            'label': false
          },
          'ui:placeholder': 'Select function...'
        }
      },
      'assign_label': {
        'classNames': 'no-margin form-builder-section',
        'ui:widget': 'labelSelect',
        'ui:options': {
          'label': false
        }
      },
      'content_value': {
        'classNames': 'no-margin form-builder-section',
        'ui:widget': 'markdownEditor',
        'ui:options': {
          'label': false
        }
      },
      'json_schema': {
        'classNames': 'no-margin form-builder-section',
        'ui:widget': 'formBuilder'
      },
      'ui_schema': {
        'ui:widget': 'hidden',
        'ui:options': {
          'rows': 8
        }
      },
      'validation': {
        'ui:widget': 'hidden',
        'ui:options': {
          'rows': 3
        }
      },
      'mapping': {
        'classNames': 'col-sm-12',
        'src': {
          'classNames': ''
        },
        'dest': {
          'classNames': ''
        }
      }
    }
  }
}
