/**
 * GRADE.content must have keys: logic, json_schema (with valid schema)
 * OFFER.content must have keys: json_schema, content (with valid schema)
 * CONTRACT.content must have keys: json_schema, content (with valid schema)
 *
 * @param content
 * @param type
 * @returns {boolean}
 */
export const verifyContent = (content, type) => {
  let status = false;
  const parsed = JSON.parse(content)
  switch(type) {
    case 'GRADE':
      status = (parsed.logic && parsed.json_schema);
      break;
    case 'OFFER':
      status = (parsed.json_schema && parsed.content_value);
      break;
    case 'CONTRACT':
      status = (parsed.json_schema && parsed.content_value);
      break;
    case 'OFFER_CONTRACT':
      status = (parsed.json_schema && parsed.content_value);
      break;
    default:
      status = false
  }

  return status;
}