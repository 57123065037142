import React, { Component } from 'react'
import { SortingSelector } from 'searchkit'

class CommonSort extends Component {
  render () {
    return <div>
      {/*<SortingSelector options={[*/}
        {/*{ label: 'From name A-Z', field: 'name.keyword', order: 'asc' },*/}
        {/*{ label: 'From name Z-A', field: 'name.keyword', order: 'desc', key: 'desc' },*/}
        {/*{ label: 'From newest', field: 'created_at.keyword', order: 'desc' },*/}
        {/*{ label: 'From oldest', field: 'created_at.keyword', order: 'asc', key: 'oldest' },*/}
        {/*{ label: 'From last updated', field: 'updated_at.keyword', order: 'asc' },*/}
        {/*{ label: 'From first updated', field: 'updated_at.keyword', order: 'desc', key: 'first_updated' }*/}
      {/*]} />*/}
    </div>
  }
}

export default CommonSort
