import React from 'react'
import { observer } from 'mobx-react'
import _ from 'lodash'
import AutosizeInput from 'react-input-autosize'
import { Header, Dropdown, Divider } from 'semantic-ui-react'

export default observer(({ store, path, Component, style = {}, placeholder = '', options, customProps = {}, mainPath = 'data.contact.0.' }) => {
  const fullPath = mainPath + path
  const params = {
    store: store,
    value: _.get(store, fullPath) || '',
    onChange: val => _.set(store, fullPath, val),
    onBlur: () => {
      store.sync()
    },
    style,
    placeholder
  }
  if (!store.loaded) return null
  if (Component) return <Component {...params} {...customProps} />
  if (options) {
    return <Dropdown style={style} text={options[params.value] || 'wybierz typ...'} icon={null}>
      <Dropdown.Menu>
        {Object.keys(options).map(option => <Dropdown.Item key={'TYPE_' + options[option]} onClick={() => params.onChange(option)} text={options[option]} />)}
      </Dropdown.Menu>
    </Dropdown>
  }
  return <AutosizeInput
    {...params}
    inputClassName='smoothInput'
    style={style}
    onChange={e => params.onChange(e.target.value)}
  />
})
