import React from 'react'
import BigCalendar from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { CloseButton } from 'components/Generics'

const DraggableCalendar = withDragAndDrop(BigCalendar)
const localizer = BigCalendar.momentLocalizer(moment)

export const TimerCalendar = ({ task, store, close }) => {
  const formatDate = date => moment(date).format('YYYY-MM-DD hh:mm')
  const handleSelect = ({ start, end }) => {
    console.log(store.getUser().user);
    task.information.push({
      name: store.getUser().user.name,
      content: JSON.stringify({
        start_date: formatDate(start),
        end_date: formatDate(end)
      }),
      type: 'worktime'
    })
    store.sync()
  }
  const handleDrop = ({ start, end, event }) => {
    const information = task.information.find(el => el.id === event.id)
    information.content = JSON.stringify({
      start_date: formatDate(start),
      end_date: formatDate(end)
    })
    store.sync()
  }
  return <div style={{
    position: 'absolute',
    zIndex: 1000,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'white'
  }}>
    <CloseButton click={close} />
    <DraggableCalendar
      longPressThreshold={90}
      selectable
      localizer={localizer}
      events={task
        .information.filter(info => info.type === 'worktime')
        .map(info => ({
          ...info,
          title: info.name,
          start: new Date(moment(JSON.parse(info.content).start_date)),
          end: new Date(moment(JSON.parse(info.content).end_date))
        }))}
      defaultView='week'
      onSelectSlot={handleSelect}
      onEventDrop={handleDrop}
      onEventResize={handleDrop}
    />
  </div>
}
