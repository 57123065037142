import React, { Component } from 'react'
import {StorworkInit, ListStore, JWT, Taxonomies} from 'storwork-stores'
import {observer} from 'mobx-react'
import _ from 'lodash'

const addplus48 = phone => {
  const phoneWithoutSpaces = phone.replace(/ /g, '')
  if (phoneWithoutSpaces.substr(0, 3) === '+48') return phoneWithoutSpaces
  return '+48' + phoneWithoutSpaces
}

const getCanonical = (number) => {
  return number.canonicalForm ? number.canonicalForm.replace(/\+48/, '') : number.value
}

const promiseSerial = funcs =>
  funcs.reduce((promise, func) =>
    promise.then(result => func().then(Array.prototype.concat.bind(result))),
  Promise.resolve([]))

var contactPhones = []
var wantedAmount = 0
var startedPhones = 0
var finishedPhones = 0
var contactsToAddToGroup = []

// Component dostaje funkcje do zamknięcia onClose, listę kontaktów contacts i nazwę grupy groupName
class App extends Component {
  componentDidMount () {
    this.handleClientLoad()
  }
  handleClientLoad = () => {
    window.gapi.load('client:auth2', this.initClient)
  }

  initClient = () => {
    // Initialize the client with API key and People API, and initialize OAuth with an
    // OAuth 2.0 client ID and scopes (space delimited string) to request access.
    window.gapi.client.init({
      apiKey: 'AIzaSyDGkrn8oZ0AYWKX5tgTyXqzhLHDCX9rE9o',
      discoveryDocs: ['https://people.googleapis.com/$discovery/rest?version=v1'],
      clientId: '816444830567-gudred8u0sqkugamuffdp9rljv1m7mbt.apps.googleusercontent.com',
      scope: 'profile https://www.googleapis.com/auth/contacts'
    }).then(() => {
      window.gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus)
      this.updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get())
    })
  }

  updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      console.log(window.gapi.auth2.getAuthInstance())
      // makeApiCall();
    }
  }

  handleSignInClick = (event) => {
    window.gapi.auth2.getAuthInstance().signIn()
  }

  handleSignOutClick = (event) => {
    window.gapi.auth2.getAuthInstance().signOut()
  }

  addPhones = (ignoredPhones, groupId) => {
    console.log('add phones');
    const additionalPeople = this.props.contacts.map(
      contact => {
        const phone = JSON.parse(contact.contact_data).find(phone => phone.status === 3 && phone.type === 'phone')
        if (!phone) return false
        return {
          name: contact.name,
          phone: phone.value
        }
      }
    ).filter(el => el && ignoredPhones.indexOf(el.phone) === -1)
    startedPhones = additionalPeople.length
    finishedPhones = 0
    console.log(additionalPeople);
    additionalPeople.map(({name, phone}) => {
      const givenName = name.split(' ').slice(0, -1).join(' ')
      const lastName = name.split(' ').slice(-1).join(' ')
      window.gapi.client.request({
        'method': 'POST',
        'path': 'https://people.googleapis.com/v1/people:createContact',
        'datatype': 'jsonp',
        'parent': 'people/me',
        'body': {
          'names': [
            {
              'givenName': givenName,
              'familyName': lastName
            }
          ],
          'phoneNumbers': [
            {
              'value': addplus48(phone)
            }
          ]
        }
      }).then((response) => {
        finishedPhones++
        contactsToAddToGroup.push(response.result.resourceName)
        if (finishedPhones === startedPhones) {
          console.log(contactsToAddToGroup)
          window.gapi.client.request({
            'method': 'POST',
            'path': 'https://people.googleapis.com/v1/' + groupId + '/members:modify',
            'datatype': 'jsonp',
            'body': {
              'resourceNamesToAdd': contactsToAddToGroup
            }
          }).then((response) => {
            alert('Udało się!')
            this.props.onClose()
            console.log(response)
          }, (reason) => {
            console.log(reason)
          })
        }
        console.log(response.result)
      }, (reason) => {
        console.log(reason)
      })
      // window.gapi.client.people.people.createContact({
      //   per
      // }).then((response) => {
      //   const groups = JSON.parse(response.body.replace(/\\n/g, ''))
      //   console.log(groups)
      // })
    })
  }

  batchGet = (members, groupId) => {
    console.log('batch get');
    console.log(members);
    return window.gapi.client.people.people.getBatchGet({
      'resourceNames': members,
      'requestMask.includeField': 'person.phoneNumbers'
    }).then((response) => {
      contactPhones = contactPhones.concat(response.result.responses)
      if (wantedAmount === contactPhones.length) {
        const phones = _.flatMap(contactPhones.map((contact) => {
          return contact.person.phoneNumbers ? contact.person.phoneNumbers.map(getCanonical) : []
        }))
        this.addPhones(phones, groupId)
      }
    }, (reason) => {
      console.log(reason)
    })
  }

  addContactsToGroup = groupId => {
    console.log('actg');
    console.log(groupId);
    window.gapi.client.people.contactGroups.get({
      'resourceName': groupId,
      'maxMembers': 10000
    }).then((response) => {
      const group = JSON.parse(response.body.replace(/\\n/g, ''))
      const members = group.memberResourceNames ? group.memberResourceNames : []
      wantedAmount = members.length
      for (var i = 0; i < members.length; i += 50) {
        this.batchGet(members.slice(i, i + 50), groupId)
      }
      if (members.length === 0) {
        this.addPhones([], groupId)
      }
    })
  }

  findOrCreateGroup = () => {
    const {groupName} = this.props
    window.gapi.client.people.contactGroups.list().then((response) => {
      const groups = JSON.parse(response.body.replace(/\\n/g, ''))
      let foundGroup = false
      groups.contactGroups.map(group => {
        if (group.name === groupName) {
          foundGroup = true
          this.addContactsToGroup(group.resourceName)
        }
      })
      if (!foundGroup) {
        window.gapi.client.request({
          'method': 'POST',
          'path': 'https://people.googleapis.com/v1/contactGroups',
          'datatype': 'jsonp',
          'parent': 'people/me',
          'body': {
            'contactGroup': {
              'name': groupName
            }
          }
        }).then((response) => {
          this.addContactsToGroup(response.result.resourceName)
        }, (reason) => {
          console.log(reason)
        })
      }
    })


  }
  render () {
    const { contacts, groupName } = this.props
    console.log(contacts)
    console.log(groupName)
    return <div style={{background: 'white', padding: 15}}>
      <div onClick={this.handleSignInClick}>
        SIGN IN
      </div>
      <div onClick={this.findOrCreateGroup}>
        API
      </div>
    </div>
  }
}

export default App
