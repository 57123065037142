import React from 'react'
import {Link} from 'react-router-dom'
import _ from 'lodash'
import { observer } from 'mobx-react'
import Grid from 'components/Grid'
import Store from 'services/StoreStore'
import Form from 'react-jsonschema-form'
import {transformer} from "../services/helpers";
import Mustache from "mustache";
import SemanticInput from "../components/semantic-ui/SemanticInput";
import FormBuilder from "../components/FormBuilder";
import SemanticSelect from "../components/semantic-ui/SemanticSelect";
import LabelSelect from "../components/semantic-ui/LabelSelect";
import { Input, Container, Table, Icon, List, Select, Label, Button } from 'semantic-ui-react'
import {getStore} from "components/List";


export const singleStore = id => Store(`
  {
    search(type: "content", source: "database", filter:"id:${id}") {
      ... on Content {
        id
        indexType
        type
        name
        content
        taxonomables {
          id
          indexType
          parent_id
          parent_type
          taxonomy_id
        }
        information {
          id
          indexType
          type
          name
          parent_id
          parent_type
          content
        }
      }
    }
  }`, 'content')

const createRating = (id) => {
  console.log(id)
  singleStore(id).executeMutation('createinformation', {
    type: 'rating',
    name: '',
    content: JSON.stringify({
      avg: '1'
    }),
    parent_type: 'contacts',
    parent_id: id
  })
}

const Render = observer(class _Render extends React.Component {
  onSubmit = ({formData}, type, additional = {}) => {
    const data = transformer(Object.assign(formData, additional))
    //
    const store = singleStore(this.props.match.params.id)
    // const record = store.data.search.find(el => el.id === parseInt(this.props.match.params.id));
    // const content = JSON.parse(record.content);
    // // const equ = Mustache.render(content.logic.equation, formData)
    // // const result = eval(equ);
    // //TODO save logic
    // //TODO connect contents with contact
    // console.log(data)
    if(type === 'form') {
      if (Object.keys(data).length > 0)
        store.executeMutation('createcontact', data)
    } else if(type === 'rating') {
      store.executeMutation('createinformation', {
        type: 'rating',
        name: '',
        content: JSON.stringify({
          avg: '1'
        }),
        parent_type: 'contacts',
        parent_id: 73
      })
    }
  }

  render () {
    const { id, type } = this.props.match.params

    const store = singleStore(id)
    if(!store.loaded) return null



    const record = store.data.search.find(el => el.id === parseInt(id));
    console.log(store)
    const content = JSON.parse(record.content);
    const recordType = record.type;

    const {json_schema, ui_schema, logic, label} = content;
    const {properties} = JSON.parse(json_schema)

    let uiSchema = {};

    Object.keys(properties).map(field => {
      const type = properties[field].type;
      const title = properties[field].title;
      const swType = properties[field].swType;

      if(swType === 'text') {
        uiSchema[field] = {
          'ui:widget': 'semanticInput',
          'ui:options': {
            'label': false
          }
        }
      } else if (swType === 'number') {
        uiSchema[field] = {
          'ui:widget': 'semanticInput',
          'ui:options': {
            'label': false
          }
        }
      } else if (swType === 'enum') {
        uiSchema[field] = {
          'ui:widget': 'semanticSelect',
          'ui:options': {
            'label': false
          }
        }
      }
    })

    const widgets = {
      formBuilder: FormBuilder,
      labelSelect: LabelSelect,
      semanticInput: SemanticInput,
      semanticSelect: SemanticSelect
    };


    return <div>
      {['form'].indexOf(type) !== -1 && <Form
        // noValidate={true}
        widgets={widgets}
        schema={JSON.parse(json_schema)}
        uiSchema={uiSchema}
        onSubmit={(formData) => this.onSubmit(formData, recordType)}
      >
        <Input type="submit" value={'Submit'} />
      </Form>}

      {['template'].indexOf(type) !== -1 && <React.Fragment>
        <style>{content.grapes.css}</style>
      <div dangerouslySetInnerHTML={{__html: content.grapes.html}}></div>
      </React.Fragment>}

    </div>
  }
});

export default Render;
